// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Warn_warn__sN0JZ {
  text-align: center;
}

.Warn_warn__sN0JZ {
  margin-top: calc(100dvh / 9 * 2);
}

.Warn_icon__WL87K {
  display: block;
  margin: 0 auto;
}

.Warn_title__yWAo6 {
  margin: 30px auto;
  font-size: 20px;
  font-weight: 500;
}

.Warn_big__NxTRs .Warn_title__yWAo6 {
  font-size: 24px;
}

.Warn_description__LWQv9 {
  margin: 0;
  font-weight: 500;
  line-height: 2.07;
  color: #878787;
}

.Warn_big__NxTRs .Warn_description__LWQv9 {
  font-size: 18px;
  line-height: 1.61;
}
`, "",{"version":3,"sources":["webpack://./src/components/Warn.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,SAAS;EACT,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".warn {\n  text-align: center;\n}\n\n.warn {\n  margin-top: calc(100dvh / 9 * 2);\n}\n\n.icon {\n  display: block;\n  margin: 0 auto;\n}\n\n.title {\n  margin: 30px auto;\n  font-size: 20px;\n  font-weight: 500;\n}\n\n.big .title {\n  font-size: 24px;\n}\n\n.description {\n  margin: 0;\n  font-weight: 500;\n  line-height: 2.07;\n  color: #878787;\n}\n\n.big .description {\n  font-size: 18px;\n  line-height: 1.61;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warn": `Warn_warn__sN0JZ`,
	"icon": `Warn_icon__WL87K`,
	"title": `Warn_title__yWAo6`,
	"big": `Warn_big__NxTRs`,
	"description": `Warn_description__LWQv9`
};
export default ___CSS_LOADER_EXPORT___;
