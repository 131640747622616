const base = `${process.env.REACT_APP_API_URL}api/post`;

function formingData(apiData) {
  const formData = new FormData();
  for (const dataKey in apiData) {
    formData.append(`${dataKey}`, apiData[dataKey]);
  }
  return formData;
}

function settingParams(base, params) {
  let url = `${base}?`;
  for (const dataKey in params) {
    url = url + `${dataKey}=${params[dataKey]}&`;
  }

  return url.slice(0, url.length - 1);
}

export async function createPost(postData, token) {
  // {
  //   "community_id": "any",
  //   "title": "any",
  //   "content": "any"
  // }
  const res = await fetch(base, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formingData(postData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  return { code, msg, data };
}

export async function getPost(apiParams) {
  // { post_id }
  const res = await fetch(settingParams(base, apiParams), {
    method: "GET",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  return { code, msg, data };
}

export async function updatePost(postData) {
  // {
  //   "post_id": "any",
  //   "title": "any",
  //   "content": "any"
  // }
  const res = await fetch(base, {
    method: "PUT",
    body: formingData(postData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  return { code, msg, data };
}

export async function deletePost(postData) {
  // {
  //   "post_id": "any"
  // }
  const res = await fetch(base, {
    method: "DELETE",
    body: formingData(postData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  return { code, msg, data };
}

export async function getMyPost(apiParams) {
  // { page, page_size }
  const res = await fetch(settingParams(`${base}/my`, apiParams), {
    method: "GET",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  return { code, msg, data };
}

export async function getListOfPost(apiParams) {
  // { community_id, page, page_size }
  const res = await fetch(settingParams(`${base}/list`, apiParams), {
    method: "GET",
    credentials: "include",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  return { code, msg, data };
}

// export async function createImageOnPost() {
//   const res = await fetch(`${base}/image`, {
//     method: "POST",
//   }).then((body) => body.json());

//   if (!res) {
//     return { code: 404, msg: "주소가 잘못되었습니다" };
//   }

//   const { code, message: msg, data } = res;

//   return { code, msg, data };
// } 수정 필요

export async function deleteImageOnPost(postData) {
  // {
  //   "post_id": "any",
  //   "image_id": "any"
  // }
  const res = await fetch(`${base}/image`, {
    method: "DELETE",
    body: formingData(postData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  return { code, msg, data };
}

export async function getListOfImageOnPost(apiParams) {
  // { post_id }
  const res = await fetch(settingParams(`${base}/image/list`, apiParams), {
    method: "GET",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  return { code, msg, data };
}

export async function approvePendingPost(postData) {
  // {
  //   "post_id": "any"
  // }
  const res = await fetch(`${base}/complete`, {
    method: "PUT",
    body: formingData(postData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  return { code, msg, data };
}

export async function createCommentOnPost(postData) {
  // {
  //   "post_id": "any",
  //   "content": "any",
  //   "parent_id": "any",
  //   "is_anonymous": "any"
  // }
  const res = await fetch(`${base}/comment`, {
    method: "POST",
    body: formingData(postData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  return { code, msg, data };
}

export async function updateCommentOnPost(postData) {
  // {
  //   "comment_id": "any",
  //   "content": "any"
  // }
  const res = await fetch(`${base}/comment`, {
    method: "PUT",
    body: formingData(postData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  return { code, msg, data };
}

export async function deleteCommentOnPost(postData) {
  // {
  //   "comment_id": "any"
  // }
  const res = await fetch(`${base}/comment`, {
    method: "DELETE",
    body: formingData(postData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  return { code, msg, data };
}

export async function getListOfCommentOnPost(apiParams) {
  // { post_id, page, page_size }
  const res = await fetch(settingParams(`${base}/comment/list`, apiParams), {
    method: "GET",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  return { code, msg, data };
}
