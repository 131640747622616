import { useNestock } from "../contexts/NestockProvider";
import PostList from "../components/PostList";
import pageStyles from "../components/ui/PageBox.module.css";

function MainPage() {
  const { postList } = useNestock();
  return (
    <div className={pageStyles.pageBox}>
      <PostList showCommunity={true} posts={postList} />
    </div>
  );
}

export default MainPage;
