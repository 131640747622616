import { useState } from "react";
import { useAuth } from "../contexts/AuthProvider";
import NotFoundPage from "./NotFoundPage";
import ShowPageProfile from "../components/ShowPageProfile";
import ReadingOptions from "../components/ReadingOptions";
import TabContent from "../components/TabContent";
import pageStyle from "../components/ui/PageBox.module.css";

function UserPage() {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState("entire");
  if (!user.id) {
    return (
      <NotFoundPage variant="big" title="로그인 하셔야 이용할 수 있습니다" />
    );
  }
  // user = {
  //   id,
  //   status,
  //   username,
  //   role,
  //   social_provider,
  //   social_id,
  //   created_at,
  //   updated_at,
  // };

  return (
    <div className={pageStyle.pageBox}>
      <ShowPageProfile option={"user"} data={user} />
      <ReadingOptions
        user={user}
        activeTab={activeTab}
        handleClick={setActiveTab}
      />
      <TabContent
        activeTab={activeTab}
        // badges={user.badges}
      />
    </div>
  );
}

export default UserPage;
