// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationList_notificationContainer__mWtbc {
  width: 300px;
  height: 350px;
  border-radius: 4px;
  margin: 0 auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: rgb(255, 255, 255);
  position: absolute;
  top: 72.5px;
  right: 100px;
  overflow: auto;
}

.NotificationList_notificationHeader__PvbTx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #e1e4e8;
  background-color: #f6f8fa;
}

.NotificationList_headerTitle__M8pNY {
  margin: 0;
  font-size: 18px;
}

.NotificationList_notificationItem__0z5IE {
  display: flex;
  padding: 15px 20px;
  border-bottom: 1px solid #e1e4e8;
  background-color: #ffffff;
  transition: background-color 0.3s;
}

.NotificationList_notificationIcon__Bu95X {
  font-size: 24px;
  margin-right: 15px;
}

.NotificationList_notificationContent__AFAki {
  flex-grow: 1;
}

.NotificationList_notificationMessage__OqP\\+Y {
  margin: 0 0 10px 0;
  font-size: 14px;
  color: #24292e;
}

.NotificationList_notificationMeta__lpRiS {
  font-size: 12px;
  color: #586069;
}

.NotificationList_metaButton__SVCzy {
  background: none;
  border: none;
  cursor: pointer;
  color: #0366d6;
  font-size: 12px;
}

.NotificationList_metaButton__SVCzy.NotificationList_buttons__8JLQF {
  margin-top: 10px;
}

.NotificationList_emptyMessage__xDg98 {
  padding: 20px;
  text-align: center;
  color: #586069;
}
`, "",{"version":3,"sources":["webpack://./src/components/NotificationList.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,cAAc;EACd,yCAAyC;EACzC,oCAAoC;EACpC,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,gCAAgC;EAChC,yBAAyB;AAC3B;;AAEA;EACE,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,gCAAgC;EAChC,yBAAyB;EACzB,iCAAiC;AACnC;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".notificationContainer {\n  width: 300px;\n  height: 350px;\n  border-radius: 4px;\n  margin: 0 auto;\n  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24);\n  background-color: rgb(255, 255, 255);\n  position: absolute;\n  top: 72.5px;\n  right: 100px;\n  overflow: auto;\n}\n\n.notificationHeader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px 20px;\n  border-bottom: 1px solid #e1e4e8;\n  background-color: #f6f8fa;\n}\n\n.headerTitle {\n  margin: 0;\n  font-size: 18px;\n}\n\n.notificationItem {\n  display: flex;\n  padding: 15px 20px;\n  border-bottom: 1px solid #e1e4e8;\n  background-color: #ffffff;\n  transition: background-color 0.3s;\n}\n\n.notificationIcon {\n  font-size: 24px;\n  margin-right: 15px;\n}\n\n.notificationContent {\n  flex-grow: 1;\n}\n\n.notificationMessage {\n  margin: 0 0 10px 0;\n  font-size: 14px;\n  color: #24292e;\n}\n\n.notificationMeta {\n  font-size: 12px;\n  color: #586069;\n}\n\n.metaButton {\n  background: none;\n  border: none;\n  cursor: pointer;\n  color: #0366d6;\n  font-size: 12px;\n}\n\n.metaButton.buttons {\n  margin-top: 10px;\n}\n\n.emptyMessage {\n  padding: 20px;\n  text-align: center;\n  color: #586069;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationContainer": `NotificationList_notificationContainer__mWtbc`,
	"notificationHeader": `NotificationList_notificationHeader__PvbTx`,
	"headerTitle": `NotificationList_headerTitle__M8pNY`,
	"notificationItem": `NotificationList_notificationItem__0z5IE`,
	"notificationIcon": `NotificationList_notificationIcon__Bu95X`,
	"notificationContent": `NotificationList_notificationContent__AFAki`,
	"notificationMessage": `NotificationList_notificationMessage__OqP+Y`,
	"notificationMeta": `NotificationList_notificationMeta__lpRiS`,
	"metaButton": `NotificationList_metaButton__SVCzy`,
	"buttons": `NotificationList_buttons__8JLQF`,
	"emptyMessage": `NotificationList_emptyMessage__xDg98`
};
export default ___CSS_LOADER_EXPORT___;
