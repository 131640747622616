import { useNestock } from "../contexts/NestockProvider";
import { useAuth } from "../contexts/AuthProvider";
import { useEffect, useState } from "react";
import { getPendingCommunity } from "../api/CommunityApi";
import { getAllOfUser, getUser } from "../api/UserApi";
import ReadingOptions from "../components/ReadingOptions";
import ManageMode from "../components/ManageMode";
import nestock from "../assets/logo.png";
import pageStyle from "../components/ui/PageBox.module.css";
import profileStyles from "../components/ShowPageProfile.module.css";
import NotFoundPage from "./NotFoundPage";

function AdminPage() {
  const { communityList } = useNestock();
  const {
    token: { access_token },
  } = useAuth();
  const tabs = [
    { id: "nests", name: "커뮤니티 관리" },
    { id: "users", name: "유저 관리" },
  ];
  const [activeTab, setActiveTab] = useState("nests");
  const [isPass, setIsPass] = useState(false);
  const [userList, setUserList] = useState([]);
  const [nestList, setNestList] = useState([]);
  useEffect(() => {
    async function check() {
      const { nextUser } = await getUser({ user_id: 0 }, access_token);
      if (nextUser) {
        if (nextUser.role === "ADMIN") {
          const {
            code: code1,
            msg: msg1,
            data: data1,
          } = await getPendingCommunity(access_token);
          if (code1 < 300) {
            setNestList([...data1, ...communityList]);
          } else {
            alert(code1, msg1);
          }
          const {
            code: code2,
            msg: msg2,
            data: data2,
          } = await getAllOfUser(access_token);
          if (code2 < 300) {
            setUserList(data2);
          } else {
            alert(code2, msg2);
          }
          setIsPass(true);
        } else {
          setIsPass(false);
        }
      } else {
        setIsPass(false);
      }
    }
    check();
  }, []);
  if (!isPass) {
    return (
      <NotFoundPage
        title="존재하지 않는 페이지입니다"
        description="올바른 주소인지 다시 한번 확인해주세요"
      />
    );
  } else {
    return (
      <div className={pageStyle.pageBox}>
        <div
          alt="프로필 배경 이미지"
          style={{
            backgroundColor: "#f0f0f0",
          }}
          className={profileStyles.background}
        />
        <div className={profileStyles.profileContainer}>
          <div className={profileStyles.profile}>
            <img src={nestock} alt="관리자" className={profileStyles.pfImg} />
          </div>
          <div className={profileStyles.pf}>
            <p className={profileStyles.title}>관리자</p>
          </div>
        </div>
        <ReadingOptions
          manageMode={true}
          tabs={tabs}
          activeTab={activeTab}
          handleClick={setActiveTab}
        />
        <ManageMode
          data={{ users: userList, nests: nestList }}
          activeTab={activeTab}
          token={access_token}
        />
      </div>
    );
  }
}

export default AdminPage;
