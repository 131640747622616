// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Alarm_alarmContainer__ND15P {
  position: relative;
}

.Alarm_iconButton__Oi8Xt {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.Alarm_iconButton__Oi8Xt,
.Alarm_iconButton__Oi8Xt img {
  width: 30px;
  height: 30px;
}

.Alarm_modalContainer__DbPJy {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.Alarm_modalContent__DYk1V {
  width: 250px;
  height: 350px;
  position: absolute;
  top: 72.5px;
  right: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: 3px 3px 3px 6px #555555;
}
`, "",{"version":3,"sources":["webpack://./src/components/Alarm.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,eAAe;EACf,UAAU;AACZ;;AAEA;;EAEE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;EACf,MAAM;EACN,OAAO;AACT;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,oCAAoC;EACpC,mCAAmC;AACrC","sourcesContent":[".alarmContainer {\n  position: relative;\n}\n\n.iconButton {\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n  padding: 0;\n}\n\n.iconButton,\n.iconButton img {\n  width: 30px;\n  height: 30px;\n}\n\n.modalContainer {\n  width: 100vw;\n  height: 100vh;\n  position: fixed;\n  top: 0;\n  left: 0;\n}\n\n.modalContent {\n  width: 250px;\n  height: 350px;\n  position: absolute;\n  top: 72.5px;\n  right: 0;\n  background-color: rgb(255, 255, 255);\n  box-shadow: 3px 3px 3px 6px #555555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alarmContainer": `Alarm_alarmContainer__ND15P`,
	"iconButton": `Alarm_iconButton__Oi8Xt`,
	"modalContainer": `Alarm_modalContainer__DbPJy`,
	"modalContent": `Alarm_modalContent__DYk1V`
};
export default ___CSS_LOADER_EXPORT___;
