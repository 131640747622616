import { createContext, useContext, useEffect, useState } from "react";
import { login, refresh_token } from "../api/AuthApi";
// import { logout } from "../api/AuthApi";
import { getUser } from "../api/UserApi";

const initial_user = {
  id: 0,
  status: null,
  username: null,
  password: null,
  email: null,
  role: "비로그인",
  social_provider: null,
  social_id: null,
  created_at: null,
  updated_at: null,
};

const initial_token = {
  access_token: null,
  refresh_token: null,
};

const AuthContext = createContext({
  user: initial_user,
  token: initial_token,
  isOnline: false,
  setLogIn: () => {},
  setLogOut: () => {},
  setUpdate: () => {},
});

function AuthProvider({ children }) {
  const [user, setUser] = useState(initial_user);
  const [token, setToken] = useState(() => {
    const prevToken = localStorage.getItem("refresh_token");
    // return initial_token;
    if (!prevToken) {
      return initial_token;
    } else {
      return {
        ...initial_token,
        refresh_token: prevToken,
      };
    }
  });
  const [isOnline, setIsOnline] = useState(false);

  async function setLogIn(loginData) {
    const { code, msg, nextUser, nextToken } = await login(loginData);
    if (code < 300) {
      setUser((prevUser) => ({
        ...prevUser,
        ...nextUser,
      }));
      setToken((prevToken) => ({
        ...prevToken,
        ...nextToken,
      }));
      setIsOnline(true);
      console.log("login success");
      await getUser({ user_id: nextUser.id }, nextToken.access_token);
    } else {
      console.log(code, msg);
    }
    return { code, msg };
  }

  async function setLogOut(user) {
    // const res = await logout(user);
    // console.log(res);
    setUser({
      ...user,
      ...initial_user,
    });
    setToken({
      ...token,
      ...initial_token,
    });
    setIsOnline(false);
    localStorage.removeItem("refresh_token");
    alert("로그아웃 되었습니다");
    console.log("로그아웃");
  }

  // async function setUpdate() {
  //   //미완
  //   //update 함수가 완성되어야 사용 가능
  //   //update 함수 = 프로필 편집
  //   const { code, msg, userData } = await update(updateData);
  //   if (200 <= code < 300) {
  //     setUser((prevData) => ({
  //       ...prevData,
  //       userData,
  //     }));
  //   } else {
  //     console.log(code, msg);
  //   }
  // }

  useEffect(() => {
    //첫 렌더링시 (새로고침시 포함) 로그인 인증된 사용자의 정보를 조회
    //access 쿠키 이용
    async function refreshAndGetUser(token) {
      if (token.refresh_token) {
        // const {
        //   code: refresh_code,
        //   msg: refresh_msg,
        //   access_token,
        // } = await refresh_token({
        //   refresh_token: token.refresh_token,
        // });
        const { access_token } = await refresh_token({
          refresh_token: token.refresh_token,
        });
        if (access_token) {
          setToken((prevToken) => ({
            ...prevToken,
            access_token,
          }));
          // const {
          //   code,
          //   msg,
          //   data: nextUser,
          // } = await getUser({ user_id: 5 }, access_token);
          const { data: nextUser } = await getUser(
            { user_id: 5 },
            access_token
          );
          if (nextUser) {
            setUser((prevUser) => ({
              ...prevUser,
              ...nextUser,
            }));
            setIsOnline(true);
          } else {
            setToken({ ...token, ...initial_token });
            localStorage.removeItem("refresh_token");
          }
        } else {
          setToken({ ...token, ...initial_token });
          localStorage.removeItem("refresh_token");
        }
      }
    }
    refreshAndGetUser(token);
  }, []);

  window.addEventListener("unload", () => {
    // 페이지가 언로드될 때 실행할 코드 작성
    if (token.refresh_token) {
      localStorage.setItem("refresh_token", token.refresh_token);
    }
  });

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        isOnline,
        setLogIn,
        setLogOut,
        // setUpdate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("반드시 AuthProvider 안에서 사용해야 합니다.");
  }

  return context;
}

export { AuthProvider, useAuth };
