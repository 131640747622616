import { memo } from "react";
import styles from "./DateText.module.css";

function DateText({ value }) {
  if (!value) return;
  const time = new Date(value).toLocaleDateString("ko-KR");
  return <span className={styles.date}>•&nbsp;&nbsp;{time}</span>;
}

export default memo(DateText);
