import { memo } from "react";
import styles from "./DateText.module.css";

function getTimeDiff(created_time) {
  const now = new Date();

  let time_passed = now - created_time;

  if (time_passed < 1000) {
    return "방금";
  }
  time_passed = Math.floor(time_passed / 1000);
  if (time_passed < 60) {
    return `${time_passed}초 전`;
  }
  time_passed = Math.floor(time_passed / 60);
  if (time_passed < 60) {
    return `${time_passed}분 전`;
  }
  time_passed = Math.floor(time_passed / 60);
  if (time_passed < 24) {
    return `${time_passed}시간 전`;
  }
  time_passed = Math.floor(time_passed / 24);
  if (time_passed < 7) {
    return `${time_passed}일 전`;
  } else if (time_passed < 28) {
    return `${Math.floor(time_passed / 7)}주 전`;
  } else {
    const created_date = created_time.getDate();
    const now_date = now.getDate();
    const created_month = created_time.getMonth();
    const now_month = now.getMonth();
    const created_year = created_time.getFullYear();
    const now_year = now.getFullYear();
    if (created_year === now_year) {
      if (created_month === now_month) {
        return "4주 전";
      } else if (created_month < now_month) {
        if (created_date > now_date) {
          if (now_month - created_month < 2) {
            return "4주 전";
          } else {
            return `${now_month - created_month - 1}개월 전`;
          }
        } else {
          return `${now_month - created_month}개월 전`;
        }
      }
    } else if (created_year < now_year) {
      if (created_month > now_month) {
        if (created_date > now_date) {
          //2024-12-25  2025-1-23
          if (12 + now_month - created_month < 2) {
            return "4주 전";
          } else {
            return `${12 + now_month - created_month - 1}개월 전`;
          }
        } else {
          return `${12 + now_month - created_month}개월 전`;
        }
      } else if (created_month === now_month) {
        if (created_date > now_date) {
          if (now_year - created_year < 2) {
            return "11개월 전";
          } else {
            return `${now_year - created_year - 1}년 전`;
          }
        } else {
          return `${now_year - created_year}년 전`;
        }
      } else {
        return `${now_year - created_year}년 전`;
      }
    }
  }
}

function DateText({ value }) {
  if (!value) return;
  const { created_at } = value;
  // const { created_at, updated_at } = value;
  const created_time = new Date(created_at);
  // const updated_time = new Date(updated_at);
  // let isEditted = false;
  // if (updated_at) {
  //   if (updated_time - created_time >= 1000) {
  //     isEditted = true;
  //   }
  // }
  return (
    <span className={styles.date}>
      •&nbsp;&nbsp;{getTimeDiff(created_time)}
    </span>
  );
  // return (
  //   <span className={styles.date}>
  //     •&nbsp;&nbsp;{getTimeDiff(created_time)} {isEditted && `(수정됨)`}
  //   </span>
  // );
}

export default memo(DateText);
