import React from "react";
import styles from "./Dialog.module.css";

export const Dialog = ({ open, onOpenChange, children }) => {
  if (!open) return null;

  return (
    <div className={styles.overlay} onClick={() => onOpenChange(false)}>
      <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export const DialogContent = ({ children }) => <div>{children}</div>;

export const DialogHeader = ({ children }) => (
  <div className={styles.header}>{children}</div>
);

export const DialogTitle = ({ children }) => (
  <h2 className={styles.title}>{children}</h2>
);

export const DialogFooter = ({ children }) => (
  <div className={styles.footer}>{children}</div>
);
