import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Plus } from "../assets/icon--plus.svg";
import { ReactComponent as Recent } from "../assets/icon--recent.svg";
import { ReactComponent as HotCoummnity } from "../assets/icon--fire.svg";
import { ReactComponent as Info } from "../assets/icon--info.svg";
import styles from "./LeftSideBar.module.css";
import classNames from "classnames";
import { useNestock } from "../contexts/NestockProvider";
import { useAuth } from "../contexts/AuthProvider";

function LeftSideBar({ isOpen }) {
  const { communityList } = useNestock();
  const { isOnline, followList } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const [followings, setFollowings] = useState(followList.followings);

  const [visitHistory, setVisitHistory] = useState(() => {
    const historyData = localStorage.getItem("visitHistory");
    return historyData ? JSON.parse(historyData) : [];
  });

  const handleDeleteHistory = (id) => {
    setVisitHistory((prevHistory) =>
      prevHistory.filter((his) => his.id !== id)
    );
    localStorage.setItem(
      "visitHistory",
      JSON.stringify(visitHistory.filter((his) => his.id !== id))
    );
  };

  useEffect(() => setFollowings(followList.followings), [followList]);

  useEffect(() => {
    setVisitHistory(() => {
      const historyData = localStorage.getItem("visitHistory");
      const nextHistory = historyData ? JSON.parse(historyData) : [];
      return nextHistory;
    });
  }, [location.pathname]);
  return (
    <div
      id={styles.toggleMotion}
      className={classNames(
        styles.edge,
        styles[`${isOpen ? "toggleContainer" : "notToggleContainer"}`]
      )}
    >
      <div className={styles.sideBar}>
        <div
          className={classNames(styles.content, styles.button)}
          onClick={() => navigate("/nest/create")}
        >
          <div className={styles.title}>
            <div className={styles.icon}>
              <Plus color="#000000" />
            </div>
            <h3>커뮤니티 생성</h3>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <div className={styles.icon}>
              <Recent />
            </div>
            <h3>최근 방문</h3>
          </div>
          <div
            className={classNames(
              styles.list,
              visitHistory.length > 0 && styles.communities
            )}
          >
            {visitHistory.length > 0 ? (
              visitHistory.map((history, i) => {
                const commu = communityList.find(({ id }) => id === history.id);
                if (!commu) {
                  handleDeleteHistory(history.id);
                  setVisitHistory((prevHistory) =>
                    prevHistory.filter(({ id }) => history.id === id)
                  );
                  return <></>;
                }
                return (
                  <div key={i} className={styles.listItem}>
                    <div
                      className={styles.itemInfo}
                      onClick={() => navigate(`/nest/${commu.id}`)}
                    >
                      <img
                        src={commu?.profile_image}
                        alt={`${commu?.communityName} 프로필`}
                        className={styles.userAvatar}
                      />
                      <p className={styles.userName}>
                        {commu?.communityName} 커뮤니티
                      </p>
                    </div>
                    <button
                      className={styles.closeButton}
                      onClick={() => handleDeleteHistory(commu.id)}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 6L14 14M14 6L6 14"
                          stroke="#9CA3AF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                );
              })
            ) : (
              <p>방문하신 커뮤니티가 없습니다</p>
            )}
          </div>
        </div>
        {isOnline && (
          <div className={styles.content}>
            <div className={styles.title}>
              <h3>팔로잉</h3>
            </div>
            {followings.length > 0 ? (
              <div className={classNames(styles.followers, styles.list)}>
                {followings.map((user) => (
                  <div
                    key={user.id}
                    className={styles.followingItem}
                    onClick={() => navigate(`/user/${user.id}`)}
                  >
                    <img
                      src={user?.profile_image}
                      alt={`${user?.userName} 프로필`}
                      className={styles.userAvatar}
                    />
                    <div className={styles.userName}>{user?.userName}</div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.list}>
                <p>팔로우하는 유저가 없습니다</p>
              </div>
            )}
          </div>
        )}
        <div className={styles.content}>
          <div className={styles.title}>
            <div className={styles.icon}>
              <HotCoummnity />
            </div>
            <h3>인기 커뮤니티</h3>
          </div>
          <div className={styles.list}>
            <p>운영 준비중인 컨텐츠입니다</p>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <div className={styles.icon}>
              <Info />
            </div>
            <h3>사이트 정보</h3>
          </div>
          <div className={styles.list}>
            <p>nestock 소개</p>
            <p>멤버쉽 소개</p>
            <p>사용자 이용 약관</p>
            <div onClick={() => navigate("/info/policy")}>
              <p>개인정보 처리방침</p>
            </div>
            <p>문의하기</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftSideBar;
