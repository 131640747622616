import { createContext, useContext, useEffect, useState } from "react";
import { getCommunity } from "../api/CommunityApi";
import { getListOfUser } from "../api/UserApi";

const NestockContext = createContext();

function NestockProvider({ children }) {
  const [userList, setUserList] = useState();
  const [communityList, setCommunityList] = useState();
  const [postList, setPostList] = useState([]);
  const [loading, setLoading] = useState(true);

  const refreshUserList = async () => {
    const { data: userData } = await getListOfUser();

    if (userData) {
      const formalizedList = userData.map(
        ({ username, FollowerUsers, FollowingUsers, ...rest }) => ({
          ...rest,
          userName: username,
          followers:
            FollowerUsers.length > 0
              ? FollowerUsers.map(({ Follower }) => {
                  const follower = userData.find(
                    (user) => user.id === Follower.id
                  );
                  return {
                    id: follower.id,
                    userName: follower.username,
                    profile_image: follower.profile_image,
                  };
                })
              : [],
          followings:
            FollowingUsers.length > 0
              ? FollowingUsers.map(({ Following }) => {
                  const following = userData.find(
                    (user) => user.id === Following.id
                  );
                  return {
                    id: following.id,
                    userName: following.username,
                    profile_image: following.profile_image,
                  };
                })
              : [],
        })
      );
      setUserList(() => formalizedList);
      return formalizedList;
    }
  };
  const refreshCommunityList = async () => {
    const { data: communityData } = await getCommunity();

    if (communityData) {
      const formalizedList = communityData.map(({ name, image, ...rest }) => ({
        ...rest,
        communityName: name,
        profile_image: image,
      }));
      setCommunityList(() => formalizedList);
      return formalizedList;
    }
  };

  const updatePostList = (postID, updateCommentData) => {
    if (!updateCommentData) {
      setPostList((prevList) => prevList.filter((post) => post.id !== postID));
      return;
    }
    const { type, commentID, newComment } = updateCommentData;
    const targetPostIdx = postList.findIndex(({ id }) => id === postID);
    const targetCommentIdx = postList[targetPostIdx].comments.findIndex(
      ({ id }) => id === commentID
    );
    if (type === "create") {
      setPostList((prevList) => {
        const nextCommentList = prevList[targetPostIdx].comments;
        nextCommentList.push(newComment);
        return [
          ...prevList.slice(0, targetPostIdx),
          { ...prevList[targetPostIdx], comments: nextCommentList },
          ...prevList.slice(targetPostIdx + 1),
        ];
      });
    } else if (type === "update") {
      setPostList((prevList) => [
        ...prevList.slice(0, targetPostIdx),
        {
          ...prevList[targetPostIdx],
          comments: [
            ...prevList[targetPostIdx].comments.slice(0, targetCommentIdx),
            {
              ...prevList[targetPostIdx].comments[targetCommentIdx],
              ...newComment,
            },
            ...prevList[targetPostIdx].comments.slice(targetCommentIdx + 1),
          ],
        },
        ...prevList.slice(targetPostIdx + 1),
      ]);
    } else if (type === "delete") {
      setPostList((prevList) => {
        const nextCommentList = prevList[targetPostIdx].comments;
        return [
          ...prevList.slice(0, targetPostIdx),
          {
            ...prevList[targetPostIdx],
            comments: nextCommentList.filter(
              (comment) => comment.id !== commentID
            ),
          },
          ...prevList.slice(targetPostIdx + 1),
        ];
      });
    }
  };

  useEffect(() => {
    async function getNestock() {
      try {
        setLoading(true);
        const { data: communityData } = await getCommunity();

        if (communityData) {
          const formalizedList = communityData.map(
            ({ name, image, ...rest }) => ({
              ...rest,
              communityName: name,
              profile_image: image,
            })
          );
          setCommunityList(formalizedList);
        }

        const { data: userData } = await getListOfUser();

        if (userData) {
          const formalizedList = userData.map(
            ({ username, FollowerUsers, FollowingUsers, ...rest }) => ({
              ...rest,
              userName: username,
              followers:
                FollowerUsers.length > 0
                  ? FollowerUsers.map(({ Follower }) => {
                      const follower = userData.find(
                        (user) => user.id === Follower.id
                      );
                      return {
                        id: follower.id,
                        userName: follower.username,
                        profile_image: follower.profile_image,
                      };
                    })
                  : [],
              followings:
                FollowingUsers.length > 0
                  ? FollowingUsers.map(({ Following }) => {
                      const following = userData.find(
                        (user) => user.id === Following.id
                      );
                      return {
                        id: following.id,
                        userName: following.username,
                        profile_image: following.profile_image,
                      };
                    })
                  : [],
            })
          );
          setUserList(formalizedList);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    getNestock();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // 또는 로딩 컴포넌트
  }

  return (
    <NestockContext.Provider
      value={{
        userList,
        refreshUserList,
        communityList,
        refreshCommunityList,
        postList,
        updatePostList,
        setPostList,
      }}
    >
      {children}
    </NestockContext.Provider>
  );
}

function useNestock() {
  const context = useContext(NestockContext);

  if (!context) {
    throw new Error("반드시 AuthProvider 안에서 사용해야 합니다.");
  }

  return context;
}

export { NestockProvider, useNestock };
