import { useNavigate } from "react-router-dom";
import styles from "./ShowPageProfile.module.css";

function ShowPageProfile({ option, data }) {
  const navigate = useNavigate();
  console.log(data);
  let pageInfo;
  if (option === "user") {
    console.log(data);
    const { id, username: title, created_at, updated_at } = data;
    pageInfo = {
      id,
      title,
      created_at,
      updated_at,
    };
  } else if (option === "mentor") {
    const { owl } = data;
    pageInfo = { owl };
  } else if (option === "community") {
    console.log(data);
    const {
      id,
      name: title,
      description,
      created_by,
      created_at,
      updated_at,
    } = data;
    pageInfo = {
      id,
      title,
      description,
      created_by,
      created_at,
      updated_at,
    };
  }
  return (
    <>
      <div
        alt="프로필 배경 이미지"
        style={{
          backgroundColor: "#f0f0f0",
        }}
        className={styles.background}
      />
      <div className={styles.profileContainer}>
        <img
          src={
            "https://file.alphasquare.co.kr/media/images/stock_logo/kr/005930.png"
          }
          alt="프로필 이미지"
          className={styles.pfImg}
        />
        <div className={styles.profile}>
          <h1 className={styles.title}>{pageInfo.title}</h1>
          {(() => {
            switch (option) {
              case "user":
                return (
                  <>
                    {/* <div className={styles.buttonContainer}>
                      <button className={styles.button}>
                        <h2>팔로우</h2>
                      </button>
                    </div> */}
                    <div>가입일: {pageInfo.created_at.split("T")[0]}</div>
                  </>
                );

              case "mentor":
                return <div>가입일: {pageInfo.created_at.split("T")[0]}</div>;
              case "community":
                return (
                  <>
                    <div className={styles.buttonContainer}>
                      {/* <button className={styles.button}>
                        <h2>팔로우</h2>
                      </button> */}
                      <button
                        className={styles.button}
                        onClick={() => navigate(`/nest/${pageInfo.id}/posting`)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                        >
                          <path
                            d="M12 4v16M4 12h16"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeinejoin="round"
                            fill="none"
                          />
                        </svg>
                        <h2>포스팅</h2>
                      </button>
                    </div>
                    <div>생성일: {pageInfo.created_at.split("T")[0]}</div>
                  </>
                );
              default:
                return <></>;
            }
          })()}
        </div>
      </div>
    </>
  );
}

export default ShowPageProfile;
