import React, { useState } from "react";
import styles from "./TradeForm.module.css";

const TradeForm = ({ onAddTrade }) => {
  const [trade, setTrade] = useState({
    stockSymbol: "",
    tradeType: "buy",
    price: "",
    quantity: "",
    reason: "",
    emotion: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddTrade({ ...trade, date: new Date().toISOString() });
    setTrade({
      stockSymbol: "",
      tradeType: "buy",
      price: "",
      quantity: "",
      reason: "",
      emotion: "",
    });
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <h2 className={styles.title}>새로운 매매 기록</h2>
      <div className={styles.formGroup}>
        <label className={styles.label} htmlFor="stockSymbol">
          주식 심볼
        </label>
        <input
          id="stockSymbol"
          type="text"
          value={trade.stockSymbol}
          onChange={(e) => setTrade({ ...trade, stockSymbol: e.target.value })}
          className={styles.input}
          placeholder="예: AAPL"
          required
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label} htmlFor="tradeType">
          매매 유형
        </label>
        <select
          id="tradeType"
          value={trade.tradeType}
          onChange={(e) => setTrade({ ...trade, tradeType: e.target.value })}
          className={styles.select}
        >
          <option value="buy">매수</option>
          <option value="sell">매도</option>
        </select>
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label} htmlFor="price">
          가격
        </label>
        <input
          id="price"
          type="number"
          value={trade.price}
          onChange={(e) => setTrade({ ...trade, price: e.target.value })}
          className={styles.input}
          placeholder="주당 가격"
          required
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label} htmlFor="quantity">
          수량
        </label>
        <input
          id="quantity"
          type="number"
          value={trade.quantity}
          onChange={(e) => setTrade({ ...trade, quantity: e.target.value })}
          className={styles.input}
          placeholder="주식 수량"
          required
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label} htmlFor="reason">
          매매 이유
        </label>
        <textarea
          id="reason"
          value={trade.reason}
          onChange={(e) => setTrade({ ...trade, reason: e.target.value })}
          className={styles.textarea}
          placeholder="매매 결정의 이유를 적어주세요"
          rows="3"
        ></textarea>
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label} htmlFor="emotion">
          감정 상태
        </label>
        <select
          id="emotion"
          value={trade.emotion}
          onChange={(e) => setTrade({ ...trade, emotion: e.target.value })}
          className={styles.select}
        >
          <option value="">선택하세요</option>
          <option value="confident">자신감 있음</option>
          <option value="nervous">불안함</option>
          <option value="excited">흥분됨</option>
          <option value="calm">평온함</option>
        </select>
      </div>
      <button type="submit" className={styles.button}>
        매매 기록 저장
      </button>
    </form>
  );
};

export default TradeForm;
