import { Link } from "react-router-dom";
import Warn from "../components/Warn";
import styles from "./NotFoundPage.module.css";
import pageStyle from "../components/ui/PageBox.module.css";

function NotFoundPage({ variant, title, description = "" }) {
  return (
    <div className={pageStyle.pageBox}>
      <Warn variant={variant} title={title} description={description} />
      <div className={styles.link}>
        <Link to="/">홈으로 가기</Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
