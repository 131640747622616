import { useEffect, useRef, useState } from "react";
import closeIcon from "../assets/icon--close-button.svg";
import styles from "./Login.module.css";
import classNames from "classnames/bind";
import { useAuth } from "../contexts/AuthProvider";
import { register, verify_email } from "../api/AuthApi";

function Login() {
  const { setLogIn } = useAuth();

  const [isOpen, setIsOpen] = useState(false);

  const modalBackground = useRef();
  const vfcode0 = useRef();
  const vfcode1 = useRef();
  const vfcode2 = useRef();
  const vfcode3 = useRef();
  const vfcode4 = useRef();
  const vfcode5 = useRef();
  const vfcode = [vfcode0, vfcode1, vfcode2, vfcode3, vfcode4, vfcode5];

  const [method, setMethod] = useState("로그인"); //로그인, 회원가입, 이메일 인증, 비밀번호 찾기, 회원가입 완료

  const [errorMessage, setErrorMessage] = useState({
    message: "",
    confirmed: false,
  });

  const INITIAL_LOGIN_VALUES = {
    email: "",
    password: "",
  };

  const INITIAL_REGISTER_VALUES = {
    username: "",
    email: "",
    password: "",
    passwordCheck: "",
  };

  const INITIAL_VERIFY_VALUES = ["", "", "", "", "", ""];

  const [loginValues, setLoginValues] = useState(INITIAL_LOGIN_VALUES);

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const { code, msg } = await setLogIn(loginValues);
    if (code < 300) {
      setLoginValues((prevValues) => ({
        ...prevValues,
        ...INITIAL_LOGIN_VALUES,
      }));
    } else {
      setErrorMessage((prevState) => ({
        ...prevState,
        message: msg,
      }));
    }
    console.log(msg);
    return;
  };

  const [registerValues, setRegisterValues] = useState(INITIAL_REGISTER_VALUES);

  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setRegisterValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    if (errorMessage.confirmed) {
      const { username, email, password } = registerValues;
      const { code, msg } = register({ username, email, password });
      if (code >= 300) {
        console.log(code, msg);
        return;
      }
      setMethod("이메일 인증");
    } else {
      return;
    }
  };

  const [verifyValues, setVerifyValues] = useState(INITIAL_VERIFY_VALUES);

  // function maxLengthCheck(object) {
  //   if (value.length > maxLength) {
  //     //object.maxLength : 매게변수 오브젝트의 maxLength 속성 값입니다.
  //     object.value = object.value.slice(0, object.maxLength);
  //   }
  // }

  const handleVerifyChange = (e) => {
    const { name, value } = e.target;
    let num = Number(name);

    for (let r = 0; r < 6; r++) {
      if (num === r) {
        if (value.length > 0) {
          if (r < 5) {
            console.log(0);
            vfcode[num + 1].current.focus();
            break;
          }
        }
      }
    }
    // switch (name) {
    //   case "0":
    //     console.log(0);
    //     vfcode1.current.focus();
    //     break;
    //   case "1":
    //     console.log(1);
    //     vfcode2.current.focus();
    //     break;
    //   case "2":
    //     console.log(2);
    //     vfcode3.current.focus();
    //     break;
    //   case "3":
    //     console.log(3);
    //     vfcode4.current.focus();
    //     break;
    //   case "4":
    //     console.log(4);
    //     vfcode5.current.focus();
    //     break;
    //   case "5":
    //     console.log(4);
    //     vfcode5.current.focus();
    //   default:
    //     break;
    // }

    setVerifyValues((prevValues) => {
      const splitIdx = Number(name);
      return [
        ...prevValues.splice(0, splitIdx),
        value,
        ...prevValues.splice(splitIdx + 1),
      ];
    });
  };

  const handleVerifySubmit = async (e) => {
    e.preventDefault();
    const verification_code = verifyValues.reduce((a, b) => a + b);
    const { code, msg } = verify_email({
      email: registerValues.email,
      verification_code,
    });
    if (code >= 300) {
      console.log(code, msg);
      return;
    }
    const { email, password } = registerValues;
    setRegisterValues((prevValues) => ({
      ...prevValues,
      ...INITIAL_REGISTER_VALUES,
    }));
    setVerifyValues((prevValues) => ({
      ...prevValues,
      ...INITIAL_VERIFY_VALUES,
    }));
    setLogIn({ email, password });
    setMethod("로그인 완료");
  };

  useEffect(() => {
    setErrorMessage((prevState) => ({
      ...prevState,
      message: "",
    }));
  }, [method, isOpen]);

  useEffect(() => {
    if (registerValues.password !== registerValues.passwordCheck) {
      setErrorMessage((prevState) => ({
        ...prevState,
        message: "비밀번호가 일치하지 않습니다",
        confirmed: false,
      }));
    } else {
      setErrorMessage((prevState) => ({
        ...prevState,
        message: "",
        confirmed: true,
      }));
    }
  }, [registerValues]);

  return (
    <>
      <button className={styles.loginButton} onClick={() => setIsOpen(true)}>
        <h2>로그인</h2>
      </button>
      {isOpen && (
        <div
          className={styles.modalContainer}
          ref={modalBackground}
          onClick={(e) => {
            if (e.target === modalBackground.current) {
              setIsOpen(false);
            }
          }}
        >
          <div className={styles.modalContent}>
            <div className={styles.modalHead}>
              <p className={styles.login}>{method}</p>
              <div
                className={styles.closePopup}
                onClick={() => setIsOpen(false)}
              >
                <img src={closeIcon} alt="닫기" />
              </div>
            </div>
            {(() => {
              switch (method) {
                case "로그인":
                  return (
                    <div className={styles.modalBody}>
                      <p>로그인하면 더 많은 기능을 이용하실 수 있어요!</p>
                      <form
                        className={styles.formContainer}
                        onSubmit={handleLoginSubmit}
                      >
                        <div
                          className={classNames(
                            styles.inputContainer,
                            styles.email
                          )}
                        >
                          <label>
                            <div className={styles.inputLabelContainer}>
                              <div className={styles.inputLabel}>이메일</div>
                            </div>
                            <input
                              type="text"
                              name="email"
                              inputMode="email"
                              autoComplete="off"
                              className={styles.input}
                              onChange={handleLoginChange}
                            />
                          </label>
                        </div>
                        <div
                          className={classNames(
                            styles.inputContainer,
                            styles.password
                          )}
                        >
                          <label>
                            <div className={styles.inputLabelContainer}>
                              <div className={styles.inputLabel}>비밀번호</div>
                            </div>
                            <input
                              type="password"
                              name="password"
                              autoComplete="off"
                              className={styles.input}
                              onChange={handleLoginChange}
                            />
                          </label>
                        </div>
                        <p className={styles.errorMsg}>
                          {errorMessage.message}
                        </p>
                        <div>
                          <button type="submit" className={styles.submitButton}>
                            <div className={styles.submitLabel}>
                              로그인 하기
                            </div>
                          </button>
                        </div>
                      </form>
                      <div className={styles.signInMenuContainer}>
                        {/* <div className={styles.oauthSignInContainer}>
                          <p>SNS 간편 로그인 / 가입</p>
                          <div
                            className={classNames(
                              styles.iconContainer,
                              styles.google
                            )}
                          ></div>
                          <div
                            className={classNames(
                              styles.iconContainer,
                              styles.kakao
                            )}
                          ></div>
                        </div> */}
                        <div className={styles.alignRight}>
                          <p onClick={() => setMethod("회원가입")}>
                            이메일로 가입하기
                          </p>
                        </div>
                        {/* <div className={styles.alignRight}>
                          <p onClick={() => setMethod("비밀번호 찾기")}>
                            비밀번호 찾기
                          </p>
                        </div> */}
                      </div>
                    </div>
                  );
                case "회원가입":
                  console.log("2");
                  return (
                    <div className={styles.modalBody}>
                      <form
                        className={styles.formContainer}
                        onSubmit={handleRegisterSubmit}
                      >
                        <div
                          className={classNames(
                            styles.inputContainer,
                            styles.email
                          )}
                        >
                          <label>
                            <div className={styles.inputLabelContainer}>
                              <div className={styles.inputLabel}>닉네임</div>
                            </div>
                            <input
                              type="text"
                              name="username"
                              autoComplete="off"
                              className={styles.input}
                              onChange={handleRegisterChange}
                            />
                          </label>
                        </div>
                        <div
                          className={classNames(
                            styles.inputContainer,
                            styles.email
                          )}
                        >
                          <label>
                            <div className={styles.inputLabelContainer}>
                              <div className={styles.inputLabel}>이메일</div>
                            </div>
                            <input
                              type="text"
                              name="email"
                              inputMode="email"
                              autoComplete="off"
                              className={styles.input}
                              onChange={handleRegisterChange}
                            />
                          </label>
                        </div>
                        <div
                          className={classNames(
                            styles.inputContainer,
                            styles.password
                          )}
                        >
                          <label>
                            <div className={styles.inputLabelContainer}>
                              <div className={styles.inputLabel}>비밀번호</div>
                            </div>
                            <input
                              type="password"
                              name="password"
                              autoComplete="off"
                              className={styles.input}
                              onChange={handleRegisterChange}
                            />
                          </label>
                        </div>
                        <div
                          className={classNames(
                            styles.inputContainer,
                            styles.password
                          )}
                        >
                          <label>
                            <div className={styles.inputLabelContainer}>
                              <div className={styles.inputLabel}>
                                비밀번호 확인
                              </div>
                            </div>
                            <input
                              type="password"
                              name="passwordCheck"
                              autoComplete="off"
                              className={styles.input}
                              onChange={handleRegisterChange}
                            />
                          </label>
                        </div>
                        <p className={styles.errorMsg}>
                          {errorMessage.message}
                        </p>
                        <div>
                          <button type="submit" className={styles.submitButton}>
                            <div className={styles.submitLabel}>
                              이메일 인증하기
                            </div>
                          </button>
                        </div>
                      </form>
                      <div className={styles.signInMenuContainer}>
                        <div className={styles.alignRight}>
                          <p onClick={() => setMethod("로그인")}>
                            로그인 화면으로 돌아가기
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                case "이메일 인증":
                  console.log("3");
                  return (
                    <div className={styles.modalBody}>
                      <form
                        className={styles.formContainer}
                        onSubmit={handleVerifySubmit}
                      >
                        <div className={styles.verticalInput}>
                          <input
                            ref={vfcode0}
                            name="0"
                            type="number"
                            maxLength="1"
                            autoComplete="off"
                            onChange={handleVerifyChange}
                          />
                          <input
                            ref={vfcode1}
                            name="1"
                            type="number"
                            maxLength="1"
                            autoComplete="off"
                            onChange={handleVerifyChange}
                          />
                          <input
                            ref={vfcode2}
                            name="2"
                            type="number"
                            maxLength="1"
                            autoComplete="off"
                            onChange={handleVerifyChange}
                          />
                          <input
                            ref={vfcode3}
                            name="3"
                            type="number"
                            maxLength="1"
                            autoComplete="off"
                            onChange={handleVerifyChange}
                          />
                          <input
                            ref={vfcode4}
                            name="4"
                            type="number"
                            maxLength="1"
                            autoComplete="off"
                            onChange={handleVerifyChange}
                          />
                          <input
                            ref={vfcode5}
                            name="5"
                            type="number"
                            maxLength="1"
                            autoComplete="off"
                            onChange={handleVerifyChange}
                          />
                        </div>
                        <div>
                          <button type="submit" className={styles.submitButton}>
                            <div className={styles.submitLabel}>전송하기</div>
                          </button>
                        </div>
                      </form>
                    </div>
                  );
                case "비밀번호 찾기":
                  console.log("4");
                  return <></>;
                case "회원가입 완료":
                  return (
                    <div
                      className={classNames(
                        styles.modalBody,
                        styles.signinSucceed
                      )}
                    >
                      <div className={styles.congratsBox}>
                        <span className={styles.congrats}>
                          &#127881;회원가입을&#127881;
                        </span>
                        <span className={styles.congrats}>완료하셨습니다!</span>
                      </div>
                      <div>
                        <button
                          onClick={() => setMethod("로그인")}
                          className={styles.submitButton}
                        >
                          <div className={styles.submitLabel}>로그인하기</div>
                        </button>
                      </div>
                    </div>
                  );
                default:
                  console.log("default");
                  return <p>오류!</p>;
              }
            })()}
          </div>
        </div>
      )}
    </>
  );
}

export default Login;
