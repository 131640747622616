// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TradeMemoPage_container__8YWDW {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 24px;
}

.TradeMemoPage_title__00n8v {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.TradeMemoPage_tradeItem__8RJHO {
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.TradeMemoPage_tradeTitle__787LA {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/pages/TradeMemoPage.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,wCAAwC;EACxC,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,gCAAgC;EAChC,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".container {\n  background-color: white;\n  padding: 24px;\n  border-radius: 8px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  margin-top: 24px;\n}\n\n.title {\n  font-size: 24px;\n  font-weight: bold;\n  margin-bottom: 16px;\n}\n\n.tradeItem {\n  border-bottom: 1px solid #e2e8f0;\n  padding-bottom: 16px;\n  margin-bottom: 16px;\n}\n\n.tradeTitle {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TradeMemoPage_container__8YWDW`,
	"title": `TradeMemoPage_title__00n8v`,
	"tradeItem": `TradeMemoPage_tradeItem__8RJHO`,
	"tradeTitle": `TradeMemoPage_tradeTitle__787LA`
};
export default ___CSS_LOADER_EXPORT___;
