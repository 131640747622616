const base = `${process.env.REACT_APP_API_URL}api/auth`;

function formingData(apiData) {
  const formData = new FormData();
  for (const dataKey in apiData) {
    formData.append(`${dataKey}`, apiData[dataKey]);
  }
  return formData;
}

export async function login(userData) {
  const res = await fetch(`${base}/login`, {
    method: "POST",
    body: formingData(userData),
    credentials: "include",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    const { user, access_token, refresh_token, post: posts } = data;
    const nextUser = { ...user, posts };
    const nextToken = { access_token, refresh_token };
    return { code, msg, nextUser, nextToken, posts };
  } else {
    return { code, msg, nextUser: null, nextToken: null, posts: null };
  }
}

export async function logout(token) {
  const res = await fetch(`${base}/logout`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function register(userData) {
  const res = await fetch(`${base}/register`, {
    method: "POST",
    body: formingData(userData),
    credentials: "include",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function verify_email(userData) {
  const res = await fetch(`${base}/verify-email`, {
    method: "POST",
    body: formingData(userData),
    credentials: "include",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg } = res;

  return { code, msg };
}

export async function resend_code(userData) {
  const res = await fetch(`${base}/resend-code`, {
    method: "POST",
    body: formingData(userData),
    credentials: "include",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function refresh_token(userData) {
  const res = await fetch(`${base}/refresh-token`, {
    method: "POST",
    body: formingData(userData),
    credentials: "include",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    const { access_token } = data;
    return { code, msg, access_token };
  } else {
    return { code, msg, access_token: null };
  }
}
