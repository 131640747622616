import { useNestock } from "../contexts/NestockProvider";
import { useAuth } from "../contexts/AuthProvider";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getPost,
  getListOfImageOnPost,
  getListOfCommentOnPost,
  deletePost,
  createComment,
  updateComment,
  deleteComment,
} from "../api/PostApi";
import ShowPost from "../components/ShowPost";
import ShowPostProfile from "../components/ShowPostProfile";
import InteractionMenu from "../components/InteractionMenu";
import { ReactComponent as CommmentIcon } from "../assets/icon--comment.svg";
import arrow from "../assets/icon--arrow-to-left.svg";
import person from "../assets/person.svg";
import styles from "./PostPage.module.css";
import pageStyle from "../components/ui/PageBox.module.css";
import classNames from "classnames";

function ShowComment({ info }) {
  const {
    id,
    User,
    parent_id,
    // is_anonymous,
    content,
    likes,
    created_at,
    updated_at,
    onUpdate,
    onDelete,
  } = info;

  // window.addEventListener("unload", () => {
  // 페이지가 언로드될 때 실행할 코드 작성 (추천/비추천 post 보내기)
  // });
  return (
    <div className={styles.commentBox}>
      <ShowPostProfile
        isComment={true}
        showOnlyUser={true}
        profile={{ User }}
        time={{ created_at, updated_at }}
        onUpdate={onUpdate}
        onDelete={onDelete}
      />
      <div className={styles.commentContainer}>
        <p>{content}</p>
        <InteractionMenu type="comments" likes={likes} id={id} />
      </div>
    </div>
  );
}

function EditComment({ info, onCancel, onSubmit }) {
  const {
    id,
    user_id,
    username,
    post_id,
    parent_id,
    // is_anonymous,
    content,
    created_at,
    updated_at,
  } = info;
  const [editted, setEditted] = useState(content);
  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setEditted(value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ comment_id: id, content: editted });
  };

  // window.addEventListener("unload", () => {
  // 페이지가 언로드될 때 실행할 코드 작성 (추천/비추천 post 보내기)
  // });
  return (
    <div className={styles.commentBox}>
      <ShowPostProfile
        isComment={true}
        showOnlyUser={true}
        profile={{ User: { user_id, username }, parent_id }}
        typeAndIds={{ type: "comments", ids: { post_id, comment_id: id } }}
        time={{ created_at, updated_at }}
      />
      <div className={styles.commentContainer}>
        <input
          className={styles.editInput}
          onChange={handleChange}
          value={editted}
        />
      </div>
      <div className={styles.buttonBox}>
        <button className={styles.editButton} onClick={handleSubmit}>
          <h3>완료</h3>
        </button>
        <button className={styles.editButton} onClick={onCancel}>
          <h3>취소</h3>
        </button>
      </div>
    </div>
  );
}

function PostPage() {
  const {
    userList,
    refreshUserList,
    communityList,
    updatePostList,
    postList,
    setPostList,
  } = useNestock();
  const { user, checkingToken } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const post_id = Number(location.pathname.split("/")[4]);
  const [post, setPost] = useState();
  const [edittingID, setEdittingID] = useState(null);
  const INITIAL_VALUES = {
    post_id,
    content: "",
    // parent_id: "",
  };
  const [myComment, setMyComment] = useState(INITIAL_VALUES);
  const handleUpdatePost = () => {
    navigate(`/posting/${post_id}`, {
      state: {
        user_id: post.User.id,
        title: post.title,
        content: post.content,
      },
    });
  };
  const handleDeletePost = async () => {
    const confirmed = window.confirm("해당 게시물을 지우시겠습니까?");
    if (!confirmed) return;
    const { code } = await checkingToken(
      async (token) => await deletePost({ post_id }, token)
    );
    if (code < 300) {
      updatePostList(post_id);
      navigate(-1);
    }
  };
  const handleChangeComment = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setMyComment((prevComment) => ({ ...prevComment, content: value }));
  };
  const handleSubmitComment = async (e) => {
    e.preventDefault();
    if (user.id === 0) {
      alert("로그인 후 이용해주세요");
      return;
    }
    const {
      code,
      data: { user_id, ...comment },
    } = await checkingToken(
      async (token) => await createComment(myComment, token)
    );
    if (code < 300) {
      const newComment = {
        ...comment,
        User: {
          user_id,
          userName: user.userName,
          user_profile: user.profile_image,
        },
        likes: 0,
      };
      updatePostList(post_id, { type: "create", newComment });
      // setPost((prevPost) => {
      //   const nextComments = prevPost.comments;
      //   nextComments.push(newComment);
      //   return { ...prevPost, comments: nextComments };
      // });
      setMyComment((prevComment) => ({ ...prevComment, ...INITIAL_VALUES }));
    }
  };
  const handleUpdateCommentClick = (comment_id) => setEdittingID(comment_id);
  const handleUpdateCommentCancel = (e) => {
    e.preventDefault();
    setEdittingID(null);
  };
  const handleUpdateComment = async (comment) => {
    const {
      code,
      data: { id, content },
    } = await checkingToken(
      async (token) => await updateComment(comment, token)
    );
    if (code < 300) {
      const replaceIdx = post.comments.findIndex(
        (comment) => comment.id === id
      );
      updatePostList(post_id, {
        type: "update",
        commentID: id,
        newComment: {
          content,
        },
      });
      setPost((prevPost) => {
        const newComments = [
          ...prevPost.comments.slice(0, replaceIdx),
          {
            ...prevPost.comments[replaceIdx],
            content,
          },
          ...prevPost.comments.slice(replaceIdx + 1),
        ];
        return { ...prevPost, comments: newComments };
      });
    }
    setEdittingID(null);
  };
  const handleDeleteComment = async (comment_id) => {
    const confirmed = window.confirm("해당 댓글을 지우시겠습니까?");
    if (!confirmed) return;
    const { code } = await checkingToken(
      async (token) => await deleteComment({ comment_id }, token)
    );
    if (code < 300) {
      setPost((prevPost) => {
        const newComments = prevPost.comments;
        return {
          ...prevPost,
          comments: newComments.filter((comment) => comment.id !== comment_id),
        };
      });
      updatePostList(post_id, { type: "delete", commentID: comment_id });
    }
  };

  useEffect(() => {
    async function GettingPost() {
      const thatPost = postList.find(({ id }) => id === post_id);
      if (thatPost) {
        setPost(thatPost);
        return;
      }
      const { data: postData } = await getPost({ post_id });
      if (postData) {
        let images = [];
        const { code, msg, data } = await getListOfImageOnPost({ post_id });
        if (!data) console.log(code, msg);
        else images = [...data];
        let comments = [];
        let page = 1;
        let isMoreLeft = true;
        do {
          const { data: commentData } = await getListOfCommentOnPost({
            post_id,
            page,
            page_size: 20,
          });
          if (commentData.length < 20) isMoreLeft = false;
          const newComments = commentData
            .map((comment) => {
              const { user_id, ...rest } = comment;
              const theUser = userList.find((user) => user.id === user_id);
              if (theUser)
                return {
                  User: {
                    user_id,
                    userName: theUser.userName,
                    user_profile: theUser.profile_image,
                  },
                  ...rest,
                };
              else {
                refreshUserList();
                return {
                  User: {
                    user_id,
                    userName: "알 수 없음",
                    user_profile:
                      "https://nestock-image.s3.ap-northeast-2.amazonaws.com/community_image/default_profile.jpeg",
                  },
                  ...rest,
                };
              }
            })
            .reverse();
          comments = [...comments, ...newComments];
        } while (isMoreLeft);
        const {
          status,
          community_id,
          user_id,
          view_count,
          updated_at,
          User: { username },
          ...restProps
        } = postData;
        const commuName = communityList.find(
          (commu) => commu.id === community_id
        )?.communityName;
        const newPost = {
          ...restProps,
          User: { id: user_id, username },
          Community: { id: community_id, name: commuName },
          comments,
          images,
          status,
          view_count,
          updated_at,
        };
        setPostList((prevList) => [...prevList, newPost]);
        setPost(newPost);
      }
    }
    GettingPost();
  }, []);
  if (!post) return <div>Loading...</div>;
  return (
    <div className={classNames(pageStyle.pageBox, styles.pageBox)}>
      <div className={styles.communityBox}>
        <div className={styles.imgBox} onClick={() => navigate(-1)}>
          <img src={arrow} alt="돌아가기" />
        </div>
        <Link to={`/nest/${post.Community.id}`}>
          <h4 className={styles.community}>{post.Community.name} 커뮤니티</h4>
        </Link>
      </div>
      <div className={styles.postBox}>
        <ShowPost
          value={post}
          imgList={post?.images}
          isPage={true}
          onUpdate={handleUpdatePost}
          onDelete={handleDeletePost}
        />
      </div>
      <div className={styles.commentListBox}>
        <h2>댓글 {post.comments.length}개</h2>
        {post.comments.length > 0 &&
          post.comments.map(({ id, ...comment }) => {
            if (id === edittingID) {
              return (
                <EditComment
                  key={id}
                  info={{ id, ...comment }}
                  onCancel={handleUpdateCommentCancel}
                  onSubmit={handleUpdateComment}
                />
              );
            } else {
              return (
                <ShowComment
                  key={id}
                  info={{
                    id,
                    ...comment,
                    post,
                    onUpdate: () => handleUpdateCommentClick(id),
                    onDelete: () => handleDeleteComment(id),
                  }}
                />
              );
            }
          })}
      </div>
      <div className={styles.commentInputBox}>
        <form className={styles.commentForm} onSubmit={handleSubmitComment}>
          <label className={styles.commentLabel} htmlFor="comment">
            <img className={styles.img} src={person} alt="이미지" />
          </label>
          <input
            className={styles.commentInput}
            type="text"
            name="content"
            placeholder="댓글 입력..."
            autoComplete="off"
            required
            onChange={handleChangeComment}
            value={myComment.content}
          />
          <button type="submit" className={styles.commentSubmit}>
            <CommmentIcon fill="#fff" />
          </button>
        </form>
      </div>
    </div>
  );
}

export default PostPage;
