// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ListStyles_innerBox__13WJr:not(:last-of-type) {
  border-bottom: var(--border-line);
}

.ListStyles_listElem__-1-Hi {
  margin: 5px 0;
  margin-right: 10px;
  border-radius: 15px;
  padding: 5px 10px;
  padding-right: 0;
}

.ListStyles_listElem__-1-Hi.ListStyles_admin__BZjCP {
  padding-bottom: 45px;
  position: relative;
}

.ListStyles_listElem__-1-Hi :where(h2, p) {
  cursor: default;
}

.ListStyles_listElem__-1-Hi:hover {
  background-color: rgb(240, 240, 240);
}

.ListStyles_profileBox__yT96F {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ListStyles_profile__jE3Qn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ListStyles_profileIMG__Dh3pf {
  width: 40px;
  height: 40px;
  border-radius: 23px;
  margin-right: 5px;
  object-fit: cover;
  object-position: center;
}

.ListStyles_buttonBox__8huBb {
  width: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
}

.ListStyles_buttonBox__8huBb.ListStyles_user__i3Zs3 {
  width: 240px;
}

.ListStyles_button__7IEtK {
  width: 60px;
  height: 35px;
  padding: 0;
  border-radius: 10px;
  background-color: rgb(220, 220, 220);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ListStyles_button__7IEtK.ListStyles_user__i3Zs3 {
  width: 110px;
}

.ListStyles_button__7IEtK:hover {
  background-color: rgb(200, 200, 200);
}

.ListStyles_button__7IEtK > p {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/ListStyles.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,UAAU;EACV,mBAAmB;EACnB,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,SAAS;AACX","sourcesContent":[".innerBox:not(:last-of-type) {\n  border-bottom: var(--border-line);\n}\n\n.listElem {\n  margin: 5px 0;\n  margin-right: 10px;\n  border-radius: 15px;\n  padding: 5px 10px;\n  padding-right: 0;\n}\n\n.listElem.admin {\n  padding-bottom: 45px;\n  position: relative;\n}\n\n.listElem :where(h2, p) {\n  cursor: default;\n}\n\n.listElem:hover {\n  background-color: rgb(240, 240, 240);\n}\n\n.profileBox {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.profile {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.profileIMG {\n  width: 40px;\n  height: 40px;\n  border-radius: 23px;\n  margin-right: 5px;\n  object-fit: cover;\n  object-position: center;\n}\n\n.buttonBox {\n  width: 140px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  position: absolute;\n}\n\n.buttonBox.user {\n  width: 240px;\n}\n\n.button {\n  width: 60px;\n  height: 35px;\n  padding: 0;\n  border-radius: 10px;\n  background-color: rgb(220, 220, 220);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.button.user {\n  width: 110px;\n}\n\n.button:hover {\n  background-color: rgb(200, 200, 200);\n}\n\n.button > p {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerBox": `ListStyles_innerBox__13WJr`,
	"listElem": `ListStyles_listElem__-1-Hi`,
	"admin": `ListStyles_admin__BZjCP`,
	"profileBox": `ListStyles_profileBox__yT96F`,
	"profile": `ListStyles_profile__jE3Qn`,
	"profileIMG": `ListStyles_profileIMG__Dh3pf`,
	"buttonBox": `ListStyles_buttonBox__8huBb`,
	"user": `ListStyles_user__i3Zs3`,
	"button": `ListStyles_button__7IEtK`
};
export default ___CSS_LOADER_EXPORT___;
