import { useEffect, useRef, useState } from "react";
import ShowPost from "./ShowPost";
import styles from "./PostList.module.css";
import { useAuth } from "../contexts/AuthProvider";

function PostList({
  showOnlyUser,
  posts = [],
  handleLoadMore,
  onUpdate,
  onDelete,
}) {
  const { likeAndDislikeList, resetCurrentValue } = useAuth();
  const [nothingLeft, setNothingLeft] = useState(false);
  const target = useRef();
  const observerRef = useRef(null);
  const isInitialLoadRef = useRef(true); // useRef로 변경

  useEffect(() => {
    const observed = async (entries) => {
      if (entries[0].isIntersecting) {
        if (isInitialLoadRef.current) {
          // 초기 로드일 때는 handleLoadMore 실행하고 플래그만 변경
          const page = await handleLoadMore();
          if (!page) setNothingLeft(true);
          isInitialLoadRef.current = false;
          return;
        }

        const page = await handleLoadMore();
        if (!page) setNothingLeft(true);
      }
    };

    observerRef.current = new IntersectionObserver(observed, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    if (!nothingLeft && target.current) {
      observerRef.current.observe(target.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [nothingLeft, handleLoadMore]);

  useEffect(() => {
    posts.forEach((post) => {
      if (likeAndDislikeList.posts.find(({ id }) => id === post.id))
        resetCurrentValue({ type: "posts", id: post.id, likes: post.likes });
    });
  }, [posts]);

  return (
    <>
      {posts.map((post) => (
        <div key={post.id} className={styles.innerBox}>
          <div className={styles.post}>
            <ShowPost
              isOnList={true}
              value={post}
              showOnlyUser={showOnlyUser}
              onUpdate={() => onUpdate(post)}
              onDelete={() => onDelete(post.id)}
            />
          </div>
        </div>
      ))}
      {!nothingLeft && <div className={styles.loadMore} ref={target}></div>}
    </>
  );
}

export default PostList;
