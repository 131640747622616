import { useEffect, useState } from "react";
import { approvePendingCommunity, deleteCommunity } from "../api/CommunityApi";
import CommunityList from "./CommunityList";
import UserList from "./UserList";
import { ReactComponent as Search } from "../assets/search.svg";
import styles from "./TabContent.module.css";

function ManageMode({
  data = { nests: [], users: [] },
  activeTab,
  token = "",
  // badges
}) {
  const [keyword, setKeyword] = useState("");
  const { nests, users } = data;
  const [filteredList, setFilteredList] = useState(nests);

  const handleKeywordChange = (e) => {
    const newKeyword = e.target.value;
    setKeyword(newKeyword);
    if (!newKeyword) setFilteredList(data[activeTab]);
    const lowered = newKeyword.toLowerCase();
    switch (activeTab) {
      case "nests":
        setFilteredList(
          nests.filter(({ name }) => name.toLowerCase().includes(lowered))
        );
        break;
      case "users":
        setFilteredList(
          users.filter(({ username }) =>
            username.toLowerCase().includes(lowered)
          )
        );
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    setFilteredList(data[activeTab]);
    setKeyword("");
  }, [activeTab]);

  return (
    <>
      <div className={styles.searchBox}>
        <div className={styles.imgBox}>
          <Search />
        </div>
        <input
          id="searchInput"
          name="keyword"
          value={keyword}
          onChange={handleKeywordChange}
          autoComplete="off"
          className={styles.searchInput}
        />
      </div>
      {(() => {
        switch (activeTab) {
          case "nests":
            return (
              <CommunityList
                nests={filteredList}
                handleFunctions={{
                  handleApprove: approvePendingCommunity,
                  handleDelete: deleteCommunity,
                }}
                manageMode={{
                  token,
                }}
              />
            );
          case "users":
            return (
              <UserList
                users={filteredList}
                manageMode={{
                  token,
                }}
              />
            );
          default:
            console.log("activeTab 오류: ", activeTab);
            return;
        }
      })()}
    </>
  );

  // return <div className={styles.mainContent}>{content}</div>;
}

export default ManageMode;
