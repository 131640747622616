import { createContext, useContext, useEffect, useState } from "react";
import { getCommunity } from "../api/CommunityApi";
import { getPost, getListOfPost } from "../api/PostApi";

const NestockContext = createContext();

function NestockProvider({ children }) {
  const [communityList, setCommunityList] = useState([]);
  const [postList, setPostList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getCommunityAndPost() {
      try {
        setLoading(true);
        const { code, msg, data: list } = await getCommunity();
        if (code >= 300) {
          console.log(code, msg);
          return;
        }

        setCommunityList(list);

        const postLists_1 = await Promise.all(
          list.map((community) =>
            getListOfPost({
              community_id: community.id,
              page: 1,
              page_size: 20,
            })
          )
        );

        const flattenedPosts = postLists_1.flatMap((response) => response.data);

        const postLists_2 = await Promise.all(
          flattenedPosts.map((post) => getPost({ post_id: post.id }))
        );

        setPostList(postLists_2.map((response) => response.data));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    getCommunityAndPost();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // 또는 로딩 컴포넌트
  }

  return (
    <NestockContext.Provider
      value={{ communityList, setCommunityList, postList, setPostList }}
    >
      {children}
    </NestockContext.Provider>
  );
}

function useNestock() {
  const context = useContext(NestockContext);

  if (!context) {
    throw new Error("반드시 AuthProvider 안에서 사용해야 합니다.");
  }

  return context;
}

export { NestockProvider, useNestock };
