// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DateText_date__wphPK {
  color: rgb(100, 100, 100);
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/DateText.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":[".date {\n  color: rgb(100, 100, 100);\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": `DateText_date__wphPK`
};
export default ___CSS_LOADER_EXPORT___;
