import { useNestock } from "../contexts/NestockProvider";
import { useNavigate } from "react-router-dom";
import ShowPostProfile from "./ShowPostProfile";
import InteractionMenu from "./InteractionMenu";
import styles from "./ShowPost.module.css";
import classNames from "classnames";

function ShowPost({
  isOnList = false,
  value,
  imgList,
  showOnlyUser = true,
  isPage = false,
  onUpdate,
  onDelete,
}) {
  const { userList, communityList } = useNestock();
  // const [links, setLinks] = useState;
  const navigate = useNavigate();
  const {
    id,
    title,
    content,
    likes,
    created_at,
    User: { id: user_id, username: userName },
    Community: { id: community_id, name: communityName },
    comments,
    ///////////////
    view_count,
    updated_at,
  } = value;

  const HyperlinkText = ({ text }) => {
    // URL을 감지하는 정규식
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // 텍스트를 URL 기준으로 분리하고 하이퍼링크로 변환
    const parsedText = text.split(urlRegex).map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#ff6f00" }}
          >
            {part}
          </a>
        );
      }
      return part;
    });

    return (
      <p className={classNames(styles.post, !isPage && styles.preview)}>
        {parsedText}
      </p>
    );
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (isPage) return;
    navigate(`/nest/${community_id}/post/${id}`);
  };

  return (
    <>
      <ShowPostProfile
        isOnList={isOnList}
        showOnlyUser={showOnlyUser}
        profile={{
          User: {
            user_id,
            userName,
            user_profile: userList.find((user) => user.id === user_id)
              ?.profile_image,
          },
          Community: {
            community_id,
            communityName,
            community_profile: communityList.find(
              (commu) => commu.id === community_id
            )?.profile_image,
          },
        }}
        time={{ created_at, updated_at }}
        onUpdate={onUpdate}
        onDelete={onDelete}
      />
      <div className={styles.postContainer} onClick={handleClick}>
        <div className={styles.texts}>
          <h2>{title}</h2>
          <HyperlinkText text={content} />
          <div className={styles.imgListBox}>
            {imgList &&
              imgList.map(({ id, image_url }) => (
                <img
                  key={id}
                  className={styles.img}
                  src={image_url}
                  alt="이미지"
                />
              ))}
          </div>
          {isPage && <h4 className={styles.viewCount}>조회 {view_count}</h4>}
        </div>
      </div>
      <InteractionMenu
        isOnList={isOnList}
        likes={likes}
        comments={typeof comments === "number" ? comments : comments.length}
        id={id}
      />
    </>
  );
}

export default ShowPost;
