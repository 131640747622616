import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Plus } from "../assets/icon--plus.svg";
import { ReactComponent as Back } from "../assets/icon--arrow-to-left.svg";
import pageStyle from "../components/ui/PageBox.module.css";
import styles from "./ProfileEditPage.module.css";
import NotFoundPage from "./NotFoundPage";
import { useAuth } from "../contexts/AuthProvider";
import { updateCommunityImg } from "../api/CommunityApi";
import { updateUserProfileImg, updateUserName } from "../api/UserApi";
import { useNestock } from "../contexts/NestockProvider";

function ProfileEditPage() {
  const { isOnline, user, checkingToken, setUpdate } = useAuth();
  const { communityList, refreshCommunityList, userList, refreshUserList } =
    useNestock();
  const location = useLocation();
  const target_type = location.pathname.split("/")[1];
  const target_id = Number(location.pathname.split("/")[2]);
  const target = (() => {
    switch (target_type) {
      case "user":
        return userList.find((user) => user.id === target_id);
      case "nest":
        return communityList.find((commu) => commu.id === target_id);
      default:
        return;
    }
  })();
  const initial_Image = { image: null };
  const initial_message = { message: "" };
  const [newImage, setNewImage] = useState(initial_Image);
  const [errorMessage, setErrorMessage] = useState(initial_message);
  const [preview, setPreview] = useState(target.profile_image);
  const inputRef = useRef();
  const [newUsername, setNewUsername] = useState(user.userName);
  // const [about, setAbout] = useState("");
  const navigate = useNavigate();
  // const maxLength = 100;
  const handleImageChange = (e) => {
    const nextImage = e.target.files[0];
    setNewImage((prevImage) => ({
      ...prevImage,
      image: nextImage,
    }));
  };
  const handleUsernameChange = (e) => {
    const nextUsername = e.target.value;
    if (userList.map((user) => user.username).includes(nextUsername)) {
      setErrorMessage((prevState) => ({
        ...prevState,
        message: "이미 존재하는 닉네임입니다",
      }));
    } else if (
      ["nestock", "Nestock", "NESTOCK", "네스톡"].includes(nextUsername)
    ) {
      setErrorMessage((prevState) => ({
        ...prevState,
        message: "사용할 수 없는 닉네임입니다",
      }));
    } else if (
      [
        "~",
        "!",
        "@",
        "#",
        "$",
        "%",
        '"',
        ";",
        "'",
        "^",
        ",",
        "&",
        "*",
        "(",
        ")",
        "_",
        "+",
        "|",
        "=",
        "/",
        ">",
        "<",
        "`",
        "?",
        ":",
        "{",
        "[",
        "}",
        "]",
        "/",
      ].some((chrctr) => nextUsername.includes(chrctr))
    ) {
      setErrorMessage((prevState) => ({
        ...prevState,
        message: "닉네임에는 특수문자를 사용할 수 없습니다",
      }));
    } else if (nextUsername.length > 11) {
      setErrorMessage((prevState) => ({
        ...prevState,
        message: "닉네임은 11자까지 작성할 수 있습니다",
      }));
    } else {
      setErrorMessage((prevState) => ({
        ...prevState,
        message: "",
      }));
    }
    setNewUsername(nextUsername);
  };
  const handleUserSubmit = async () => {
    let confirmed;
    if (!errorMessage.message && newUsername) confirmed = true;
    if (!confirmed) return;
    if (newImage.image && newUsername && user.userName !== newUsername) {
      const {
        code: code1,
        data: data1,
        access_token,
      } = await checkingToken(
        async (token) => await updateUserName({ username: newUsername }, token)
      );
      if (code1 < 300 && !access_token) {
        setUpdate({ userName: data1.username });
        refreshUserList();
        return;
      } else if (code1 >= 300) return;
      const { code: code2, data: data2 } = await updateUserProfileImg(
        newImage,
        access_token
      );
      if (code2 >= 300) {
        alert("실패했습니다");
        navigate(-1);
      } else {
        alert("저장되었습니다");
        navigate(-1);
        setUpdate({ profile_image: data2 });
        refreshUserList();
      }
    } else if (
      !newImage.image &&
      newUsername &&
      user.userName !== newUsername
    ) {
      const { code, data } = await checkingToken(
        async (token) => await updateUserName({ username: newUsername }, token)
      );
      if (code >= 300) {
        alert("실패했습니다");
        navigate(-1);
      } else {
        alert("저장되었습니다");
        navigate(-1);
        setUpdate({ userName: data.username });
        refreshUserList();
      }
    } else if (
      newImage.image &&
      (!newUsername || user.userName === newUsername)
    ) {
      const { code, data } = await checkingToken(
        async (token) => await updateUserProfileImg(newImage, token)
      );
      if (code >= 300) {
        alert("실패했습니다");
        navigate(-1);
      } else {
        alert("저장되었습니다");
        navigate(-1);
        setUpdate({ profile_image: data });
        refreshUserList();
      }
    }
  };
  const handleNestSubmit = async () => {
    if (!newImage.image) return;
    const { code, data } = await checkingToken(async (token) => {
      const { code, msg, data } = await updateCommunityImg(
        { community_id: target_id, ...newImage },
        token
      );
      return { code, msg, data };
    });
    if (code >= 300) {
      alert("실패했습니다");
      navigate(-1);
    } else {
      alert("저장되었습니다");
      navigate(-1);
      console.log(data);
    }
  };

  useEffect(() => {
    if (!newImage.image) return;

    const nextPreview = URL.createObjectURL(newImage.image);
    setPreview(nextPreview);

    return () => {
      setPreview(null);
      URL.revokeObjectURL(nextPreview);
    };
  }, [newImage]);
  if (!isOnline)
    return (
      <NotFoundPage
        title="프로필을 수정할 수 없습니다"
        description="로그인하시고 이용해주세요"
      />
    );
  if (target_type === "user" && user.id !== target_id)
    return <NotFoundPage title="프로필을 수정할 수 없습니다" />;
  if (target_type === "nests" && user.id !== target.created_by)
    return <NotFoundPage title="프로필을 수정할 수 없습니다" />;
  return (
    <div className={pageStyle.pageBox}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <button onClick={() => navigate(-1)} className={styles.backButton}>
              <Back />
            </button>
            <h1 className={styles.pageTitle}>프로필 사진 편집</h1>
          </div>
          <button
            onClick={() => {
              switch (target_type) {
                case "user":
                  handleUserSubmit();
                  break;
                case "nest":
                  handleNestSubmit();
                  break;
                default:
                  return;
              }
            }}
            className={styles.saveButton}
          >
            저장
          </button>
        </div>
      </div>
      <div className={styles.mainContent}>
        <div className={styles.avatarSection}>
          <div className={styles.avatarWrapper}>
            <div className={styles.largeAvatar}>
              <img src={preview} alt="프로필" className={styles.avatarImage} />
            </div>
            <input
              type="file"
              accept="image/png, image/jpeg"
              className={styles.imageInput}
              onChange={handleImageChange}
              ref={inputRef}
            />
            <button
              type="button"
              className={styles.uploadButton}
              onClick={() => inputRef.current.click()}
            >
              <Plus width="20" height="20" />
            </button>
          </div>
        </div>
        {target_type === "user" && (
          <div className={styles.formSection}>
            <div className={styles.formGroup}>
              <label className={styles.label}>닉네임</label>
              <input
                type="text"
                value={newUsername}
                onChange={handleUsernameChange}
                className={styles.input}
                placeholder="닉네임을 입력하세요"
              />
            </div>
            {/* <div className={styles.formGroup}>
            <label className={styles.label}>소개글</label>
            <textarea
              disabled
              value={about}
              onChange={(e) => {
                if (e.target.value.length <= maxLength) {
                  setAbout(e.target.value);
                }
              }}
              className={styles.textarea}
              placeholder="자기소개를 입력하세요 (최대 100자)"
            />
            <div className={styles.charCount}>
              {about.length}/{maxLength}자
            </div>
          </div> */}
          </div>
        )}
        <p className={styles.errorMsg}>{errorMessage.message}</p>
      </div>
      <a className={styles.deleteAccount}>계정 삭제</a>
    </div>
  );
}

export default ProfileEditPage;
