import pageStyles from "../components/ui/PageBox.module.css";
import styles from "./DeleteAccountPage.module.css";

function DeleteAccountPage() {
  return (
    <div className={pageStyles.pageBox}>
      <h1 className={styles.title}>회원 탈퇴 및 계정 삭제</h1>
      <div className={styles.container}>
        <p className={styles.text}>해당 기능은 현재 준비 중입니다</p>
        <p className={styles.text}>
          자세한 사항은 idealoftyke@nestock.net 으로 문의 부탁드립니다
        </p>
      </div>
    </div>
  );
}

export default DeleteAccountPage;
