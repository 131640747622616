import React, { useState } from "react";
import styles from "./NotificationList.module.css";
import classNames from "classnames/bind";
const bindCN = classNames.bind(styles);

const NotificationItem = ({ notification, onDismiss }) => {
  const getNotificationIcon = (type) => {
    switch (type) {
      case "comment":
        return "💭";
      case "reply":
        return "↩️";
      case "calendar":
        return "📅";
      default:
        return "🔔";
    }
  };

  const getNotificationMessage = (notification) => {
    switch (notification.type) {
      case "comment":
        return `${notification.user}님이 회원님의 게시물에 댓글을 남겼습니다: "${notification.content}"`;
      case "reply":
        return `${notification.user}님이 회원님의 댓글에 답글을 남겼습니다: "${notification.content}"`;
      case "calendar":
        return `일정 알림: ${notification.content}`;
      default:
        return notification.content;
    }
  };

  return (
    <div className={styles.notificationItem}>
      <div className={styles.notificationIcon}>
        {getNotificationIcon(notification.type)}
      </div>
      <div className={styles.notificationContent}>
        <p className={styles.notificationMessage}>
          {getNotificationMessage(notification)}
        </p>
        <div className={styles.notificationMeta}>
          <span>{notification.time}</span>
          {notification.type !== "calendar" ? (
            <div>
              <button
                className={bindCN(styles.metaButton, styles.buttons)}
                onClick={() => alert(`게시물로 이동: ${notification.postId})`)}
              >
                게시물로 이동
              </button>
              <button
                className={bindCN(styles.metaButton, styles.buttons)}
                onClick={() => onDismiss(notification.id)}
              >
                알림 삭제
              </button>
            </div>
          ) : (
            <button
              className={styles.metaButton}
              onClick={() => onDismiss(notification.id)}
            >
              알림 삭제
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const NotificationList = () => {
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      type: "calendar",
      content: "주간 투자 전략 회의",
      time: "오늘 오후 3시",
    },
    {
      id: 2,
      type: "comment",
      user: "김투자",
      content: "이 종목에 대해 어떻게 생각하시나요?",
      time: "30분 전",
      postId: "post123",
    },
    {
      id: 3,
      type: "reply",
      user: "이분석",
      content: "네, 좋은 관점입니다. 추가로 재무제표를 살펴보면...",
      time: "5분 전",
      postId: "post123",
    },
  ]);

  const handleDismiss = (id) => {
    setNotifications(
      notifications.filter((notification) => notification.id !== id)
    );
  };

  return (
    <div className={styles.notificationContainer}>
      <div className={styles.notificationHeader}>
        <h2 className={styles.headerTitle}>새 알림</h2>
        <span>{notifications.length}</span>
      </div>
      {notifications.map((notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          onDismiss={handleDismiss}
        />
      ))}
      {notifications.length === 0 && (
        <p className={styles.emptyMessage}>새로운 알림이 없습니다.</p>
      )}
    </div>
  );
};

export default NotificationList;
