import { useNestock } from "../contexts/NestockProvider";
import { useLocation } from "react-router-dom";
import PostList from "../components/PostList";
import pageStyle from "../components/ui/PageBox.module.css";

function SearchResultPage() {
  const { postList } = useNestock();
  const keyword = useLocation().search.split("=")[1];
  function getPostList(keyword) {
    if (!keyword) return postList;

    const lowered = keyword.toLowerCase();
    const titleInclude = postList.filter(({ title }) =>
      title.toLowerCase().includes(lowered)
    );
    const contentInclude = postList.filter(({ content }) =>
      content.toLowerCase().includes(lowered)
    );

    const intersection = titleInclude.filter((x) => contentInclude.includes(x)); // 교집합

    const difference = titleInclude
      .filter((x) => !contentInclude.includes(x))
      .concat(contentInclude.filter((x) => !titleInclude.includes(x))); // 대칭차집합

    return [...intersection, ...difference];
  }
  return (
    <div className={pageStyle.pageBox}>
      <PostList posts={getPostList(keyword)} />
    </div>
  );
}

export default SearchResultPage;
