import styles from "./ReadingOptions.module.css";
import classNames from "classnames";

function ReadingOptions({ manageMode = false, tabs, activeTab, handleClick }) {
  return (
    <div className={styles.tabContainer}>
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={classNames(
            manageMode && styles.manageMode,
            styles.tabButton,
            styles[`${activeTab === tab.id ? "activeTab" : "inactiveTab"}`]
          )}
          onClick={() => handleClick(tab.id)}
        >
          <p>{tab.name}</p>
          <div
            className={classNames(
              styles.highlight,
              activeTab === tab.id ? styles.clicked : styles.hovered
            )}
          ></div>
        </button>
      ))}
    </div>
  );
}

export default ReadingOptions;
