import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReadingOptions from "../components/ReadingOptions";
import UserList from "../components/UserList";
import { ReactComponent as Search } from "../assets/search.svg";
import { ReactComponent as Back } from "../assets/icon--arrow-to-left.svg";
import styles from "./FollowListPage.module.css";
import pageStyle from "../components/ui/PageBox.module.css";
import classNames from "classnames";
import { useNestock } from "../contexts/NestockProvider";

function FollowListPage() {
  const [keyword, setKeyword] = useState("");
  const { userList } = useNestock();
  const location = useLocation();
  const navigate = useNavigate();
  const user_id = Number(location.pathname.split("/")[2]);
  const followList = userList.find((user) => user.id === user_id);
  const [filteredUsers, setFilteredUsers] = useState();
  const initial_tab = location.state;
  const tabs = [
    { id: "followers", name: "팔로워" },
    { id: "followings", name: "팔로잉" },
  ];
  const [activeTab, setActiveTab] = useState(
    initial_tab ? initial_tab : "follwers"
  );
  const handleKeywordChange = (e) => {
    const newKeyword = e.target.value;
    setKeyword(newKeyword);
    if (!newKeyword) setFilteredUsers(followList[activeTab]);
    const lowered = newKeyword.toLowerCase();
    setFilteredUsers(
      followList[activeTab].filter(({ userName }) =>
        userName.toLowerCase().includes(lowered)
      )
    );
  };
  useEffect(() => {
    setFilteredUsers(followList[activeTab]);
    setKeyword("");
  }, [activeTab]);
  return (
    <div className={classNames(styles.container, pageStyle.pageBox)}>
      <div className={styles.header}>
        <div className={styles.navigationHeader}>
          <button
            onClick={() => navigate(initial_tab ? -1 : `/user/${user_id}`)}
            className={styles.backButton}
          >
            <Back />
          </button>
          <h2>{userList.find((user) => user.id === user_id).username}</h2>
        </div>
        <ReadingOptions
          tabs={tabs}
          activeTab={activeTab}
          handleClick={setActiveTab}
        />
      </div>
      <div className={styles.searchBox}>
        <div className={styles.imgBox}>
          <Search />
        </div>
        <input
          id="searchInput"
          name="keyword"
          value={keyword}
          onChange={handleKeywordChange}
          autoComplete="off"
          className={styles.searchInput}
        />
      </div>
      <div className={styles.list}>
        <UserList users={filteredUsers} />
      </div>
    </div>
  );
}

export default FollowListPage;
