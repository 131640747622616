import styles from "../components/SearchBar.module.css";
import searchIcon from "../assets/search.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import AutoComplete from "./AutoComplete";

function SearchBar() {
  const [isOpen, setIsOpen] = useState(false);
  const autoComplete = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const className = [styles.blurredInput, styles.blurredButton];

  const [history, setHistory] = useState(() => {
    const historyData = localStorage.getItem("searchHistory");
    return historyData ? JSON.parse(historyData) : [];
  });

  function editSearchHistory(method, searchLog) {
    setHistory((prevHistory) => {
      const filtered = prevHistory.filter(({ type, content }) => {
        const { type: logType, content: logContent } = searchLog;
        if (type === logType) {
          if (logType === "community") {
            return content.id !== logContent.id;
          } else if (logType === "search") {
            return content.keyword !== logContent.keyword;
          } else {
            return true;
          }
        } else {
          return true;
        }
      });
      switch (method) {
        case "add":
          console.log("added");
          return [searchLog, ...filtered].splice(0, 20);
        case "delete":
          console.log("deleted");
          return filtered;
        default:
          console.log("edit method 입력 오류");
          return prevHistory;
      }
    });
  }

  const handleKeywordChange = (e) => setKeyword(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!keyword) {
      return;
    }
    document.getElementById("searchInput").blur();
    editSearchHistory("add", {
      type: "search",
      content: {
        keyword,
      },
    });
    navigate(`/search?keyword=${keyword}`);
  };

  useEffect(() => {
    setKeyword("");
  }, [location]);

  useEffect(() => {
    localStorage.setItem("searchHistory", JSON.stringify(history));
  }, [history]);

  return (
    <div className={styles.searchBar} onClick={() => setIsOpen(true)}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <input
          id="searchInput"
          name="keyword"
          value={keyword}
          onChange={handleKeywordChange}
          placeholder="검색"
          autoComplete="off"
          className={className[0]}
        ></input>
        <button type="submit" className={className[1]}>
          <img src={searchIcon} alt="검색" />
        </button>
      </form>
      {isOpen && (
        <div
          className={styles.autoCompleteContainer}
          ref={autoComplete}
          onClick={(e) => {
            e.stopPropagation();
            if (e.target === autoComplete.current) {
              setIsOpen(false);
            }
          }}
        >
          <AutoComplete
            keyword={keyword}
            aboutHistory={{ history, editSearchHistory }}
          />
        </div>
      )}
    </div>
  );
}

export default SearchBar;
