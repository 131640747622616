// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LeftSideBar_toggleContainer__HIopT {
  width: 40vw;
  min-width: 220px;
}

.LeftSideBar_notToggleContainer__rg3-L {
  width: 100%;
}

.LeftSideBar_edge__OXkfd {
  border-right: var(--border-line);
  background-color: rgb(247, 247, 247);
}

@media screen and (max-width: 1200px) {
  .LeftSideBar_notToggleContainer__rg3-L {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .LeftSideBar_notToggleContainer__rg3-L {
    display: initial;
  }
}

.LeftSideBar_sideBar__63tWM {
  width: 100%;
  height: calc(100dvh / 9 * 8);
  padding: 0 20px;
  position: sticky;
  top: calc(100dvh / 9);
  overflow-y: scroll;
}

.LeftSideBar_sideBar__63tWM::-webkit-scrollbar {
  width: 10px;
}

.LeftSideBar_sideBar__63tWM::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 12px 12px 12px 12px;
}

.LeftSideBar_sideBar__63tWM:hover::-webkit-scrollbar-thumb {
  background: rgb(220, 220, 220);
}

.LeftSideBar_sideBar__63tWM p {
  color: rgb(48, 48, 48);
}

.LeftSideBar_sideBar__63tWM > div:not(:last-of-type) {
  border-bottom: var(--border-line);
}

.LeftSideBar_content__4h04l {
  width: 100%;
  padding: 0 8px;
}

.LeftSideBar_title__ineWX {
  display: flex;
  flex-direction: row;
}

.LeftSideBar_title__ineWX > h3 {
  margin: 14px 0 14px 5px;
}

.LeftSideBar_list__OXNxp {
  padding: 0 2px;
}

.LeftSideBar_list__OXNxp > p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.LeftSideBar_list__OXNxp > p:first-child {
  margin-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/LeftSideBar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gCAAgC;EAChC,oCAAoC;AACtC;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,WAAW;EACX,4BAA4B;EAC5B,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,kCAAkC;AACpC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".toggleContainer {\n  width: 40vw;\n  min-width: 220px;\n}\n\n.notToggleContainer {\n  width: 100%;\n}\n\n.edge {\n  border-right: var(--border-line);\n  background-color: rgb(247, 247, 247);\n}\n\n@media screen and (max-width: 1200px) {\n  .notToggleContainer {\n    display: none;\n  }\n}\n\n@media screen and (min-width: 1200px) {\n  .notToggleContainer {\n    display: initial;\n  }\n}\n\n.sideBar {\n  width: 100%;\n  height: calc(100dvh / 9 * 8);\n  padding: 0 20px;\n  position: sticky;\n  top: calc(100dvh / 9);\n  overflow-y: scroll;\n}\n\n.sideBar::-webkit-scrollbar {\n  width: 10px;\n}\n\n.sideBar::-webkit-scrollbar-thumb {\n  background: transparent;\n  border-radius: 12px 12px 12px 12px;\n}\n\n.sideBar:hover::-webkit-scrollbar-thumb {\n  background: rgb(220, 220, 220);\n}\n\n.sideBar p {\n  color: rgb(48, 48, 48);\n}\n\n.sideBar > div:not(:last-of-type) {\n  border-bottom: var(--border-line);\n}\n\n.content {\n  width: 100%;\n  padding: 0 8px;\n}\n\n.title {\n  display: flex;\n  flex-direction: row;\n}\n\n.title > h3 {\n  margin: 14px 0 14px 5px;\n}\n\n.list {\n  padding: 0 2px;\n}\n\n.list > p {\n  width: 100%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.list > p:first-child {\n  margin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleContainer": `LeftSideBar_toggleContainer__HIopT`,
	"notToggleContainer": `LeftSideBar_notToggleContainer__rg3-L`,
	"edge": `LeftSideBar_edge__OXkfd`,
	"sideBar": `LeftSideBar_sideBar__63tWM`,
	"content": `LeftSideBar_content__4h04l`,
	"title": `LeftSideBar_title__ineWX`,
	"list": `LeftSideBar_list__OXNxp`
};
export default ___CSS_LOADER_EXPORT___;
