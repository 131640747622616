import { useAuth } from "../contexts/AuthProvider";
import { useState } from "react";
import styles from "./PostingPage.module.css";
import pageStyle from "../components/ui/PageBox.module.css";
import classNames from "classnames/bind";
import NotFoundPage from "./NotFoundPage";
import { approvePendingPost, createPost } from "../api/PostApi";
import { useLocation } from "react-router-dom";
import { useNestock } from "../contexts/NestockProvider";

function PostingPage() {
  const { user, token } = useAuth();
  const { setPostList } = useNestock();
  const location = useLocation();
  const community_id = Number(location.pathname.split("/")[2]);

  const INITIAL_VALUES = {
    title: "",
    content: "",
  };

  const [values, setValues] = useState(INITIAL_VALUES);

  if (user.id === 0) {
    return (
      <NotFoundPage
        title="게시물을 작성할 수 없습니다"
        description="로그인하시고 이용해주세요"
      />
    );
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // const handleSaveSubmit = async (e) => {
  //   e.preventDefault();
  //   const { code, msg, data } = await createPost({...values, community_id});
  //   if (code < 300) {
  //     setPostLIst((prevList) => [...prevList, data]);

  //   } else {
  //     console.log(code, msg);
  //   }
  // };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();
    console.log(values);
    console.log(e);
    const {
      code: saveCode,
      msg: saveMsg,
      data: saveData,
    } = await createPost({ ...values, community_id }, token.access_token);
    if (saveCode < 300) {
      const { code, msg, data } = await approvePendingPost({
        post_id: saveData.community_id,
      });
      if (code < 300) {
        setPostList((prevList) => [...prevList, data]);
      } else {
        console.log(code, msg);
      }
    } else {
      console.log(saveCode, saveMsg);
    }
  };

  return (
    <div className={classNames(styles.container, pageStyle.pageBox)}>
      <h1 className={styles.title}>포스팅</h1>
      <form className={styles.form} onSubmit={handleUploadSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="title" className={styles.label}>
            제목
          </label>
          <input
            type="text"
            name="title"
            className={styles.input}
            required
            placeholder="제목을 입력하세요"
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="content" className={styles.label}>
            내용
          </label>
          <textarea
            name="content"
            className={styles.textarea}
            required
            placeholder="내용을 입력하세요"
            rows="10"
            onChange={handleChange}
          ></textarea>
        </div>
        {/* <div className={styles.formGroup}>
          <label htmlFor="file-upload" className={styles.label}>
            이미지 첨부
          </label>
          <input
            type="file"
            id="file-upload"
            accept="image/*"
            className={styles.fileInput}
          />
          <button
            type="button"
            onClick={() => document.getElementById("file-upload").click()}
            className={styles.fileButton}
            disabled={true}
          >
            이미지 선택
          </button>
          <span className={styles.fileName}>선택된 파일 없음</span>
        </div> */}
        {/* <button type="submit" className={styles.button}>
          임시저장
        </button> */}
        <button type="submit" className={styles.button}>
          업로드
        </button>
      </form>
    </div>
  );
}

export default PostingPage;
