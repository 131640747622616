import { useNavigate } from "react-router-dom";
import ShowPostProfile from "./ShowPostProfile";
// import InteractionMenu from "./InteractionMenu";
import styles from "./ShowPost.module.css";

function ShowPost({ value, showCommunity = true }) {
  const {
    id,
    community_id,
    user_id,
    title,
    content,
    // status,
    created_at,
    // updated_at,
  } = value;
  const profile = { user_id, community_id };
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.stopPropagation();
    navigate(`/nest/${community_id}/post/${id}`);
  };

  return (
    <>
      <ShowPostProfile
        showCommunity={showCommunity}
        profile={profile}
        time={created_at}
      />
      <div className={styles.postContainer} onClick={handleClick}>
        <div className={styles.texts}>
          <h2>{title}</h2>
          <p className={styles.post}>{content}</p>
        </div>
        {/* <InteractionMenu
          recommendCount={recommended}
          commentCount={comment.length}
        /> */}
      </div>
    </>
  );
}

export default ShowPost;
