const base = `${process.env.REACT_APP_API_URL}api/user`;

function settingParams(base, params) {
  let url = `${base}?`;
  for (const dataKey in params) {
    url = url + `${dataKey}=${params[dataKey]}&`;
  }

  return url.slice(0, url.length - 1);
}

export async function getUser(userID, token) {
  const res = await fetch(settingParams(base, userID), {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function getListOfUser(token) {
  const res = await fetch(`${base}/list`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;
  console.log(code, msg, data);

  return { code, msg, data };
}
