import { useNavigate } from "react-router-dom";
import PostList from "../components/PostList";
// import badge1 from "../assets/badges/badge--short.jpg";
// import badge2 from "../assets/badges/badge--expert.jpg";
// import badge3 from "../assets/badges/badge--dividend.jpg";
// import badge4 from "../assets/badges/badge--trade-log.jpg";
// import badge5 from "../assets/badges/badge--only-car.jpg";
// import badge6 from "../assets/badges/badge--billionair.jpg";
// import badge7 from "../assets/badges/badge--gain500pct.jpg";
// import badge8 from "../assets/badges/badge--gain100pct.jpg";
// import badge9 from "../assets/badges/badge--top5pct.jpg";
// import badge10 from "../assets/badges/badge--only-medicine.jpg";
// import badge11 from "../assets/badges/badge--scalping.jpg";
import styles from "./TabContent.module.css";

function TabContent({
  target,
  posts,
  handleLoadMore,
  onUpdate,
  onDelete,
  activeTab,
  // badges
}) {
  const navigate = useNavigate();
  const UserStats = () => {
    const stats = [
      {
        label: "작성한 게시물",
        value: target.post_count,
      },
      {
        label: "작성한 댓글",
        value: target.comment_count,
      },
      {
        label: "받은 댓글",
        value: target.comment_on_post_count,
      },
      {
        label: "받은 좋아요",
        value:
          Number(target.post_likes_count) + Number(target.comment_likes_count),
      },
    ];

    const renderStatIcon = (label) => {
      switch (label) {
        case "작성한 게시물":
          return (
            <svg
              width="25"
              height="25"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#ef4444"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9.5a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
              />
            </svg>
          );
        case "작성한 댓글":
          return (
            <svg
              width="25"
              height="25"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#0ea5e9"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
              />
            </svg>
          );
        case "받은 댓글":
          return (
            <svg
              width="25"
              height="25"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#22c55e"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
              />
            </svg>
          );
        case "받은 좋아요":
          return (
            <svg
              width="25"
              height="25"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#f59e0b"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
          );
        default:
          return;
      }
    };

    const socialLinks = [
      { type: "instagram", label: "Instagram" },
      { type: "toss", label: "Toss" },
    ];

    const renderSocialIcon = (type) => {
      switch (type) {
        case "instagram":
          return (
            <svg className={styles.socialIcon} viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153.509.5.902 1.105 1.153 1.772.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 01-1.153 1.772c-.5.508-1.105.902-1.772 1.153-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 01-1.772-1.153 4.904 4.904 0 01-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 011.153-1.772A4.897 4.897 0 015.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 100 10 5 5 0 000-10zm6.5-.25a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0zM12 9a3 3 0 110 6 3 3 0 010-6z"
              />
            </svg>
          );
        case "toss":
          return (
            <svg className={styles.socialIcon} viewBox="0 0 24 24">
              <rect width="24" height="24" rx="12" fill="#0064FF" />
              <path fill="white" d="M7 7h10v10H7z" />
            </svg>
          );
        default:
          return null;
      }
    };

    return (
      <div className={styles.container}>
        <div className={styles.content}>
          {/* 통계 그리드 */}
          <div className={styles.statsGrid}>
            {stats.map((stat, index) => (
              <div key={index} className={styles.statCard}>
                <div className={styles.statIcon}>
                  {renderStatIcon(stat.label)}
                </div>
                <div className={styles.statLabel}>{stat.label}</div>
                <div className={styles.statValue}>
                  {stat.value.toLocaleString()}
                </div>
              </div>
            ))}
          </div>

          <div className={styles.divider} />

          {/* 소셜 링크 섹션 */}
          <div className={styles.socialSection}>
            <h3 className={styles.sectionTitle}>소셜 링크</h3>
            <div className={styles.socialLinks}>
              {socialLinks.map((link, index) => (
                <div key={index} className={styles.socialLink}>
                  {renderSocialIcon(link.type)}
                  <span className={styles.socialLabel}>{link.label}</span>
                </div>
              ))}
              <button className={styles.addButton}>
                <span className={styles.plusIcon}>+</span>
                소셜 링크 추가
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  switch (activeTab) {
    case "posts":
      return (
        <PostList
          posts={posts}
          showOnlyUser={false}
          handleLoadMore={handleLoadMore}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      );

    case "comments":
      return;

    case "about":
      return <UserStats />;

    case "adminPriv":
      return (
        <button onClick={() => navigate("/admin")}>관리자페이지로 이동</button>
      );
    default:
      return <></>;
  }

  // return <div className={styles.mainContent}>{content}</div>;
}

export default TabContent;
