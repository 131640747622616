import ShowPost from "./ShowPost";
import styles from "./PostList.module.css";

function PostList({ showCommunity = true, posts = [] }) {
  return (
    <>
      {posts.map((post) => {
        return (
          <div key={post.id} className={styles.innerBox}>
            <div className={styles.post}>
              <ShowPost value={post} showCommunity={showCommunity} />
            </div>
          </div>
        );
      })}
    </>
  );
}

export default PostList;
