// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_input__Hv8oo {
  border: 1px solid #d1d5db;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 1rem;
  line-height: 1.5;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.Input_input__Hv8oo:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/Input.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,8CAA8C;AAChD;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,6CAA6C;AAC/C","sourcesContent":[".input {\n  border: 1px solid #d1d5db;\n  border-radius: 4px;\n  padding: 8px 12px;\n  font-size: 1rem;\n  line-height: 1.5;\n  transition: border-color 0.2s, box-shadow 0.2s;\n}\n\n.input:focus {\n  outline: none;\n  border-color: #3b82f6;\n  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `Input_input__Hv8oo`
};
export default ___CSS_LOADER_EXPORT___;
