// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TradeForm_form__xPXLC {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.TradeForm_title__GubNt {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.TradeForm_formGroup__nf8J2 {
  margin-bottom: 16px;
}

.TradeForm_label__V4C8e {
  display: block;
  color: #4a5568;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

.TradeForm_input__tvaWA,
.TradeForm_select__sPINB,
.TradeForm_textarea__WJqTs {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 16px;
}

.TradeForm_input__tvaWA:focus,
.TradeForm_select__sPINB:focus,
.TradeForm_textarea__WJqTs:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.TradeForm_button__g4trk {
  background-color: #4299e1;
  color: white;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.TradeForm_button__g4trk:hover {
  background-color: #3182ce;
}
`, "",{"version":3,"sources":["webpack://./src/components/TradeForm.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;;;EAGE,WAAW;EACX,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;;;EAGE,aAAa;EACb,6CAA6C;AAC/C;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".form {\n  background-color: white;\n  padding: 24px;\n  border-radius: 8px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.title {\n  font-size: 24px;\n  font-weight: bold;\n  margin-bottom: 16px;\n}\n\n.formGroup {\n  margin-bottom: 16px;\n}\n\n.label {\n  display: block;\n  color: #4a5568;\n  font-size: 14px;\n  font-weight: bold;\n  margin-bottom: 8px;\n}\n\n.input,\n.select,\n.textarea {\n  width: 100%;\n  padding: 8px 12px;\n  border: 1px solid #e2e8f0;\n  border-radius: 4px;\n  font-size: 16px;\n}\n\n.input:focus,\n.select:focus,\n.textarea:focus {\n  outline: none;\n  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);\n}\n\n.button {\n  background-color: #4299e1;\n  color: white;\n  font-weight: bold;\n  padding: 8px 16px;\n  border-radius: 4px;\n  border: none;\n  cursor: pointer;\n}\n\n.button:hover {\n  background-color: #3182ce;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `TradeForm_form__xPXLC`,
	"title": `TradeForm_title__GubNt`,
	"formGroup": `TradeForm_formGroup__nf8J2`,
	"label": `TradeForm_label__V4C8e`,
	"input": `TradeForm_input__tvaWA`,
	"select": `TradeForm_select__sPINB`,
	"textarea": `TradeForm_textarea__WJqTs`,
	"button": `TradeForm_button__g4trk`
};
export default ___CSS_LOADER_EXPORT___;
