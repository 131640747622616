// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PostList_innerBox__QNQEG:not(:last-of-type) {
  border-bottom: var(--border-line);
}

.PostList_post__W8VoN {
  margin: 5px 0;
  border-radius: 15px;
  padding: 5px 10px;
  position: relative;
}

@media screen and (max-width: 960px) {
  .PostList_post__W8VoN:active {
    background-color: rgb(240, 240, 240);
  }
}

@media screen and (min-width: 960px) {
  .PostList_post__W8VoN:hover {
    background-color: rgb(240, 240, 240);
  }
}

.PostList_loadMore__HMWMm {
  width: 100%;
  height: 10px;
  background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/components/PostList.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;IACE,oCAAoC;EACtC;AACF;;AAEA;EACE;IACE,oCAAoC;EACtC;AACF;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,6BAA6B;AAC/B","sourcesContent":[".innerBox:not(:last-of-type) {\n  border-bottom: var(--border-line);\n}\n\n.post {\n  margin: 5px 0;\n  border-radius: 15px;\n  padding: 5px 10px;\n  position: relative;\n}\n\n@media screen and (max-width: 960px) {\n  .post:active {\n    background-color: rgb(240, 240, 240);\n  }\n}\n\n@media screen and (min-width: 960px) {\n  .post:hover {\n    background-color: rgb(240, 240, 240);\n  }\n}\n\n.loadMore {\n  width: 100%;\n  height: 10px;\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerBox": `PostList_innerBox__QNQEG`,
	"post": `PostList_post__W8VoN`,
	"loadMore": `PostList_loadMore__HMWMm`
};
export default ___CSS_LOADER_EXPORT___;
