import { useNavigate } from "react-router-dom";
// import { getCoumminityNames } from "../api";
import styles from "./AutoComplete.module.css";
import { useNestock } from "../contexts/NestockProvider";

function AutoComplete({ keyword, aboutHistory }) {
  const { communityList } = useNestock();
  console.log(communityList);
  console.log(typeof keyword);
  const { history, editSearchHistory } = aboutHistory;
  function getCoumminityNames(keyword) {
    if (!keyword) return;
    console.log(typeof keyword);

    const lowered = keyword.toLowerCase();

    const allItems = communityList.filter(({ name }) =>
      name.toLowerCase().includes(lowered)
    );

    console.log(allItems);

    return allItems.slice(0, 6);
  }
  // const communities = communityList
  //   .filter(({ name }) => name.toLowerCase().includes(keyword.toLowerCase()))
  //   .slice(0, 6);
  // console.log(communities);
  const communities = getCoumminityNames(keyword);
  console.log(communities);
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    const { type, content } = JSON.parse(
      e.target.closest("[data-search]").dataset.search
    );
    document.getElementById("searchInput").blur();
    editSearchHistory("add", { type, content });
    if (type === "community") {
      navigate(`/nest/${content.id}`);
    } else if (type === "search") {
      navigate(`/search/${content.keyword}`);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    editSearchHistory("delete", e.target.name);
  };
  return (
    <div className={styles.list}>
      {keyword ? (
        <>
          {communities.map(({ id, name }) => (
            <div
              key={id}
              data-search={JSON.stringify({
                type: "community",
                content: { id, name },
              })}
              onClick={handleClick}
            >
              <h4 className={styles.element}>{name}</h4>
            </div>
          ))}
        </>
      ) : (
        <>
          {history.slice(0, 5).map(({ type, content }, i) => (
            <div
              key={i}
              data-search={JSON.stringify({ type, content })}
              onClick={handleClick}
            >
              <h4 className={styles.element}>
                {type === "community"
                  ? `${content.name} 커뮤니티`
                  : content.keyword}
              </h4>
              <button onClick={handleDelete} type="button">
                x
              </button>
            </div>
          ))}
        </>
      )}
      <div style={{ paddingLeft: "15px" }}>
        <p>인기 급상승 커뮤니티</p>
      </div>
    </div>
  );
}

export default AutoComplete;
