import { useAuth } from "../contexts/AuthProvider";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import LeftSideBar from "./LeftSideBar";
import SearchBar from "./SearchBar";
import { ReactComponent as Person } from "../assets/person.svg";
import Alarm from "./Alarm";
import Login from "./Login";
import { ReactComponent as MobileLogo } from "../assets/logo.svg";
import { ReactComponent as NotMobileLogo } from "../assets/logo-name.svg";
import styles from "./Nav.module.css";
import { ReactComponent as User } from "../assets/icon--user.svg";
import { ReactComponent as Calendar } from "../assets/icon--calendar.svg";
import { ReactComponent as LogOut } from "../assets/icon--logout.svg";
import dropdownStyles from "./ProfileDropdown.module.css";
import classNames from "classnames";

function Nav() {
  const {
    user,
    token: { access_token },
    isOnline,
    setLogOut,
  } = useAuth();
  const [leftOpen, setLeftOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const leftBar = useRef();
  const userMenu = useRef();
  const navigate = useNavigate();
  return (
    <div className={styles.nav}>
      <div className={styles.navContainer}>
        <div className={styles.navBody}>
          {leftOpen && (
            <div
              className={styles.leftContainer}
              ref={leftBar}
              onClick={(e) => {
                e.stopPropagation();
                if (e.target === leftBar.current) {
                  setLeftOpen(false);
                }
              }}
            >
              <LeftSideBar isOpen={leftOpen} />
            </div>
          )}
          <div
            className={styles.leftBarIcon}
            onClick={() => {
              setUserOpen(false);
              setLeftOpen(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="30"
              height="30"
            >
              <path
                d="M3 6h18v2H3V6zm0 5h18v2H3v-2zm0 5h18v2H3v-2z"
                fill="#000000"
              />
            </svg>
          </div>
          <div onClick={() => navigate("/")}>
            <MobileLogo className={styles.mobileLogo} width="131" height="28" />
            <NotMobileLogo
              className={styles.notMobileLogo}
              width="180"
              height="45"
            />
          </div>
        </div>
        <div className={styles.navRightSide}>
          <SearchBar className={styles.searchBar} />
          <div
            className={classNames(styles.menuBox, isOnline && styles.isOnline)}
          >
            {isOnline ? (
              <>
                <div
                  className={styles.iconButton}
                  onClick={() => {
                    setLeftOpen(false);
                    setUserOpen(!userOpen);
                  }}
                >
                  <img
                    className={styles.profileIcon}
                    src={user.profile_image}
                    alt="프로필 이미지"
                  />
                  {userOpen && (
                    <div
                      className={styles.userContainer}
                      ref={userMenu}
                      onClick={(e) => {
                        if (e.target == userMenu.current) {
                          setUserOpen(false);
                        }
                      }}
                    >
                      <div className={dropdownStyles.dropdownMenu}>
                        <div className={dropdownStyles.dropdownContent}>
                          <div className={dropdownStyles.userInfo}>
                            <p className={dropdownStyles.userName}>
                              {user.userName}
                            </p>
                            <p className={dropdownStyles.userEmail}>
                              {user.email}
                            </p>
                          </div>
                          <div
                            onClick={() => {
                              setUserOpen(false);
                              navigate(`/user/${user.id}`);
                            }}
                            className={dropdownStyles.menuItem}
                          >
                            <User
                              className={dropdownStyles.smallIcon}
                              style={{ color: "#f97316" }}
                            />
                            프로필
                          </div>
                          <div
                            className={dropdownStyles.menuItem}
                            onClick={() => {
                              setUserOpen(false);
                              navigate("/calendar");
                            }}
                          >
                            <Calendar
                              className={dropdownStyles.smallIcon}
                              style={{ color: "#f97316" }}
                            />
                            주식 캘린더
                          </div>
                          <div className={dropdownStyles.menuDivider}>
                            <button
                              onClick={() => {
                                setUserOpen(false);
                                setLogOut(access_token);
                              }}
                              className={dropdownStyles.logoutButton}
                            >
                              <LogOut className={dropdownStyles.smallIcon} />
                              로그아웃
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* <Alarm /> */}
              </>
            ) : (
              <Login />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nav;
