import { Link, useNavigate } from "react-router-dom";
import personIcon from "../assets/person.png";
import SearchBar from "./SearchBar";
import Alarm from "./Alarm";
import Login from "./Login";
import logo from "../assets/logo.png";
import logoName from "../assets/logo-name.png";
import styles from "./Nav.module.css";
import classNames from "classnames";
import LeftSideBar from "./LeftSideBar";
import { useState, useRef } from "react";
import { useAuth } from "../contexts/AuthProvider";

function Nav() {
  const { setLogOut } = useAuth();
  const [leftOpen, setLeftOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const leftBar = useRef();
  const userMenu = useRef();
  const { isOnline } = useAuth();
  const navigate = useNavigate();
  return (
    <div className={styles.nav}>
      <div className={styles.navContainer}>
        <div className={styles.navBody}>
          {leftOpen && (
            <div
              className={styles.leftContainer}
              ref={leftBar}
              onClick={(e) => {
                e.stopPropagation();
                if (e.target === leftBar.current) {
                  setLeftOpen(false);
                }
              }}
            >
              <LeftSideBar isOpen={leftOpen} />
            </div>
          )}
          <div
            className={styles.leftBarIcon}
            onClick={() => {
              setUserOpen(false);
              setLeftOpen(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="30"
              height="30"
            >
              <path
                d="M3 6h18v2H3V6zm0 5h18v2H3v-2zm0 5h18v2H3v-2z"
                fill="#000000"
              />
            </svg>
          </div>
          <Link to="/">
            <img
              className={classNames(styles.image, styles.mobileLogo)}
              src={logo}
              alt="Nestock Logo"
            />
            <img
              className={classNames(styles.image, styles.notMobileLogo)}
              src={logoName}
              alt="Nestock Logo"
            />
          </Link>
        </div>
        <SearchBar className={styles.searchBar} />
        <div className={styles.menuBox}>
          {isOnline ? (
            <>
              <Alarm />
              <div
                className={styles.iconButton}
                onClick={() => {
                  setLeftOpen(false);
                  setUserOpen(true);
                }}
              >
                <img src={personIcon} alt="유저 메뉴" />
                {userOpen && (
                  <div
                    className={styles.userContainer}
                    ref={userMenu}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (e.target === userMenu.current) {
                        setUserOpen(false);
                      }
                    }}
                  >
                    <div className={styles.userMenu}>
                      <div
                        className={styles.userProfile}
                        onClick={() => {
                          setUserOpen(false);
                          navigate("/user/me");
                        }}
                      >
                        <h2>프로필</h2>
                      </div>
                      <div
                        className={styles.stockCalendar}
                        onClick={() => {
                          setUserOpen(false);
                          navigate("/calendar");
                        }}
                      >
                        <h2>주식 캘린더</h2>
                      </div>
                      <div
                        className={styles.logOut}
                        onClick={() => {
                          setUserOpen(false);
                          setLogOut();
                        }}
                      >
                        <h2>로그아웃</h2>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <Login />
          )}
        </div>
      </div>
    </div>
  );
}

export default Nav;
