import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNestock } from "../contexts/NestockProvider";
import { getListOfPost } from "../api/PostApi";
import useDidMountEffect from "../hooks/useDidMountEffect";
import PostList from "../components/PostList";
import pageStyle from "../components/ui/PageBox.module.css";

function SearchResultPage() {
  const { userList, refreshUserList } = useNestock();
  const location = useLocation();
  const [keyword, setKeyword] = useState(
    decodeURI(location.search.split("=")[1])
  );
  const [page, setPage] = useState(1);
  const [resultList, setResultList] = useState([]);

  const handleLoadMoreResult = async () => {
    if (page === 0) {
      return;
    }

    try {
      const { data } = await getListOfPost({
        keyword,
        page,
        page_size: 20,
      });

      if (!data || data.length === 0) {
        setPage(0);
        return;
      }

      const prevUserIDs = userList.map((user) => user.id);
      const prevPostIDs = resultList.map((post) => post.id);

      const newPosts = data.filter((post) => !prevPostIDs.includes(post.id));

      // Check for new users and refresh user list if needed
      const hasNewUsers = data.some(
        (post) => !prevUserIDs.includes(post.User.id)
      );
      if (hasNewUsers) {
        refreshUserList();
      }

      if (newPosts.length === 0) {
        setPage(0);
        return;
      }

      setResultList((prev) => [...prev, ...newPosts]);

      if (newPosts.length < 20) {
        setPage(0);
      } else {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      setPage(0);
    }
  };

  const resetSearch = async (newKeyword) => {
    setResultList([]);
    setPage(1);

    const { data } = await getListOfPost({
      keyword: newKeyword,
      page: 1,
      page_size: 20,
    });

    if (data && data.length > 0) {
      setResultList(() => data);
      if (data.length < 20) {
        setPage(0);
      } else {
        setPage(2);
      }
    } else {
      setPage(0);
    }
  };

  useDidMountEffect(() => {
    const newKeyword = decodeURI(location.search.split("=")[1]);
    setKeyword(newKeyword);
    resetSearch(newKeyword);
  }, [location]);

  return (
    <div className={pageStyle.pageBox}>
      <PostList
        showOnlyUser={false}
        posts={resultList}
        handleLoadMore={handleLoadMoreResult}
      />
    </div>
  );
}

export default SearchResultPage;
