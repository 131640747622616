import { useNestock } from "../contexts/NestockProvider";
import { useAuth } from "../contexts/AuthProvider";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deletePost, getMajorListOfPost } from "../api/PostApi";
import PostList from "../components/PostList";
import { ReactComponent as Plus } from "../assets/icon--plus.svg";
import pageStyles from "../components/ui/PageBox.module.css";
import styles from "./MainPage.module.css";
import classNames from "classnames";

const category = "recent";

function RecentMainPage() {
  const { userList, refreshUserList, updatePostList } = useNestock();
  const { checkingToken } = useAuth();
  const [page, setPage] = useState(1);
  const [postList, setPostList] = useState([]);
  const navigate = useNavigate();
  const handleUpdate = (post) =>
    navigate(`/posting/${post.id}`, {
      state: {
        user_id: post.User.id,
        title: post.title,
        content: post.content,
      },
    });

  const handleDelete = async (post_id) => {
    const confirmed = window.confirm("해당 게시물을 지우시겠습니까?");
    if (!confirmed) return;
    const { code } = await checkingToken(
      async (token) => await deletePost({ post_id }, token)
    );
    if (code < 300) {
      updatePostList(post_id);
      setPostList((prevList) => prevList.filter((post) => post.id !== post_id));
    }
  };
  const handleLoadMore = async () => {
    if (page === 0) {
      return;
    }

    try {
      const { data } = await getMajorListOfPost({
        category,
        page,
        page_size: 20,
      });

      if (!data || data.length === 0) {
        setPage(0);
        return;
      }

      const prevUserIDs = userList.map((user) => user.id);
      const prevPostIDs = postList.map((post) => post.id);

      const newPosts = data.filter((post) => !prevPostIDs.includes(post.id));

      // Check for new users and refresh user list if needed
      const hasNewUsers = data.some(
        (post) => !prevUserIDs.includes(post.User.id)
      );
      if (hasNewUsers) {
        refreshUserList();
      }

      if (newPosts.length === 0) {
        setPage(0);
        return;
      }

      setPostList((prev) => [...prev, ...newPosts]);

      if (newPosts.length < 20) {
        setPage(0);
      } else {
        setPage((prev) => prev + 1);
        return 1;
      }
    } catch (error) {
      console.error("Failed to load posts:", error);
      setPage(0);
    }
  };
  return (
    <div className={pageStyles.pageBox}>
      <div className={styles.buttonBox}>
        <div className={styles.listButtonBox}>
          <button
            className={styles.sortButton}
            onClick={() => navigate("/popular")}
          >
            <h4>인기순</h4>
          </button>
          <button className={classNames(styles.sortButton, styles.selected)}>
            <h4>최신순</h4>
          </button>
          <button
            className={styles.sortButton}
            onClick={() => navigate("/", { state: "communityList" })}
          >
            <h4>커뮤니티 목록</h4>
          </button>
        </div>
        <button
          className={styles.createButton}
          onClick={() => navigate("/posting")}
        >
          <Plus width="18" height="18" color="#000000" />
          <h4 className={styles.notMobile}>포스팅</h4>
        </button>
      </div>
      <PostList
        showOnlyUser={false}
        posts={postList}
        handleLoadMore={handleLoadMore}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
      />
    </div>
  );
}

export default RecentMainPage;
