// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteAccountPage_title__OOxfa {
  margin: 20px 0;
  font-size: 24px;
  font-weight: bold;
}

.DeleteAccountPage_container__v1GD8 {
  width: 100%;
  margin-top: 50px;
}

.DeleteAccountPage_text__NtqwH {
  width: fit-content;
  margin: 0 auto;
  font-size: 15px;
  line-height: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/DeleteAccountPage.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".title {\n  margin: 20px 0;\n  font-size: 24px;\n  font-weight: bold;\n}\n\n.container {\n  width: 100%;\n  margin-top: 50px;\n}\n\n.text {\n  width: fit-content;\n  margin: 0 auto;\n  font-size: 15px;\n  line-height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `DeleteAccountPage_title__OOxfa`,
	"container": `DeleteAccountPage_container__v1GD8`,
	"text": `DeleteAccountPage_text__NtqwH`
};
export default ___CSS_LOADER_EXPORT___;
