import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./App";
import MainPage from "./pages/MainPage";
import PopularMainPage from "./pages/PopularMainPage";
import RecentMainPage from "./pages/RecentMainPage";
import SearchResultPage from "./pages/SearchResultPage";
import CreatingCommunityPage from "./pages/CreatingCommunityPage";
import CommunityPage from "./pages/CommunityPage";
import PostPage from "./pages/PostPage";
import PostingPage from "./pages/PostingPage";
import RevisingPostPage from "./pages/RevisingPostPage";
import CalendarPage from "./pages/CalendarPage";
import TradeMemoPage from "./pages/TradeMemoPage";
import CouncelPage from "./pages/CouncelPage";
import PolicyPage from "./pages/PolicyPage";
import InquiryPage from "./pages/InquiryPage";
import UserPage from "./pages/UserPage";
import ProfileEditPage from "./pages/ProfileEditPage";
import FollowListPage from "./pages/FollowListPage";
import DeleteAccountPage from "./pages/DeleteAccountPage";
import NotFoundPage from "./pages/NotFoundPage";
import AdminPage from "./pages/AdminPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <MainPage />,
      },
      {
        path: "popular",
        element: <PopularMainPage />,
      },
      {
        path: "recent",
        element: <RecentMainPage />,
      },
      {
        path: "search",
        element: <SearchResultPage />,
      },
      {
        path: "posting",
        children: [
          {
            index: true,
            element: <PostingPage />,
          },
          {
            path: ":postId",
            element: <RevisingPostPage />,
          },
        ],
      },
      {
        path: "nest",
        children: [
          {
            index: true,
            element: <NotFoundPage />,
          },
          {
            path: "create",
            element: <CreatingCommunityPage />,
          },
          {
            path: ":communitySlug",
            children: [
              {
                index: true,
                element: <CommunityPage />,
              },
              {
                path: "edit",
                element: <ProfileEditPage />,
              },
              {
                path: "post",
                children: [
                  {
                    index: true,
                    element: <NotFoundPage />,
                  },
                  {
                    path: ":postId",
                    element: <PostPage />,
                  },
                ],
              },
            ],
          },
          {
            path: "*",
            element: <NotFoundPage />,
          },
        ],
      },
      {
        path: "calendar",
        element: <CalendarPage />,
      },
      {
        path: "tradememo",
        element: <TradeMemoPage />,
      },
      {
        path: "councel",
        element: <CouncelPage />,
      },
      {
        path: "info",
        children: [
          {
            path: "policy",
            element: <PolicyPage />,
          },
          {
            path: "inquiry",
            element: <InquiryPage />,
          },
        ],
      },
      {
        path: "user",
        children: [
          {
            index: true,
            element: <NotFoundPage />,
          },
          {
            path: ":userID",
            children: [
              {
                index: true,
                element: <UserPage />,
              },
              {
                path: "edit",
                element: <ProfileEditPage />,
              },
              {
                path: "follow-list",
                element: <FollowListPage />,
              },
            ],
          },
          {
            path: "delete-account",
            element: <DeleteAccountPage />,
          },
        ],
      },
      {
        path: "admin",
        element: <AdminPage />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]);

function Main() {
  return <RouterProvider router={router} />;
}

export default Main;
