import { useNestock } from "../contexts/NestockProvider";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import PostList from "../components/PostList";
import NotFoundPage from "./NotFoundPage";
import ShowPageProfile from "../components/ShowPageProfile";
import pageStyle from "../components/ui/PageBox.module.css";

function CommunityPage() {
  const { communityList, postList } = useNestock();
  const location = useLocation();
  const communityID = Number(location.pathname.split("/")[2]);
  const data = communityList.find(({ id }) => id === communityID);
  const posts = postList.filter(
    ({ community_id }) => community_id === communityID
  );

  function addVisitHistory(log) {
    if (!log) return;
    const historyData = localStorage.getItem("visitHistory");
    const prevHistory = historyData ? JSON.parse(historyData) : [];
    const filtered = prevHistory.filter(({ id }) => id !== log.id);
    const addedHistory = [log, ...filtered].splice(0, 20);
    localStorage.setItem("visitHistory", JSON.stringify(addedHistory));
  }

  useEffect(() => {
    addVisitHistory({ id: data.id, name: data.name });
  }, [location]);

  if (!data) {
    return (
      <NotFoundPage
        variant="big"
        title="존재하지 않는 커뮤니티입니다"
        description="올바른 주소가 맞는지 다시 한 번 확인해 주세요"
      />
    );
  }

  return (
    <div className={pageStyle.pageBox}>
      <ShowPageProfile option={"community"} data={data} />
      <PostList showCommunity={false} posts={posts} />
    </div>
  );
}

export default CommunityPage;
