import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../components/ui/Dialog";
import { Button } from "../components/ui/Button";
import { Input } from "../components/ui/Input";
import arrowTL from "../assets/icon--arrow-to-left.svg";
import arrowTR from "../assets/icon--arrow-to-right.svg";
import styles from "./CalendarPage.module.css";
import pageStyle from "../components/ui/PageBox.module.css";
import classNames from "classnames";

function CalendarPage() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [calendarDays, setCalendarDays] = useState([]);
  const [events, setEvents] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({
    title: "",
    type: "economic",
    date: "",
  });
  const inlineStyles = {
    bgWhite: {
      backgroundColor: "#ffffff",
    },
    bgGray: {
      backgroundColor: "#f3f4f6",
    },
    fontSize: {
      fontSize: "700",
    },
  };
  useEffect(() => {
    generateCalendarDays();
  }, [currentDate]);
  console.log(events);

  const generateCalendarDays = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(Date.UTC(year, month, 1)).getUTCDay();
    const lastDate = new Date(Date.UTC(year, month + 1, 0)).getUTCDate();

    let days = [];
    for (let i = 0; i < firstDay; i++) {
      const prevMonthLastDate = new Date(Date.UTC(year, month, 0)).getUTCDate();
      days.push({
        day: prevMonthLastDate - firstDay + i + 1,
        date: new Date(
          Date.UTC(year, month - 1, prevMonthLastDate - firstDay + i + 1)
        ),
        isCurrentMonth: false,
      });
    }
    for (let i = 1; i <= lastDate; i++) {
      days.push({
        day: i,
        date: new Date(Date.UTC(year, month, i)),
        isCurrentMonth: true,
      });
    }

    const remainingDays = 35 - days.length;
    for (let i = 1; i <= remainingDays; i++) {
      days.push({
        day: i,
        date: new Date(Date.UTC(year, month + 1, i)),
        isCurrentMonth: false,
      });
    }

    setCalendarDays(days);
  };

  const prevMonth = () => {
    setCurrentDate(
      new Date(
        Date.UTC(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
      )
    );
  };

  const nextMonth = () => {
    setCurrentDate(
      new Date(
        Date.UTC(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
      )
    );
  };

  const handleYearChange = (e) => {
    const newYear = parseInt(e.target.value);
    setCurrentDate(new Date(Date.UTC(newYear, currentDate.getMonth(), 1)));
  };

  const handleMonthChange = (e) => {
    const newMonth = parseInt(e.target.value);
    setCurrentDate(new Date(Date.UTC(currentDate.getFullYear(), newMonth, 1)));
  };

  const days = ["일", "월", "화", "수", "목", "금", "토"];
  const months = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ];

  const eventTypeColors = {
    holiday: "event_holiday",
    economic: "event_economic",
    company: "event_company",
    market: "event_market",
  };

  const eventTypeLabels = {
    holiday: "휴장일",
    economic: "경제 지표",
    company: "기업 이벤트",
    market: "시장 이벤트",
  };

  const getEventsForDate = (date) => {
    if (!date) return [];
    console.log(date.toISOString());
    const dateString = date.toISOString().split("T")[0];
    return events[dateString] || [];
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setIsEventModalOpen(true);
  };

  const handleAddEvent = () => {
    const dateString = newEvent.date;
    const updatedEvents = { ...events };
    if (!updatedEvents[dateString]) {
      updatedEvents[dateString] = [];
    }
    delete newEvent.date;
    updatedEvents[dateString].push(newEvent);
    setEvents(updatedEvents);
    setIsAddEventModalOpen(false);
    setNewEvent({ title: "", type: "economic", date: "" });
  };

  const handleDeleteEvent = () => {
    const dateString = selectedEvent.date;
    const updatedEvents = { ...events };
    updatedEvents[dateString] = updatedEvents[dateString].filter(
      (e) => e !== selectedEvent
    );
    setEvents(updatedEvents);
    setIsEventModalOpen(false);
  };

  return (
    <div className={classNames(styles.calendar_container, pageStyle.pageBox)}>
      <div className={styles.calendar_wrapper}>
        <div className={styles.calendar_header}>
          <div className={styles.month_nav_container}>
            <button onClick={prevMonth} className={styles.month_nav_button}>
              <img src={arrowTL} alt="이전달로"></img>
            </button>
            <h2 className={styles.month_nav_text}>
              {currentDate.getFullYear()}년 {months[currentDate.getMonth()]}
            </h2>
            <button onClick={nextMonth} className={styles.month_nav_button}>
              <img src={arrowTR} alt="다음달로"></img>
            </button>
          </div>
          <div className={styles.month_nav_container}>
            <select
              value={currentDate.getFullYear()}
              onChange={handleYearChange}
              className={styles.year_month_select}
            >
              {Array.from(
                { length: 10 },
                (_, i) => currentDate.getFullYear() - 5 + i
              ).map((year) => (
                <option key={year} value={year}>
                  {year}년
                </option>
              ))}
            </select>
            <select
              value={currentDate.getMonth()}
              onChange={handleMonthChange}
              className={styles.year_month_select}
            >
              {months.map((month, index) => (
                <option key={index} value={index}>
                  {month}
                </option>
              ))}
            </select>
            <Button
              onClick={() => setIsAddEventModalOpen(true)}
              className={styles.add_event_button}
            >
              이벤트 추가
            </Button>
          </div>
        </div>

        {/* 색상 범례 추가 */}
        <div className={styles.legend}>
          {Object.entries(eventTypeColors).map(([type, colorClass]) => (
            <div key={type} className={styles.legend_item}>
              <div
                className={classNames(
                  styles.legend_color,
                  styles[`${colorClass + "_bg"}`]
                )}
              ></div>
              <span className={styles.legend_type}>
                {eventTypeLabels[type]}
              </span>
            </div>
          ))}
        </div>

        <div className={styles.calendar_grid}>
          {days.map((day) => (
            <div key={day} className={styles.day_of_the_week}>
              {day}
            </div>
          ))}
          {calendarDays.map((item, index) => (
            <div
              key={index}
              style={
                inlineStyles[`${item.isCurrentMonth ? "bgWhite" : "bgGray"}`]
              }
              className={styles.calendar_day}
            >
              <div style={inlineStyles.fontSize}>{item.day}</div>
              <div className={styles.event_day}>
                {getEventsForDate(item.date).map((event, eventIndex) => (
                  <div
                    key={eventIndex}
                    className={classNames(
                      styles.event,
                      styles[`${eventTypeColors[event.type]}`]
                    )}
                    onClick={() => handleEventClick(event)}
                  >
                    {event.title.length > 10
                      ? `${event.title.substring(0, 10)}...`
                      : event.title}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <Dialog open={isEventModalOpen} onOpenChange={setIsEventModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{selectedEvent?.title}</DialogTitle>
          </DialogHeader>
          <div className={styles.marginTop}>
            <p>&#128197; {selectedEvent?.date}</p>
            <p>&#128337; 이벤트 유형: {eventTypeLabels[selectedEvent?.type]}</p>
          </div>
          <DialogFooter>
            <Button variant="destructive" onClick={handleDeleteEvent}>
              삭제
            </Button>
            <Button
              variant="outline"
              onClick={() => setIsEventModalOpen(false)}
            >
              닫기
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={isAddEventModalOpen} onOpenChange={setIsAddEventModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>새 이벤트 추가</DialogTitle>
          </DialogHeader>
          <div className={styles.marginTop}>
            <Input
              placeholder="이벤트 제목"
              value={newEvent.title}
              onChange={(e) =>
                setNewEvent({ ...newEvent, title: e.target.value })
              }
            />
            <select
              value={newEvent.type}
              onChange={(e) =>
                setNewEvent({ ...newEvent, type: e.target.value })
              }
              className={styles.event_select}
            >
              <option value="economic">경제 지표</option>
              <option value="company">기업 이벤트</option>
              <option value="market">시장 이벤트</option>
              <option value="holiday">휴장일</option>
            </select>
            <Input
              type="date"
              value={newEvent.date}
              onChange={(e) =>
                setNewEvent({ ...newEvent, date: e.target.value })
              }
            />
          </div>
          <DialogFooter>
            <Button onClick={handleAddEvent}>추가</Button>
            <Button
              variant="outline"
              onClick={() => setIsAddEventModalOpen(false)}
            >
              취소
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CalendarPage;
