// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_button__dXAUs {
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  padding: 8px;
}

.Button_default__\\+E8pc {
  background-color: rgb(240, 240, 240);
}

.Button_default__\\+E8pc:hover {
  background-color: rgb(220, 220, 220);
}

.Button_outline__\\+A-LR {
  background-color: white;
  color: #374151;
  border: 1px solid #d1d5db;
}

.Button_outline__\\+A-LR:hover {
  background-color: #f3f4f6;
}

.Button_destructive__H9NVe {
  background-color: #dc2626;
  color: white;
}

.Button_destructive__H9NVe:hover {
  background-color: #b91c1c;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/Button.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,6CAA6C;EAC7C,YAAY;AACd;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,uBAAuB;EACvB,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".button {\n  font-weight: 500;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.2s, color 0.2s;\n  padding: 8px;\n}\n\n.default {\n  background-color: rgb(240, 240, 240);\n}\n\n.default:hover {\n  background-color: rgb(220, 220, 220);\n}\n\n.outline {\n  background-color: white;\n  color: #374151;\n  border: 1px solid #d1d5db;\n}\n\n.outline:hover {\n  background-color: #f3f4f6;\n}\n\n.destructive {\n  background-color: #dc2626;\n  color: white;\n}\n\n.destructive:hover {\n  background-color: #b91c1c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button_button__dXAUs`,
	"default": `Button_default__+E8pc`,
	"outline": `Button_outline__+A-LR`,
	"destructive": `Button_destructive__H9NVe`
};
export default ___CSS_LOADER_EXPORT___;
