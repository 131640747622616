// import { Link } from "react-router-dom";
import styles from "./RightSideBar.module.css";
// import calender from "../assets/icon--calender.svg";
// import memo from "../assets/icon--memo.svg";
// import quiz from "../assets/icon--quiz.svg";
// import councel from "../assets/icon--councel.svg";

function RightSideBar() {
  return (
    <div className={styles.edge}>
      <div className={styles.sideBar}>
        {/* <Link to={"/calender"}>
          <div className={styles.link}>
            <img src={calender} alt="캘린더"></img>
            <h1>주식 캘린더</h1>
          </div>
        </Link>
        <Link to={"/tradememo"}>
          <div className={styles.link}>
            <img src={memo} alt="일지"></img>
            <h1>매매 일지</h1>
          </div>
        </Link>
        <Link to={"/calender"}>
          <div className={styles.link}>
            <img src={quiz} alt="퀴즈"></img>
            <h1>주식 퀴즈</h1>
          </div>
        </Link>
        <Link to={"/councel"}>
          <div className={styles.link}>
            <img src={councel} alt="상담"></img>
            <h1>챗봇 상담</h1>
          </div>
        </Link>
        <Link to={"/membership"}>
          <div className={styles.link}>
            <img src={councel} alt="멤버쉽"></img>
            <h1>멤버쉽</h1>
          </div>
        </Link>
        <Link to={"/mentor"}>
          <div className={styles.link}>
            <img src={councel} alt="전문가"></img>
            <h1>전문가 커뮤니티</h1>
          </div>
        </Link> */}
      </div>
    </div>
  );
}

export default RightSideBar;
