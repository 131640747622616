import { createContext, useContext, useState } from "react";

const SearchContext = createContext();

function SearchProvider({ children }) {
  const [searchParams, setSearchParams] = useState();
  return (
    <SearchContext.Provider value={{ searchParams, setSearchParams }}>
      {children}
    </SearchContext.Provider>
  );
}

function useSearchParams() {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error("Provider 안에서 사용하시오");
  }

  return context.searchParams;
}

function useSetSearchParams() {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error("Provider 안에서 사용하시오");
  }

  return context.setSearchParams;
}

export { SearchProvider, useSearchParams, useSetSearchParams };
