import { useNavigate } from "react-router-dom";
import { updateUserRole, toggleFollow } from "../api/UserApi";
import listStyles from "./ListStyles.module.css";
import classNames from "classnames";
import { useAuth } from "../contexts/AuthProvider";
import { useNestock } from "../contexts/NestockProvider";

function UserList({ users, manageMode = false }) {
  const { refreshUserList } = useNestock();
  const { isOnline, refreshUser, setFollowList, token } = useAuth();
  const navigate = useNavigate();
  const handleFollow = async (user) => {
    if (!isOnline) alert("로그인하시고 이용해주세요");
    const { code, msg } = await toggleFollow(
      { target_user_id: user.id },
      token.access_token
    );
    if (code < 300) {
      if (msg) {
        setFollowList((prevList) => ({
          ...prevList,
          followings: [
            ...prevList.followings,
            {
              id: user.id,
              userName: user.userName,
              profile_image: user.profile_image,
            },
          ],
        }));
        refreshUserList();
      }
    } else if (code === 401) {
      const token = await refreshUser();
      const { code, msg } = await toggleFollow(
        { target_user_id: user.id },
        token
      );
      if (code < 300) {
        if (msg) {
          setFollowList((prevList) => ({
            ...prevList,
            followings: [
              ...prevList.followings,
              {
                id: user.id,
                userName: user.userName,
                profile_image: user.profile_image,
              },
            ],
          }));
          refreshUserList();
        }
      }
    }
  };
  const handleUnfollow = async (user) => {
    if (!isOnline) alert("로그인하시고 이용해주세요");
    const { code, msg } = await toggleFollow(
      { target_user_id: user.id },
      token.access_token
    );
    if (code < 300) {
      if (!msg) {
        setFollowList((prevList) => ({
          ...prevList,
          followings: prevList.followings.filter(
            (following) => following.id === user.id
          ),
        }));
      }
    } else if (code === 401) {
      const token = await refreshUser();
      const { code, msg } = await toggleFollow(
        { target_user_id: user.id },
        token
      );
      if (code < 300) {
        if (!msg) {
          setFollowList((prevList) => ({
            ...prevList,
            followings: prevList.followings.filter(
              (following) => following.id === user.id
            ),
          }));
        }
      }
    }
  };
  return (
    <>
      {users?.map((user) => {
        const role = user.role;
        const canHandle =
          manageMode && user.status === "active" && role === "USER";
        const handleUserClick = (e) => {
          e.stopPropagation();
          navigate(`/user/${user.id}`);
        };
        const updateUserToExpert = async (e) => {
          e.stopPropagation();
          const confirmed = window.confirm(
            "해당 유저를 전문가로 변경하겠습니까?"
          );
          if (!confirmed) return;
          const { code, msg } = await updateUserRole(
            { target_user_id: user.id, role: "EXPERT" },
            manageMode?.token
          );
          if (code < 300) {
            alert("전문가로 변경되었습니다");
            window.location.reload();
          } else {
            alert("실패", msg);
          }
        };
        const updateUserToAdmin = async (e) => {
          e.stopPropagation();
          const confirmed = window.confirm(
            "해당 유저를 관리자로 변경하겠습니까?"
          );
          if (!confirmed) return;
          const { code, msg } = await updateUserRole(
            { target_user_id: user.id, role: "ADMIN" },
            manageMode?.token
          );
          if (code < 300) {
            alert("관리자로 변경되었습니다");
            window.location.reload();
          } else {
            alert("실패", msg);
          }
        };
        return (
          <div key={user.id} className={listStyles.innerBox}>
            <div
              className={classNames(
                listStyles.listElem,
                canHandle && listStyles.admin
              )}
              onClick={handleUserClick}
            >
              <div className={listStyles.profileBox}>
                <div className={listStyles.profile}>
                  <img
                    src={user.profile_image}
                    className={listStyles.profileIMG}
                    alt="유저 이미지"
                  />
                  <p>{user.username ? user.username : user.userName}</p>
                  {manageMode &&
                    (() => {
                      switch (role) {
                        case "USER":
                          return <h2>유저</h2>;
                        case "EXPERT":
                          return <h2>전문가</h2>;
                        case "ADMIN":
                          return <h2>관리자</h2>;
                        default:
                          return;
                      }
                    })()}
                </div>
              </div>
              {manageMode && (
                <p>{`${user.email} ${(() => {
                  if (user.status === "pending") {
                    return "(인증 미완료)";
                  } else return <></>;
                })()}`}</p>
              )}
              {canHandle && (
                <div
                  className={classNames(listStyles.buttonBox, listStyles.user)}
                >
                  <button
                    className={classNames(listStyles.button, listStyles.user)}
                    onClick={updateUserToExpert}
                  >
                    <p>전문가로 변경</p>
                  </button>
                  <button
                    className={classNames(listStyles.button, listStyles.user)}
                    onClick={updateUserToAdmin}
                  >
                    <p>관리자로 변경</p>
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default UserList;
