import RecommendCount from "./RecommendCount";
import styles from "./InteractionMenu.module.css";
import { ReactComponent as CommmentIcon } from "../assets/icon--comment.svg";
import { ReactComponent as ShareIcon } from "../assets/icon--share.svg";
import classNames from "classnames/bind";

function InteractionMenu({
  isOnList = false,
  type = "posts",
  likes,
  comments = -1,
  id,
}) {
  switch (type) {
    case "posts":
      //postpage -> showpost -> interaction (type = post, 댓글 수 o)
      return (
        <div className={classNames(styles.menu, isOnList && styles.isOnList)}>
          <RecommendCount isOnList={isOnList} aboutLike={{ type, id, likes }} />
          <div className={styles.commented}>
            <CommmentIcon />
            {comments >= 0 && <p>{comments}</p>}
          </div>
          <div className={styles.share}>
            <ShareIcon />
          </div>
        </div>
      );
    case "comments": //postpage -> showcomment -> interaction (type = comment, 댓글/공유 아이콘 x)
      return (
        <div className={classNames(styles.menu, styles.comment)}>
          <RecommendCount isOnList={true} aboutLike={{ type, id, likes }} />
        </div>
      );
    default:
      return;
  }
}

export default InteractionMenu;
