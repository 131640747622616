import React from "react";
import styles from "./Button.module.css";

export const Button = React.forwardRef(
  ({ className, variant = "default", ...props }, ref) => {
    const buttonClass = `${styles.button} ${styles[variant]} ${
      className || ""
    }`;

    return <button className={buttonClass} ref={ref} {...props}></button>;
  }
);

Button.displayName = "Button";
