const base = `${process.env.REACT_APP_API_URL}api/post`;

function formingData(apiData) {
  const formData = new FormData();
  for (const dataKey in apiData) {
    formData.append(`${dataKey}`, apiData[dataKey]);
  }
  return formData;
}

function settingParams(base, params) {
  let url = `${base}?`;
  for (const dataKey in params) {
    url = url + `${dataKey}=${params[dataKey]}&`;
  }

  return url.slice(0, url.length - 1);
}

export async function createPost(postData, token) {
  // {
  //   "community_id": "any",
  //   "title": "any",
  //   "content": "any"
  // }
  const res = await fetch(base, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
    body: formingData(postData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다", data: null };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function getPost(apiParams) {
  // { post_id }
  const res = await fetch(settingParams(base, apiParams), {
    method: "GET",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다", data: null };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function updatePost(postData, token) {
  // {
  //   "post_id": "any",
  //   "title": "any",
  //   "content": "any"
  // }
  const res = await fetch(base, {
    method: "PUT",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
    body: formingData(postData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다", data: null };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function deletePost(postData, token) {
  // {
  //   "post_id": "any"
  // }
  const res = await fetch(base, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
    body: formingData(postData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다", data: null };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function getMyPost(apiParams, token) {
  // { page, page_size }
  const res = await fetch(settingParams(`${base}/my`, apiParams), {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다", data: null };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function getListOfPost(apiParams) {
  // { community_id, user_id, keyword, page, page_size }
  // commuity_id, user_id, keyword는 셋 중 하나만 전달하기
  const res = await fetch(settingParams(`${base}/list`, apiParams), {
    method: "GET",
    credentials: "include",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다", data: null };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function getMajorListOfPost(apiParams) {
  // { category, page, page_size }
  // category는 recent 혹은 popluar 둘 중 하나
  const res = await fetch(settingParams(`${base}/major/list`, apiParams), {
    method: "GET",
    credentials: "include",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다", data: null };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function createImageOnPost(imgData, token) {
  // {
  //   "post_id": "any"
  //   "image": "any"
  // }
  const res = await fetch(`${base}/image`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
    body: formingData(imgData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다", data: null };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function deleteImageOnPost(postData) {
  // {
  //   "post_id": "any",
  //   "image_id": "any"
  // }
  const res = await fetch(`${base}/image`, {
    method: "DELETE",
    body: formingData(postData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다", data: null };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function getListOfImageOnPost(apiParams) {
  // { post_id }
  const res = await fetch(settingParams(`${base}/image/list`, apiParams), {
    method: "GET",
    credentials: "include",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다", data: null };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function approvePendingPost(postData, token) {
  // {
  //   "post_id": "any"
  // }
  const res = await fetch(`${base}/complete`, {
    method: "PUT",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
    body: formingData(postData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다", data: null };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function createComment(postData, token) {
  // {
  //   "post_id": "any",
  //   "content": "any",
  //   "parent_id": "any",
  //   "is_anonymous": "any"
  // }
  const res = await fetch(`${base}/comment`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
    body: formingData(postData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다", data: null };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function updateComment(postData, token) {
  // {
  //   "comment_id": "any",
  //   "content": "any"
  // }
  const res = await fetch(`${base}/comment`, {
    method: "PUT",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
    body: formingData(postData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다", data: null };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function deleteComment(postData, token) {
  // {
  //   "comment_id": "any"
  // }
  const res = await fetch(`${base}/comment`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
    body: formingData(postData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다", data: null };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function getListOfCommentOnPost(apiParams) {
  // { post_id, page, page_size }
  const res = await fetch(settingParams(`${base}/comment/list`, apiParams), {
    method: "GET",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다", data: null };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function createLikeOrDislike(postData, token) {
  // {
  // "like_type": "any",
  // "post_id": "any",
  // "comment_id": "any"
  // }
  let fetchBody;
  switch (postData.type) {
    case "posts":
      fetchBody = { post_id: postData.id, like_type: postData.clicked_type };
      break;
    case "comments":
      fetchBody = { comment_id: postData.id, like_type: postData.clicked_type };
      break;
    default:
      return { code: 405, msg: "postData.type 오류" };
  }
  const res = await fetch(`${base}/like`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
    body: formingData(fetchBody),
  }).then((body) => body.json());

  if (!res) return { code: 404, msg: "주소가 잘못되었습니다" };

  const { code, message: msg } = res;

  return { code, msg };
}
