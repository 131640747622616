const base = `${process.env.REACT_APP_API_URL}api/community`;

function formingData(apiData) {
  const formData = new FormData();
  for (const dataKey in apiData) {
    formData.append(`${dataKey}`, apiData[dataKey]);
  }
  return formData;
}

export async function createCommunity(communityData, token) {
  const res = await fetch(base, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
    body: formingData(communityData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function getCommunity() {
  const res = await fetch(base, {
    method: "GET",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function updateCommunity(communityData, token) {
  // {
  //   "community_id": 2,
  //   "name": "test.mk2",
  //   "description": "test.mk2",
  //   "type": "USER"
  // }
  const res = await fetch(base, {
    method: "PUT",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
    body: formingData(communityData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function deleteCommunity(communityData, token) {
  // {
  //   "community_id": "2"
  // }
  const res = await fetch(base, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
    body: formingData(communityData),
  });

  return res.status;
}

export async function getPendingCommunity(token) {
  const res = await fetch(`${base}/pending`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function approvePendingCommunity(communityData, token) {
  // {
  //   "community_id": 2,
  // }
  const res = await fetch(`${base}/approve`, {
    method: "PUT",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
    body: formingData(communityData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function updateCommunityImg(communityData, token) {
  // {
  //   "community_id": 2,
  // }
  const res = await fetch(`${base}/image`, {
    method: "PUT",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
    body: formingData(communityData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}

export async function deleteCommunityImg(communityData, token) {
  // {
  //   "community_id": 2,
  // }
  const res = await fetch(`${base}/image`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      Cookie: `access_token=${token}`,
    },
    body: formingData(communityData),
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, message: msg, data } = res;

  if (code < 300) {
    return { code, msg, data };
  } else {
    return { code, msg, data: null };
  }
}
