import { useNestock } from "../contexts/NestockProvider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ShowPost from "../components/ShowPost";
// import ShowPostProfile from "../components/ShowPostProfile";
// import InteractionMenu from "../components/InteractionMenu";
import arrow from "../assets/icon--arrow-to-left.svg";
import styles from "./PostPage.module.css";
import pageStyle from "../components/ui/PageBox.module.css";

// function ShowComment({ info }) {
//   const { content, writer, createdAt } = info;
//   return (
//     <div className={styles.comment}>
//       <ShowPostProfile
//         showCommunity={false}
//         profile={{ writer }}
//         time={createdAt}
//       />
//       <p>{content}</p>
//       <InteractionMenu recommendCount="1" commentCount="2" isComment={true} />
//     </div>
//   );
// }

function PostPage() {
  const { communityList, postList } = useNestock();
  console.log(communityList, postList);
  const location = useLocation();
  const navigate = useNavigate();
  // const comments = post.comment;
  const post_id = Number(location.pathname.split("/")[4]);
  console.log(post_id);
  console.log(postList);
  const post = postList.find(({ id }) => id === post_id);
  console.log(post);
  const community = communityList.find(({ id }) => id === post.community_id);

  return (
    <div className={pageStyle.pageBox}>
      <div className={styles.communityBox}>
        <div className={styles.imgBox} onClick={() => navigate(-1)}>
          <img src={arrow} alt="돌아가기" />
        </div>
        <Link to={`/nest/${community.id}`}>
          <h4 className={styles.community}>{community.name} 커뮤니티</h4>
        </Link>
      </div>
      <div className={styles.postBox}>
        <ShowPost showCommunity={false} value={post} />
      </div>
      {/* <div className={styles.commentBox}>
        <h2>댓글 {comments.length}개</h2>
        {comments.map(({ id, content, writer, createdAt }) => (
          <ShowComment key={id} info={{ content, writer, createdAt }} />
        ))}
      </div> */}
    </div>
  );
}

export default PostPage;
