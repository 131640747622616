import { useAuth } from "../contexts/AuthProvider";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DateText from "./DateText";
import pf from "../assets/logo.png";
import { ReactComponent as EtcIcon } from "../assets/icon--etc.svg";
import styles from "./ShowPostProfile.module.css";
import classNames from "classnames";
import { useNestock } from "../contexts/NestockProvider";

function ShowPostProfile({
  isOnList = false,
  isComment = false,
  showOnlyUser,
  profile,
  time,
  onUpdate = () => {},
  onDelete = () => {},
}) {
  const {
    User: { user_id, userName, user_profile },
    Community = {},
  } = profile;
  const { community_id, communityName, community_profile } = Community;
  const { isOnline, user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={classNames(
        styles.profileContainer,
        isOnList && styles.isOnList,
        isComment && styles.comment
      )}
    >
      {showOnlyUser ? (
        <div
          className={classNames(styles.profile, isComment && styles.comment)}
        >
          <img
            src={
              user_profile ||
              "https://nestock-image.s3.ap-northeast-2.amazonaws.com/community_image/default_profile.jpeg"
            }
            className={classNames(styles.userIMG, isComment && styles.comment)}
            alt="유저 이미지"
          />
          <Link to={`/user/${user_id}`}>
            <h4 className={styles.avatar}>{userName}</h4>
          </Link>
          <DateText value={time} />
        </div>
      ) : (
        <div className={classNames(styles.profile, styles.optional)}>
          <img
            src={
              community_profile ||
              "https://nestock-image.s3.ap-northeast-2.amazonaws.com/profile_image/default_profile.png"
            }
            className={styles.communityIMG}
            alt="커뮤니티 이미지"
          />
          <Link to={`/nest/${community_id}`}>
            <h4 className={styles.avatar}>{communityName}</h4>
          </Link>
          <Link to={`/user/${user_id}`}>
            <h5 className={styles.avatar}>{userName}</h5>
          </Link>
          <DateText value={time} />
        </div>
      )}
      {isOnline && (
        <div
          className={styles.etc}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          <div className={styles.etcIMG}>
            <EtcIcon />
          </div>
          {isOpen &&
            (() => {
              if (user.id === user_id) {
                return (
                  <div className={styles.etcButtonBox}>
                    <button className={styles.button} onClick={onUpdate}>
                      <h4>수정</h4>
                    </button>
                    <button className={styles.button} onClick={onDelete}>
                      <h4>삭제</h4>
                    </button>
                  </div>
                );
              } else if (user.id !== user_id && user.role === "ADMIN") {
                return (
                  <div className={styles.etcButtonBox}>
                    <button className={styles.button} onClick={onDelete}>
                      <h4>삭제</h4>
                    </button>
                  </div>
                );
              } else {
                setIsOpen(false);
                return;
              }
            })()}
        </div>
      )}
    </div>
  );
}

export default ShowPostProfile;
