import { useNestock } from "../contexts/NestockProvider";
import { useAuth } from "../contexts/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { deletePost, getListOfPost } from "../api/PostApi";
import PostList from "../components/PostList";
import NotFoundPage from "./NotFoundPage";
import ShowPageProfile from "../components/ShowPageProfile";
import pageStyle from "../components/ui/PageBox.module.css";

function CommunityPage() {
  const { userList, refreshUserList, communityList, updatePostList } =
    useNestock();
  const { checkingToken } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [communityID, setCommunityID] = useState(
    Number(location.pathname.split("/").slice(-1)[0])
  );
  const [community, setCommunity] = useState(() => {
    const commu_id = Number(location.pathname.split("/").slice(-1)[0]);
    const commu = communityList.find((commu) => commu.id === commu_id);
    return commu;
  });
  const [page, setPage] = useState(1);
  const [postList, setPostList] = useState([]);
  const handleUpdate = (post) =>
    navigate(`/posting/${post.id}`, {
      state: {
        user_id: post.User.id,
        title: post.title,
        content: post.content,
      },
    });

  const handleDelete = async (post_id) => {
    const confirmed = window.confirm("해당 게시물을 지우시겠습니까?");
    if (!confirmed) return;
    const { code } = await checkingToken(
      async (token) => await deletePost({ post_id }, token)
    );
    if (code < 300) {
      updatePostList(post_id);
      setPostList((prevList) => prevList.filter((post) => post.id !== post_id));
    }
  };

  let isFirstTime = true;

  const handleLoadMore = async () => {
    if (page === 0) {
      return;
    }

    try {
      const { data } = await getListOfPost({
        community_id: communityID,
        page,
        page_size: 20,
      });

      if (!data || data.length === 0) {
        setPage(0);
        return;
      }

      const prevUserIDs = userList.map((user) => user.id);
      const prevPostIDs = postList.map((post) => post.id);

      const newPosts = data.filter((post) => !prevPostIDs.includes(post.id));

      // Check for new users and refresh user list if needed
      const hasNewUsers = data.some(
        (post) => !prevUserIDs.includes(post.User.id)
      );
      if (hasNewUsers) {
        refreshUserList();
      }

      if (newPosts.length === 0) {
        setPage(0);
        return;
      }

      setPostList((prev) => [...prev, ...newPosts]);

      if (newPosts.length < 20) {
        setPage(0);
      } else {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.error("Failed to load posts:", error);
      setPage(0);
    }
  };

  const resetCommunity = async (newCommunityID) => {
    setPostList([]);
    setPage(1);

    const { data } = await getListOfPost({
      community_id: newCommunityID,
      page: 1,
      page_size: 20,
    });

    if (data && data.length > 0) {
      setPostList(() => data);
      if (data.length < 20) {
        setPage(0);
      } else {
        setPage(2);
      }
    } else {
      setPage(0);
    }
  };

  function addVisitHistory(log) {
    if (!log) return;
    const historyData = localStorage.getItem("visitHistory");
    const prevHistory = historyData ? JSON.parse(historyData) : [];
    const filtered = prevHistory.filter(({ id }) => id !== log.id);
    const addedHistory = [log, ...filtered].splice(0, 20);
    localStorage.setItem("visitHistory", JSON.stringify(addedHistory));
  }

  useEffect(() => {
    if (isFirstTime) {
      addVisitHistory({
        id: community.id,
        communityName: community.communityName,
        profile_image: community.profile_image,
      });
      isFirstTime = false;
    }
    const newCommunityID = Number(location.pathname.split("/").slice(-1)[0]);
    if (communityID !== newCommunityID) {
      const newCommunity = communityList.find(
        (commu) => commu.id === newCommunityID
      );
      addVisitHistory({
        id: newCommunityID,
        communityName: newCommunity.communityName,
        profile_image: newCommunity.profile_image,
      });
      setCommunityID(newCommunityID);
      setCommunity((prevCommu) => ({
        ...prevCommu,
        ...newCommunity,
      }));
      resetCommunity(newCommunityID);
    }
  }, [location]);

  if (!communityID) {
    return (
      <NotFoundPage
        variant="big"
        title="존재하지 않는 커뮤니티입니다"
        description="올바른 주소가 맞는지 다시 한 번 확인해 주세요"
      />
    );
  }

  return (
    <div className={pageStyle.pageBox}>
      <ShowPageProfile option={"community"} target={community} />
      <PostList
        showCommunity={false}
        posts={postList}
        handleLoadMore={handleLoadMore}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
      />
    </div>
  );
}

export default CommunityPage;
