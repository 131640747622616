import { useAuth } from "../contexts/AuthProvider";
import { useEffect, useState } from "react";
import { ReactComponent as LikeIcon } from "../assets/icon--like.svg";
import { ReactComponent as DislikeIcon } from "../assets/icon--dislike.svg";
import styles from "./RecommendCount.module.css";

function RecommendCount({ isOnList, aboutLike }) {
  const { isOnline, likeAndDislikeList, handleLikeOrDislike } = useAuth();
  // const { likes, myLike } = aboutLike;
  const { type, id, likes } = aboutLike;
  const elemIdx = likeAndDislikeList[type].findIndex((elem) => elem.id === id);
  const [option, setOption] = useState(
    elemIdx >= 0 ? likeAndDislikeList[type][elemIdx].current_type : "none"
  );
  const [count, setCount] = useState(
    isOnList || elemIdx < 0 || !likeAndDislikeList[type][elemIdx].current_value
      ? likes
      : likeAndDislikeList[type][elemIdx].current_value
  );
  const notColoredStyle = {
    color: "transparent",
  };
  const handleLikeClick = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isOnline) {
      alert("추천/비추천 기능은 로그인하셔야 이용하실 수 있습니다");
      return;
    }
    const { current_type, current_value } = await handleLikeOrDislike({
      type,
      id,
      prev_value: count,
      clicked_type: "like",
    });
    setOption(current_type);
    setCount(current_value);
  };
  const handleDislikeClick = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isOnline) {
      alert("추천/비추천 기능은 로그인하셔야 이용하실 수 있습니다");
      return;
    }
    const { current_type, current_value } = await handleLikeOrDislike({
      type,
      id,
      prev_value: count,
      clicked_type: "dislike",
    });
    setOption(current_type);
    setCount(current_value);
  };
  useEffect(() => {
    if (isOnline && elemIdx >= 0) {
      const target = likeAndDislikeList[type][elemIdx];
      setOption(target.current_type);
    }
  }, [isOnline]);
  return (
    <div className={styles.recommended}>
      <div className={styles.imgBox} onClick={handleLikeClick}>
        {option === "like" ? (
          <LikeIcon fill="#FF6464" style={notColoredStyle} />
        ) : (
          <LikeIcon fill="none" />
        )}
      </div>
      <p>{count}</p>
      <div className={styles.imgBox} onClick={handleDislikeClick}>
        {option === "dislike" ? (
          <DislikeIcon fill="#6464FF" style={notColoredStyle} />
        ) : (
          <DislikeIcon fill="none" />
        )}
      </div>
    </div>
  );
}

export default RecommendCount;
