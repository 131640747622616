import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AutoComplete from "./AutoComplete";
import { ReactComponent as SearchIcon } from "../assets/search.svg";
import { ReactComponent as CloseButton } from "../assets/icon--close-button.svg";
import styles from "../components/SearchBar.module.css";
import classNames from "classnames";

function SearchBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [searchBarActive, setSearchBarActive] = useState(false);
  const autoComplete = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");

  const [history, setHistory] = useState(() => {
    const historyData = localStorage.getItem("searchHistory");
    return historyData ? JSON.parse(historyData) : [];
  });

  function editSearchHistory(method, searchLog) {
    setHistory((prevHistory) => {
      const filtered = prevHistory.filter(({ type, content }) => {
        const { type: logType, content: logContent } = searchLog;
        if (type === logType) {
          if (logType === "search") {
            return content.keyword !== logContent.keyword;
          } else {
            return content.id !== logContent.id;
          }
        } else {
          return true;
        }
      });
      switch (method) {
        case "add":
          console.log("added");
          return [searchLog, ...filtered].splice(0, 20);
        case "delete":
          console.log("deleted");
          return filtered;
        default:
          console.log("edit method 입력 오류");
          return prevHistory;
      }
    });
  }

  const handleKeywordChange = (e) => setKeyword(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!keyword) {
      return;
    }
    editSearchHistory("add", {
      type: "search",
      content: {
        keyword,
      },
    });
    navigate(`/search?keyword=${keyword}`);
  };

  useEffect(() => {
    setKeyword("");
    setIsOpen(false);
    setSearchBarActive(false);
  }, [location]);

  useEffect(() => {
    localStorage.setItem("searchHistory", JSON.stringify(history));
  }, [history]);

  return (
    <>
      <div
        className={styles.notMobileSearchBar}
        onClick={() => setIsOpen(true)}
      >
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.searchIcon}>
            <SearchIcon />
          </div>
          <input
            name="keyword"
            value={keyword}
            onChange={handleKeywordChange}
            placeholder="검색"
            autoComplete="off"
            className={styles.searchInput}
          />
        </form>
        {isOpen && (
          <div
            className={styles.autoCompleteContainer}
            ref={autoComplete}
            onClick={(e) => {
              e.stopPropagation();
              if (e.target === autoComplete.current) {
                setIsOpen(false);
              }
            }}
          >
            <AutoComplete
              keyword={keyword}
              aboutHistory={{ history, editSearchHistory }}
            />
          </div>
        )}
      </div>
      <div
        className={classNames(
          styles.mobileSearchBar,
          searchBarActive ? styles.active : styles.inactive
        )}
      >
        <div
          className={styles.toggleButton}
          onClick={() => {
            setIsOpen(true);
            setSearchBarActive(true);
          }}
        >
          <SearchIcon />
        </div>
        <div className={styles.activatedBar}>
          <div
            className={styles.closeButton}
            onClick={() => {
              setIsOpen(false);
              setSearchBarActive(false);
            }}
          >
            <CloseButton width="12" height="12" />
          </div>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.searchIcon}>
              <SearchIcon />
            </div>
            <input
              name="keyword"
              value={keyword}
              onChange={handleKeywordChange}
              placeholder="검색"
              autoComplete="off"
              className={styles.searchInput}
            />
          </form>
          {isOpen && (
            <div className={styles.autoCompleteContainer}>
              <AutoComplete
                keyword={keyword}
                aboutHistory={{ history, editSearchHistory }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SearchBar;
