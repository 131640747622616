// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowPageProfile_background__e8Whw {
  height: 200px;
  padding: 20px 40px;
  border-radius: 10px;
  margin-top: 20px;
}

.ShowPageProfile_profileContainer__K-KWl {
  margin: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  position: relative;
  top: -32px;
}

.ShowPageProfile_pfImg__\\+KPPk {
  width: 140px;
  height: 140px;
  border: 5px solid #ffffff;
  border-radius: 70px;
}

.ShowPageProfile_profile__2t8sk {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 12px;
}

.ShowPageProfile_title__FpRSf {
  line-height: 33px;
  margin: 0;
}

.ShowPageProfile_buttonContainer__KnY8N {
  display: flex;
  flex-direction: row;
}

.ShowPageProfile_button__XuzzF {
  width: -moz-fit-content;
  width: fit-content;
  height: 33px;
  padding: 7px 10px;
  border: 1px solid #0f0f0f;
  border-radius: 15px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  background-color: transparent;
}

/* 

.button.upload {

}

.button.follow {
} */
`, "",{"version":3,"sources":["webpack://./src/components/ShowPageProfile.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,SAAS;EACT,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,SAAS;AACX;;AAEA;EACE,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;;;;;;;GAOG","sourcesContent":[".background {\n  height: 200px;\n  padding: 20px 40px;\n  border-radius: 10px;\n  margin-top: 20px;\n}\n\n.profileContainer {\n  margin: 0 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  gap: 20px;\n  position: relative;\n  top: -32px;\n}\n\n.pfImg {\n  width: 140px;\n  height: 140px;\n  border: 5px solid #ffffff;\n  border-radius: 70px;\n}\n\n.profile {\n  width: fit-content;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  gap: 12px;\n}\n\n.title {\n  line-height: 33px;\n  margin: 0;\n}\n\n.buttonContainer {\n  display: flex;\n  flex-direction: row;\n}\n\n.button {\n  width: fit-content;\n  height: 33px;\n  padding: 7px 10px;\n  border: 1px solid #0f0f0f;\n  border-radius: 15px;\n  margin-right: 10px;\n  display: flex;\n  align-items: center;\n  background-color: transparent;\n}\n\n/* \n\n.button.upload {\n\n}\n\n.button.follow {\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `ShowPageProfile_background__e8Whw`,
	"profileContainer": `ShowPageProfile_profileContainer__K-KWl`,
	"pfImg": `ShowPageProfile_pfImg__+KPPk`,
	"profile": `ShowPageProfile_profile__2t8sk`,
	"title": `ShowPageProfile_title__FpRSf`,
	"buttonContainer": `ShowPageProfile_buttonContainer__KnY8N`,
	"button": `ShowPageProfile_button__XuzzF`
};
export default ___CSS_LOADER_EXPORT___;
