import { useNestock } from "../contexts/NestockProvider";
import { useAuth } from "../contexts/AuthProvider";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { deletePost, getListOfPost } from "../api/PostApi";
import NotFoundPage from "./NotFoundPage";
import ShowPageProfile from "../components/ShowPageProfile";
import ReadingOptions from "../components/ReadingOptions";
import TabContent from "../components/TabContent";
import pageStyle from "../components/ui/PageBox.module.css";

function UserPage() {
  const { userList, refreshUserList, updatePostList } = useNestock();
  const { user, checkingToken } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const tabs = [
    { id: "posts", name: "게시물" },
    { id: "comments", name: "댓글" },
    { id: "about", name: "더보기" },
  ];
  const [userID, setUserID] = useState(
    Number(location.pathname.split("/").slice(-1)[0])
  );
  const [targetUser, setTargetUser] = useState(() => {
    const user_id = Number(location.pathname.split("/").slice(-1)[0]);
    const USER = userList.find((user) => user.id === user_id);
    return USER;
  });
  const [page, setPage] = useState(1);
  const [postList, setPostList] = useState([]);
  const handleUpdate = (post) =>
    navigate(`/posting/${post.id}`, {
      state: {
        user_id: post.User.id,
        title: post.title,
        content: post.content,
      },
    });

  const handleDelete = async (post_id) => {
    const confirmed = window.confirm("해당 게시물을 지우시겠습니까?");
    if (!confirmed) return;
    const { code } = await checkingToken(
      async (token) => await deletePost({ post_id }, token)
    );
    if (code < 300) {
      updatePostList(post_id);
      setPostList((prevList) => prevList.filter((post) => post.id !== post_id));
    }
  };
  const [activeTab, setActiveTab] = useState(
    user.role !== "EXPERT" ? "posts" : "infoShare"
  );

  const handleLoadMore = async () => {
    if (page === 0) {
      return;
    }

    try {
      const { data } = await getListOfPost({
        user_id: userID,
        page,
        page_size: 20,
      });

      if (!data || data.length === 0) {
        setPage(0);
        return;
      }

      const prevUserIDs = userList.map((user) => user.id);
      const prevPostIDs = postList.map((post) => post.id);

      const newPosts = data.filter((post) => !prevPostIDs.includes(post.id));

      // Check for new users and refresh user list if needed
      const hasNewUsers = data.some(
        (post) => !prevUserIDs.includes(post.User.id)
      );
      if (hasNewUsers) {
        refreshUserList();
      }

      if (newPosts.length === 0) {
        setPage(0);
        return;
      }

      setPostList((prev) => [...prev, ...newPosts]);

      if (newPosts.length < 20) {
        setPage(0);
      } else {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      setPage(0);
    }
  };

  const resetUser = async (newUserID) => {
    setPostList([]);
    setPage(1);

    const { data } = await getListOfPost({
      user_id: newUserID,
      page: 1,
      page_size: 20,
    });

    if (data && data.length > 0) {
      setPostList(() => data);
      if (data.length < 20) {
        setPage(0);
      } else {
        setPage(2);
      }
    } else {
      setPage(0);
    }
  };

  useEffect(() => {
    const newUserID = Number(location.pathname.split("/").slice(-1)[0]);
    if (userID !== newUserID) {
      const newUser = userList.find((user) => user.id === newUserID);
      setUserID(newUserID);
      setTargetUser((prevUser) => ({
        ...prevUser,
        ...newUser,
      }));
      setActiveTab("posts");
      resetUser(newUserID);
    }
  }, [location]);

  useEffect(() => {
    const newUser = userList.find((user) => user.id === userID);
    setTargetUser((prevUser) => ({
      ...prevUser,
      ...newUser,
    }));
  }, [userList]);

  if (!userID) {
    return (
      <NotFoundPage
        variant="big"
        title="존재하지 않는 유저입니다"
        description="올바른 주소가 맞는지 다시 한 번 확인해 주세요"
      />
    );
  }

  return (
    <div className={pageStyle.pageBox}>
      <ShowPageProfile option={"user"} target={targetUser} />
      <ReadingOptions
        tabs={
          targetUser.role === "ADMIN" && userID === user.id
            ? [...tabs, { id: "adminPriv", name: "관리자" }]
            : tabs
        }
        activeTab={activeTab}
        handleClick={setActiveTab}
      />
      <TabContent
        target={targetUser}
        posts={postList}
        handleLoadMore={handleLoadMore}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        activeTab={activeTab}
        // badges={user.badges}
      />
    </div>
  );
}

export default UserPage;
