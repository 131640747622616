import styles from "./ReadingOptions.module.css";
import classNames from "classnames/bind";
import Warn from "./Warn";
const bindCN = classNames.bind(styles);

function ReadingOptions({ user, activeTab, handleClick }) {
  const { role } = user;

  const userTabs = [
    { id: "entire", name: "전체" },
    { id: "posts", name: "게시물" },
    { id: "comments", name: "댓글" },
    { id: "badge", name: "뱃지" },
  ];

  const mentorTabs = [
    { id: "entire", name: "전체" },
    { id: "infoShare", name: "정보공유" },
    { id: "povShare", name: "관점공유" },
    { id: "dailyLife", name: "일상" },
  ];

  const adminTabs = [
    { id: "entire", name: "전체" },
    { id: "infoShare", name: "정보공유" },
    { id: "povShare", name: "관점공유" },
    { id: "dailyLife", name: "일상" },
  ];

  if (role === "USER") {
    return (
      <div className={styles.tabContainer}>
        {userTabs.map((tab) => (
          <button
            key={tab.id}
            className={bindCN(
              styles.tabButton,
              styles[`${activeTab === tab.id ? "activeTab" : "inactiveTab"}`]
            )}
            onClick={() => handleClick(tab.id)}
          >
            {tab.name}
          </button>
        ))}
      </div>
    );
  } else if (role === "EXPERT") {
    return (
      <div className={styles.tabContainer}>
        {mentorTabs.map((tab) => (
          <button
            key={tab.id}
            className={bindCN(
              styles.tabButton,
              styles[`${activeTab === tab.id ? "activeTab" : "inactiveTab"}`]
            )}
            onClick={() => handleClick(tab.id)}
          >
            {tab.name}
          </button>
        ))}
      </div>
    );
  } else if (role === "ADMIN") {
    return (
      <div className={styles.tabContainer}>
        {adminTabs.map((tab) => (
          <button
            key={tab.id}
            className={bindCN(
              styles.tabButton,
              styles[`${activeTab === tab.id ? "activeTab" : "inactiveTab"}`]
            )}
            onClick={() => handleClick(tab.id)}
          >
            {tab.name}
          </button>
        ))}
      </div>
    );
  } else {
    return <Warn variant="big" title="로그인하셔야 이용하실 수 있습니다" />;
  }
}

export default ReadingOptions;
