import React from "react";
import styles from "./TradeList.module.css";

const TradeList = ({ trades }) => (
  <div className={styles.container}>
    <h2 className={styles.title}>매매 기록 목록</h2>
    {trades.length === 0 ? (
      <p>아직 기록된 매매가 없습니다.</p>
    ) : (
      trades.map((trade, index) => (
        <div key={index} className={styles.tradeItem}>
          <h3 className={styles.tradeTitle}>
            {trade.stockSymbol} - {trade.tradeType === "buy" ? "매수" : "매도"}
          </h3>
          <p>
            가격: {trade.price}원, 수량: {trade.quantity}주
          </p>
          <p>일시: {new Date(trade.date).toLocaleString()}</p>
          <p>이유: {trade.reason}</p>
          <p>감정 상태: {trade.emotion}</p>
        </div>
      ))
    )}
  </div>
);

export default TradeList;
