import Nav from "./components/Nav";
import styles from "./components/App.module.css";
import "./components/App.font.css";
import { Outlet, useNavigate, ScrollRestoration } from "react-router-dom";
import LeftSideBar from "./components/LeftSideBar";
import RightSideBar from "./components/RightSideBar";
import { AuthProvider, useAuth } from "./contexts/AuthProvider";
import { NestockProvider } from "./contexts/NestockProvider";
import { ErrorBoundary } from "react-error-boundary";
import useDidMountEffect from "./hooks/useDidMountEffect";
import { useEffect } from "react";

function App() {
  const { isOnline } = useAuth();
  const navigate = useNavigate();

  function ErrorFallback({ error }) {
    return (
      <div role="alert">
        <p>Something went wrong</p>
        <pre>{error.message}</pre>
      </div>
    );
  }
  useEffect(() => {
    if (!window.isReactNativeWebview) return;
    const onScroll = () =>
      window.ReactNativeWebView.postMessage(window.scrollY);
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useDidMountEffect(() => {
    if (!isOnline) {
      navigate("/");
    }
  }, [isOnline]);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(e) => e.stopPropagation()}
      stopPropagation
    >
      <div className={styles.container}>
        <NestockProvider>
          <AuthProvider>
            <Nav className={styles.nav} />
            <div className={styles.body}>
              <LeftSideBar className={styles.leftSideBar} />
              <div className={styles.content}>
                <Outlet className={styles.mainContent} />
                <ScrollRestoration />
                <RightSideBar className={styles.rightsideBar} />
              </div>
            </div>
          </AuthProvider>
        </NestockProvider>
      </div>
    </ErrorBoundary>
  );
}

export default App;
