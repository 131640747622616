// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowPostProfile_profileContainer__jyq\\+7 {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.ShowPostProfile_profile__pVgo2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ShowPostProfile_img__nP7SQ {
  height: 40px;
  border-radius: 23px;
  margin-right: 5px;
}

.ShowPostProfile_avatar__OaP26 {
  padding-top: 1px;
  padding-right: 7px;
}

.ShowPostProfile_etc__l5AJh {
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/ShowPostProfile.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".profileContainer {\n  height: 40px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.profile {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.img {\n  height: 40px;\n  border-radius: 23px;\n  margin-right: 5px;\n}\n\n.avatar {\n  padding-top: 1px;\n  padding-right: 7px;\n}\n\n.etc {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileContainer": `ShowPostProfile_profileContainer__jyq+7`,
	"profile": `ShowPostProfile_profile__pVgo2`,
	"img": `ShowPostProfile_img__nP7SQ`,
	"avatar": `ShowPostProfile_avatar__OaP26`,
	"etc": `ShowPostProfile_etc__l5AJh`
};
export default ___CSS_LOADER_EXPORT___;
