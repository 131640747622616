import styles from "./LeftSideBar.module.css";
import recent from "../assets/icon--recent.svg";
import hotPost from "../assets/icon--hot-post.svg";
import hotCoummnity from "../assets/icon--hot-community.svg";
import info from "../assets/icon--info.svg";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const bindCN = classNames.bind(styles);

function LeftSideBar({ isOpen }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [visitHistory, setVisitHistory] = useState(() => {
    const historyData = localStorage.getItem("visitHistory");
    return historyData ? JSON.parse(historyData) : [];
  });

  useEffect(() => {
    setVisitHistory(() => {
      const historyData = localStorage.getItem("visitHistory");
      const nextHistory = historyData ? JSON.parse(historyData) : [];
      return nextHistory;
    });
  }, [location.pathname]);
  return (
    <div
      className={bindCN(
        styles.edge,
        styles[`${isOpen ? "toggleContainer" : "notToggleContainer"}`]
      )}
    >
      <div className={styles.sideBar}>
        <div className={styles.content}>
          <div className={styles.title}>
            <img src={recent} alt="최근 방문" />
            <h3>최근 방문</h3>
          </div>
          <div className={styles.list}>
            {visitHistory.length > 0 ? (
              visitHistory.map((history, i) => (
                <p key={i} onClick={() => navigate(`/nest/${history.id}`)}>
                  {history.name} 커뮤니티
                </p>
              ))
            ) : (
              <p>방문하신 커뮤니티가 없습니다</p>
            )}
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <img src={hotPost} alt="최근 방문" />
            <h3>인기 게시물</h3>
          </div>
          <div className={styles.list}>
            <p>운영 준비중인 컨텐츠입니다</p>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <img src={hotCoummnity} alt="최근 방문" />
            <h3>인기 커뮤니티</h3>
          </div>
          <div className={styles.list}>
            <p>운영 준비중인 컨텐츠입니다</p>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <img src={info} alt="최근 방문" />
            <h3>사이트 정보</h3>
          </div>
          <div className={styles.list}>
            <p>nestock 소개</p>
            <p>멤버쉽 소개</p>
            <p>사용자 이용 약관</p>
            <p>개인정보 처리방침</p>
            <p>문의하기</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftSideBar;
