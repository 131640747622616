// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dialog_overlay__ldYC6 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Dialog_content__IsdwO {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}

.Dialog_header__vOd6g {
  margin-bottom: 16px;
}

.Dialog_title__NdeTD {
  font-size: 1.5rem;
  font-weight: bold;
}

.Dialog_footer__npQH1 {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/Dialog.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,yBAAyB;AAC3B","sourcesContent":[".overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.content {\n  background-color: white;\n  padding: 20px;\n  border-radius: 8px;\n  max-width: 500px;\n  width: 100%;\n}\n\n.header {\n  margin-bottom: 16px;\n}\n\n.title {\n  font-size: 1.5rem;\n  font-weight: bold;\n}\n\n.footer {\n  margin-top: 16px;\n  display: flex;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `Dialog_overlay__ldYC6`,
	"content": `Dialog_content__IsdwO`,
	"header": `Dialog_header__vOd6g`,
	"title": `Dialog_title__NdeTD`,
	"footer": `Dialog_footer__npQH1`
};
export default ___CSS_LOADER_EXPORT___;
