import ReactDOM from "react-dom/client";
import Main from "./Main";

function ErrorFallback({ error }) {
  console.log("ErrorFallback rendering with:", error);
  return (
    <div>
      <p>Something went wrong</p>
      <pre>{error.message}</pre>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));

try {
  root.render(<Main />);
} catch (error) {
  // 1. setTimeout으로 비동기 렌더링
  // setTimeout(() => {
  //   root.render(<ErrorFallback error={error} />);
  // }, 0);

  // 또는
  // 2. 새로운 root 생성 시도
  const newRoot = ReactDOM.createRoot(document.getElementById("root"));
  newRoot.render(<ErrorFallback error={error} />);
}
