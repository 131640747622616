import { createContext, useContext, useEffect, useState } from "react";
import { login, logout, refresh_token } from "../api/AuthApi";
import { getUser } from "../api/UserApi";
import { createLikeOrDislike } from "../api/PostApi";
import { useNestock } from "./NestockProvider";

const initial_user = {
  id: 0,
  status: null,
  userName: null,
  password: null,
  email: null,
  role: null,
  social_provider: null,
  social_id: null,
  created_at: null,
  updated_at: null,
  myPosts: null,
};

const initial_followList = {
  followers: [],
  followings: [],
};

const initial_likeAndDislikeList = {
  posts: [],
  comments: [],
};

const initial_token = {
  access_token: null,
  refresh_token: null,
};

const AuthContext = createContext({
  user: initial_user,
  token: initial_token,
  isOnline: false,
  setLogIn: () => {},
  setLogOut: () => {},
  setUpdate: () => {},
});

function AuthProvider({ children }) {
  const { userList, refreshUserList } = useNestock();
  const [user, setUser] = useState(initial_user);
  const [followList, setFollowList] = useState(initial_followList);
  const [likeAndDislikeList, setLikeAndDislikeList] = useState(
    initial_likeAndDislikeList
  );
  const [token, setToken] = useState(initial_token);
  const [isOnline, setIsOnline] = useState(false);

  async function setLogIn(loginData) {
    const { code, msg, nextUser, nextToken } = await login(loginData);
    if (code < 300) {
      const { username, Likes, ...rest } = nextUser;
      setUser((prevUser) => ({
        ...prevUser,
        userName: username,
        ...rest,
      }));
      setFollowList((prevList) => {
        const user = userList.find((user) => user.id === rest.id);
        if (user) {
          const followers = user.followers;
          const followings = user.followings;
          return {
            ...prevList,
            followers,
            followings,
          };
        } else {
          refreshUserList();
          return {
            ...prevList,
            followers: [],
            followings: [],
          };
        }
      });
      setLikeAndDislikeList((prevList) => {
        const nextPosts = [];
        const nextComments = [];
        Likes.forEach((data) => {
          switch (data.entity_type) {
            case "post":
              nextPosts.push({
                id: data.entity_id,
                current_type: data.is_like ? "like" : "dislike",
                current_value: null,
              });
              break;
            case "comment":
              nextComments.push({
                id: data.entity_id,
                current_type: data.is_like ? "like" : "dislike",
                current_value: null,
              });
              break;
            default:
              break;
          }
        });
        return {
          ...prevList,
          posts: nextPosts,
          comments: nextComments,
        };
      });
      setToken((prevToken) => ({
        ...prevToken,
        ...nextToken,
      }));
      setIsOnline(true);
    }
    return { code, msg };
  }

  async function setLogOut(token) {
    const { code, msg } = await logout(token);
    setUser({
      ...user,
      ...initial_user,
    });
    setLikeAndDislikeList(initial_likeAndDislikeList);
    setToken((prevToken) => ({ ...prevToken, ...initial_token }));
    setIsOnline(false);
    if (token) alert("로그아웃 되었습니다");
    else alert("다시 로그인해주세요");
  }

  async function setUpdate(updatedData) {
    setUser((prevUser) => ({
      ...prevUser,
      ...updatedData,
    }));
  }

  async function refreshUser() {
    const { access_token } = await refresh_token({
      refresh_token: token.refresh_token,
    });
    if (access_token) {
      setToken((prevToken) => ({
        ...prevToken,
        access_token,
      }));
      return access_token;
    } else setLogOut();
  }

  async function checkingToken(tokenFunction) {
    const { code, msg, data } = await tokenFunction(token.access_token);
    if (!code) return { code: 403, msg, data };
    if (code < 300) return { code, msg, data };
    else if (code === 401) {
      const { access_token } = await refresh_token({
        refresh_token: token.refresh_token,
      });
      if (access_token) {
        setToken((prevToken) => ({
          ...prevToken,
          access_token,
        }));
        const { code, msg, data } = await tokenFunction(access_token);
        if (code < 300) return { code, msg, data, access_token };
      } else {
        setLogOut();
        return { code, msg, data };
      }
    } else return { code, msg, data };
  }

  function transposeLikes(type) {
    const transpose = {
      dislike: -1,
      none: 0,
      like: 1,
    };
    return transpose[type];
  }

  function computeChangedType(clicked_type, prev_type) {
    // current_type 구하기, origin_type은 관여 x
    if (clicked_type === prev_type) return "none";
    return clicked_type;
  }

  function addLikeOrDislike({ type, id, current_type, current_value }) {
    setLikeAndDislikeList((prevList) => ({
      ...prevList,
      [type]: [
        ...prevList[type],
        {
          id,
          current_type: current_type,
          current_value: Number(current_value) + transposeLikes(current_type),
        },
      ],
    }));
    return {
      current_type: current_type,
      current_value: Number(current_value) + transposeLikes(current_type),
    };
  }

  async function handleLikeOrDislike({ type, id, prev_value, clicked_type }) {
    const list = likeAndDislikeList[type];
    const targetIdx = list.findIndex((elem) => elem.id === id);
    const { code } = await checkingToken(async (token) => {
      const { code, msg, data } = await createLikeOrDislike(
        {
          type,
          id,
          clicked_type,
        },
        token
      );
      return { code, msg, data };
    });
    if (code >= 300) return { current_type: "none", current_value: prev_value };
    if (targetIdx >= 0) {
      // 기존 리스트에 있었음
      const target = list[targetIdx];
      const next_type = computeChangedType(clicked_type, target.current_type);
      setLikeAndDislikeList((prevList) => ({
        ...prevList,
        [type]: [
          ...prevList[type].splice(0, targetIdx),
          {
            ...target,
            current_type: next_type,
            current_value:
              target.current_value -
              transposeLikes(target.current_type) +
              transposeLikes(next_type),
          },
          ...prevList[type].splice(targetIdx + 1),
        ],
      }));
      return {
        current_type: next_type,
        current_value:
          target.current_value -
          transposeLikes(target.current_type) +
          transposeLikes(next_type),
      };
    } else {
      // 기존 리스트에 없었음 (none이었다가 RecommendCount에서 처음 사용됨)
      const { current_type, current_value } = addLikeOrDislike({
        type,
        id,
        current_type: clicked_type,
        current_value: prev_value,
      });
      return { current_type, current_value };
    }
  }

  function resetCurrentValue({ type, id, likes }) {
    const list = likeAndDislikeList[type];
    const targetIdx = list.findIndex((elem) => elem.id === id);
    if (targetIdx < 0) return;
    const target = list[targetIdx];
    setLikeAndDislikeList((prevList) => ({
      ...prevList,
      [type]: [
        ...prevList[type].splice(0, targetIdx),
        {
          ...target,
          current_value: Number(likes),
        },
        ...prevList[type].splice(targetIdx + 1),
      ],
    }));
  }

  useEffect(() => {
    //첫 렌더링시 (새로고침시 포함) 로그인 인증된 사용자의 정보를 조회
    //access 쿠키 이용
    async function gettingUser() {
      const refreshToken = localStorage.getItem("refresh_token");
      if (refreshToken) {
        const { code, msg, access_token } = await refresh_token({
          refresh_token: refreshToken,
        });
        localStorage.removeItem("refresh_token");
        if (access_token) {
          const { code, nextUser } = await getUser(
            { user_id: 0 },
            access_token
          );
          if (code < 300) {
            const { username, Likes, ...rest } = nextUser;
            setToken((prevToken) => ({
              ...prevToken,
              refresh_token: refreshToken,
              access_token,
            }));
            setUser((prevUser) => ({
              ...prevUser,
              userName: username,
              ...rest,
            }));
            setFollowList((prevList) => {
              const user = userList.find((user) => user.id === rest.id);
              const followers = user.followers;
              const followings = user.followings;
              return {
                ...prevList,
                followers,
                followings,
              };
            });
            setLikeAndDislikeList((prevList) => {
              const nextPosts = [];
              const nextComments = [];
              Likes.forEach((data) => {
                switch (data.entity_type) {
                  case "post":
                    nextPosts.push({
                      id: data.entity_id,
                      current_type: data.is_like ? "like" : "dislike",
                      current_value: null,
                    });
                    break;
                  case "comment":
                    nextComments.push({
                      id: data.entity_id,
                      current_type: data.is_like ? "like" : "dislike",
                      current_value: null,
                    });
                    break;
                  default:
                    break;
                }
              });
              return {
                ...prevList,
                posts: nextPosts,
                comments: nextComments,
              };
            });
            setIsOnline(true);
          } else {
            alert("다시 로그인해주세요");
          }
        } else {
          alert("다시 로그인해주세요");
        }
      }
    }
    ///
    gettingUser();
  }, []);

  window.addEventListener("unload", () => {
    // const url = `${process.env.REACT_APP_API_URL}api/post/like`;
    // user.posts.forEach((post) => {
    //   if (post.request_type) {
    //     navigator.sendBeacon(
    //       url,
    //       JSON.stringify({
    //         like_type: post.request_type,
    //         post_id: post.id,
    //       })
    //     );
    //   }
    // });
    // user.comments.forEach((comment) => {
    //   if (comment.request_type) {
    //     navigator.sendBeacon(
    //       url,
    //       JSON.stringify({
    //         like_type: comment.request_type,
    //         comment_id: comment.id,
    //       })
    //     );
    //   }
    // });
    if (token.refresh_token) {
      localStorage.setItem("refresh_token", token.refresh_token);
    }
  });

  return (
    <AuthContext.Provider
      value={{
        user,
        refreshUser,
        checkingToken,
        followList,
        setFollowList,
        likeAndDislikeList,
        token,
        isOnline,
        setLogIn,
        setLogOut,
        setUpdate,
        addLikeOrDislike,
        handleLikeOrDislike,
        resetCurrentValue,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("반드시 AuthProvider 안에서 사용해야 합니다.");
  }

  return context;
}

export { AuthProvider, useAuth };
