import { useNavigate } from "react-router-dom";
// import { getCoumminityNames } from "../api";
import styles from "./AutoComplete.module.css";
import { useNestock } from "../contexts/NestockProvider";
import { ReactComponent as Close } from "../assets/icon--close-button.svg";
import classNames from "classnames";

function AutoComplete({ keyword, aboutHistory }) {
  const { userList, communityList } = useNestock();
  const { editSearchHistory } = aboutHistory;
  function getUsers(keyword) {
    if (!keyword) return;

    const lowered = keyword.toLowerCase();

    const allItems = userList.filter(({ userName }) =>
      userName.toLowerCase().includes(lowered)
    );

    return allItems.slice(0, 6);
  }
  function getCoumminities(keyword) {
    if (!keyword) return;

    const lowered = keyword.toLowerCase();

    const allItems = communityList.filter(({ communityName }) =>
      communityName.toLowerCase().includes(lowered)
    );

    return allItems.slice(0, 6);
  }
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    const { type, content } = JSON.parse(
      e.target.closest("[data-search]").dataset.search
    );
    editSearchHistory("add", { type, content });
    if (type === "community") {
      navigate(`/nest/${content.id}`);
    } else if (type === "user") {
      navigate(`/user/${content.id}`);
    } else if (type === "search") {
      navigate(`/search?keyword=${content.keyword}`);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { type, content } = JSON.parse(
      e.target.closest("[data-search]").dataset.search
    );
    editSearchHistory("delete", { type, content });
  };
  return (
    <div className={styles.list}>
      {keyword ? (
        <>
          {getUsers(keyword).map(({ id, userName, profile_image }) => (
            <div
              key={id}
              className={classNames(styles.elementContainer, styles.search)}
              data-search={JSON.stringify({
                type: "user",
                content: { id, name: userName, img: profile_image },
              })}
              onClick={handleClick}
            >
              <div className={styles.profileBox}>
                <img
                  className={styles.profileIMG}
                  src={profile_image}
                  alt="프로필"
                />
                <h4 className={styles.element}>{userName}</h4>
              </div>
            </div>
          ))}
          {getCoumminities(keyword).map(
            ({ id, communityName, profile_image }) => (
              <div
                key={id}
                className={classNames(styles.elementContainer, styles.search)}
                data-search={JSON.stringify({
                  type: "community",
                  content: { id, name: communityName, img: profile_image },
                })}
                onClick={handleClick}
              >
                <div className={styles.profileBox}>
                  <img
                    className={styles.profileIMG}
                    src={profile_image}
                    alt="프로필"
                  />
                  <h4 className={styles.element}>{communityName}</h4>
                </div>
              </div>
            )
          )}
        </>
      ) : (
        <>
          <h2>최근 검색어</h2>
          {aboutHistory.history.slice(0, 5).map(({ type, content }, i) => (
            <div
              key={i}
              className={classNames(styles.elementContainer, styles.history)}
              data-search={JSON.stringify({ type, content })}
              onClick={handleClick}
            >
              <div className={styles.profileBox}>
                {type !== "search" && (
                  <img
                    className={styles.profileIMG}
                    src={content.img}
                    alt="프로필"
                  />
                )}
                <h4 className={styles.element}>
                  {type === "community" && `${content.name} 커뮤니티`}
                  {type === "user" && content.name}
                  {type === "search" && content.keyword}
                </h4>
              </div>
              <button
                className={classNames(
                  styles.button,
                  type === "search" && styles.search
                )}
                onClick={handleDelete}
                type="button"
              >
                <Close width="12" height="12" />
              </button>
            </div>
          ))}
          {aboutHistory.history.length === 0 && <p>최근 검색어가 없습니다</p>}
        </>
      )}
    </div>
  );
}

export default AutoComplete;
