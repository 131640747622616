import { useAuth } from "../contexts/AuthProvider";
import { useNavigate } from "react-router-dom";
import nestock from "../assets/logo.png";
import { ReactComponent as Plus } from "../assets/icon--plus.svg";
import styles from "./ShowPageProfile.module.css";
import { useNestock } from "../contexts/NestockProvider";
import { toggleFollow } from "../api/UserApi";
import { useEffect, useState } from "react";
import classNames from "classnames";

function ShowPageProfile({ option, target = null }) {
  const { isOnline, user, token, refreshUser, followList, setFollowList } =
    useAuth();
  const { refreshUserList } = useNestock();
  const navigate = useNavigate();
  const targetInfo = (() => {
    switch (option) {
      case "user":
        return {
          id: target.id,
          title: target.userName,
          profile_image: target.profile_image,
          followers: target.followers.length > 0 ? target.followers : [],
          followings: target.followings.length > 0 ? target.followings : [],
          created_at: target.created_at,
        };
      case "mentor":
        return;
      case "community":
        return {
          id: target.id,
          title: target.communityName,
          profile_image: target.profile_image,
          description: target.description,
          created_by: target.created_by,
          created_at: target.created_at,
          updated_at: target.updated_at,
        };
      default:
        return;
    }
  })();
  // const titleStyle = useMemo(() => {
  //   function adjustFontSize(text) {
  //     const maxFontSize = 1.5; // 최대 폰트 크기
  //     const minFontSize = 1; // 최소 폰트 크기
  //     const lengthFactor = 1.5; // 텍스트 길이에 따른 크기 조절 계수

  //     let textLength = element.textContent.length;
  //     let newSize = Math.max(minFontSize, maxFontSize - textLength * lengthFactor);

  //     element.style.fontSize = `${newSize}rem`;
  //   }
  //   return {
  //     fontSize: ,
  //     letterSpacing: ,
  //   }
  // }, []);
  const [following, setFollowing] = useState(
    followList.followings.findIndex((user) => targetInfo?.id === user.id) >= 0
  );
  console.log(
    followList.followings.findIndex((user) => targetInfo?.id === user.id)
  );
  const handleFollow = async () => {
    if (!isOnline) alert("로그인하시고 이용해주세요");
    const { code, msg } = await toggleFollow(
      { target_user_id: targetInfo.id },
      token.access_token
    );
    if (code < 300) {
      if (msg) {
        setFollowing(true);
        setFollowList((prevList) => ({
          ...prevList,
          followings: [
            ...prevList.followings,
            {
              id: targetInfo.id,
              userName: targetInfo.title,
              profile_image: targetInfo.profile_image,
            },
          ],
        }));
        refreshUserList();
      }
    } else if (code === 401) {
      const token = await refreshUser();
      const { code, msg } = await toggleFollow(
        { target_user_id: targetInfo.id },
        token
      );
      if (code < 300) {
        if (msg) {
          setFollowing(true);
          setFollowList((prevList) => ({
            ...prevList,
            followings: [
              ...prevList.followings,
              {
                id: targetInfo.id,
                userName: targetInfo.title,
                profile_image: targetInfo.profile_image,
              },
            ],
          }));
          refreshUserList();
        }
      }
    }
  };
  const handleUnfollow = async () => {
    if (!isOnline) alert("로그인하시고 이용해주세요");
    const { code, msg } = await toggleFollow(
      { target_user_id: targetInfo.id },
      token.access_token
    );
    if (code < 300) {
      if (!msg) {
        setFollowing(false);
        setFollowList((prevList) => ({
          ...prevList,
          followings: prevList.followings.filter(
            (following) => following.id !== targetInfo.id
          ),
        }));
        refreshUserList();
      }
    } else if (code === 401) {
      const token = await refreshUser();
      const { code, msg } = await toggleFollow(
        { target_user_id: targetInfo.id },
        token
      );
      if (code < 300) {
        if (!msg) {
          setFollowing(false);
          setFollowList((prevList) => ({
            ...prevList,
            followings: prevList.followings.filter(
              (following) => following.id === targetInfo.id
            ),
          }));
          refreshUserList();
        }
      }
    }
  };
  const isNotice = targetInfo?.title === "공지";

  useEffect(() => {
    setFollowing(
      followList.followings.findIndex((user) => target.id === user.id) >= 0
    );
  }, [target, isOnline]);
  if (option === "manager")
    return (
      <>
        <div
          alt="프로필 배경 이미지"
          style={{
            backgroundColor: "#f0f0f0",
          }}
          className={styles.background}
        />
        <div className={styles.profileContainer}>
          <div className={styles.profile}>
            <img src={nestock} alt="관리자" className={styles.pfImg} />
          </div>
          <div className={styles.pf}>
            <p className={styles.title}>관리자</p>
          </div>
        </div>
      </>
    );

  return (
    <>
      <div className={styles.profileContainer}>
        <div className={styles.profile}>
          <img
            src={targetInfo.profile_image}
            alt="프로필 이미지"
            className={styles.pfImg}
          />
          {option === "user" && user.id === targetInfo.id && (
            <button
              onClick={() => navigate(`/user/${user.id}/edit`)}
              className={styles.editButton}
            >
              프로필 편집&nbsp;
            </button>
          )}
          {option === "community" && user.id === targetInfo.created_by && (
            <button
              onClick={() => navigate(`/nest/${targetInfo.id}/edit`)}
              className={styles.editButton}
            >
              프로필 편집&nbsp;
            </button>
          )}
        </div>
        <div className={styles.pf}>
          <div className={styles.pfUpward}>
            <p className={classNames(styles.title, styles.community)}>
              {targetInfo.title}
            </p>
            {(() => {
              if (
                option === "community" &&
                (!isNotice || user.role === "ADMIN")
              )
                return (
                  <button
                    onClick={() =>
                      navigate("/posting", {
                        state: { community_id: targetInfo.id },
                      })
                    }
                    className={styles.followButton}
                  >
                    포스팅
                  </button>
                );
              if (targetInfo.id === user.id || option !== "user") return;
              if (!isOnline)
                return (
                  <button
                    onClick={handleFollow}
                    className={styles.followButton}
                  >
                    팔로우
                  </button>
                );
              if (following)
                return (
                  <button
                    onClick={handleUnfollow}
                    className={styles.followingButton}
                  >
                    팔로잉
                  </button>
                );
              else
                return (
                  <button
                    onClick={handleFollow}
                    className={styles.followButton}
                  >
                    팔로우
                  </button>
                );
            })()}
          </div>
          <div className={styles.profileBox}>
            {option === "user" && (
              <>
                <div
                  onClick={() =>
                    navigate("./follow-list", { state: "followers" })
                  }
                  className={styles.follow}
                >
                  <span>팔로워 </span>
                  <span>{targetInfo.followers.length}</span>
                </div>
                <div
                  onClick={() =>
                    navigate("./follow-list", { state: "followings" })
                  }
                  className={styles.follow}
                >
                  <span>팔로잉 </span>
                  <span>{targetInfo.followings.length}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {option === "community" && (
        <p className={styles.description}>{targetInfo.description}</p>
      )}
    </>
  );
}

export default ShowPageProfile;
