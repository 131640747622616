// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReadingOptions_tabContainer__XHC\\+P {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.ReadingOptions_tabButton__6aq\\+p {
  width: 22%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
}

.ReadingOptions_inactiveTab__Ipa35:hover {
  background-color: rgb(230, 230, 230);
}

.ReadingOptions_activeTab__Uo\\+pa {
  background-color: rgb(200, 200, 200);
}
`, "",{"version":3,"sources":["webpack://./src/components/ReadingOptions.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".tabContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n}\n\n.tabButton {\n  width: 22%;\n  height: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 16px;\n  padding: 5px;\n  border-radius: 10px;\n  text-align: center;\n}\n\n.inactiveTab:hover {\n  background-color: rgb(230, 230, 230);\n}\n\n.activeTab {\n  background-color: rgb(200, 200, 200);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": `ReadingOptions_tabContainer__XHC+P`,
	"tabButton": `ReadingOptions_tabButton__6aq+p`,
	"inactiveTab": `ReadingOptions_inactiveTab__Ipa35`,
	"activeTab": `ReadingOptions_activeTab__Uo+pa`
};
export default ___CSS_LOADER_EXPORT___;
