import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import MainPage from "./pages/MainPage";
import SearchResultPage from "./pages/SearchResultPage";
import CommunityPage from "./pages/CommunityPage";
// import MentorListPage from "./pages/MentorListPage";
// import MentorPage from "./pages/MentorPage";
import PostPage from "./pages/PostPage";
import PostingPage from "./pages/PostingPage";
import CalendarPage from "./pages/CalendarPage";
import TradeMemoPage from "./pages/TradeMemoPage";
import CouncelPage from "./pages/CouncelPage";
import MembershipPage from "./pages/MembershipPage";
import UserPage from "./pages/UserPage";
import NotFoundPage from "./pages/NotFoundPage";

function Main() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<MainPage />} />
          <Route path="search" element={<SearchResultPage />} />
          <Route path="nest">
            <Route index element={<NotFoundPage />} />
            <Route path=":communitySlug">
              <Route index element={<CommunityPage />} />
              <Route path="post">
                <Route index element={<NotFoundPage />} />
                <Route path=":postId" element={<PostPage />} />
              </Route>
              <Route path="posting" element={<PostingPage />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          {/* <Route path="mentor">
            <Route index element={<MentorListPage />} />
            <Route path=":mentorSlug">
              <Route index element={<MentorPage />} />
              <Route path="post">
                <Route index element={<NotFoundPage />} />
                <Route path=":postId" element={<PostPage />} />
              </Route>
              <Route path="posting" element={<PostingPage />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Route> */}
          <Route path="calendar" element={<CalendarPage />} />
          <Route path="tradememo" element={<TradeMemoPage />} />
          <Route path="councel" element={<CouncelPage />} />
          <Route path="membership" element={<MembershipPage />} />
          <Route path="user">
            <Route index element={<NotFoundPage />} />
            <Route path="me">
              <Route index element={<UserPage />} />
            </Route>
            {/* <Route path=":userID">
              <Route index element={<UserPage />} />
            </Route> */}
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Main;
