import Nav from "./components/Nav";
import styles from "./components/App.module.css";
import "./components/App.font.css";
import { Outlet, useNavigate } from "react-router-dom";
import LeftSideBar from "./components/LeftSideBar";
import RightSideBar from "./components/RightSideBar";
import { SearchProvider } from "./contexts/SearchContext";
import { AuthProvider, useAuth } from "./contexts/AuthProvider";
import { NestockProvider } from "./contexts/NestockProvider";
import { ErrorBoundary } from "react-error-boundary";
import useDidMountEffect from "./hooks/useDidMountEffect";

function App() {
  const { isOnline } = useAuth();
  const navigate = useNavigate();

  function ErrorFallback({ error }) {
    return (
      <div role="alert">
        <p>Something went wrong</p>
        <pre>{error.message}</pre>
      </div>
    );
  }

  function handleError(error, info) {
    console.log("Logging error:", error);
    console.log("Error Information:", info);
    error.stopPropagation?.();
  }

  useDidMountEffect(() => {
    if (!isOnline) {
      navigate("/");
    }
  }, [isOnline]);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={handleError}
      stopPropagation
    >
      <div className={styles.container}>
        <AuthProvider>
          <SearchProvider>
            <NestockProvider>
              <Nav className={styles.nav} />
              <div className={styles.body}>
                <LeftSideBar className={styles.leftSideBar} />
                <div className={styles.content}>
                  <Outlet className={styles.mainContent} />
                  <RightSideBar className={styles.rightsideBar} />
                </div>
              </div>
            </NestockProvider>
          </SearchProvider>
        </AuthProvider>
      </div>
    </ErrorBoundary>
  );
}

export default App;
