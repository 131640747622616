// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecommendCount_recommended__IZPQY > p {
  width: fit-content;
  padding: 0 3px;
  margin: 0;
}

.RecommendCount_imgBox__CC3NC {
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

@media screen and (max-width: 960px) {
  .RecommendCount_imgBox__CC3NC:active {
    background-color: rgb(200, 200, 200);
  }
}

@media screen and (min-width: 960px) {
  .RecommendCount_imgBox__CC3NC:hover {
    background-color: rgb(200, 200, 200);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/RecommendCount.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE;IACE,oCAAoC;EACtC;AACF;;AAEA;EACE;IACE,oCAAoC;EACtC;AACF","sourcesContent":[".recommended > p {\n  width: fit-content;\n  padding: 0 3px;\n  margin: 0;\n}\n\n.imgBox {\n  width: 30px;\n  height: 30px;\n  border-radius: 15px;\n}\n\n@media screen and (max-width: 960px) {\n  .imgBox:active {\n    background-color: rgb(200, 200, 200);\n  }\n}\n\n@media screen and (min-width: 960px) {\n  .imgBox:hover {\n    background-color: rgb(200, 200, 200);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recommended": `RecommendCount_recommended__IZPQY`,
	"imgBox": `RecommendCount_imgBox__CC3NC`
};
export default ___CSS_LOADER_EXPORT___;
